import React from 'react';
import DatePicker from 'react-datepicker';
import PropsTypes from 'prop-types';
import { connect } from '../../Store';
import 'moment/locale/sk';
import styled from 'styled-components';
import 'react-datepicker/dist/react-datepicker.css';
import { rem } from '../../../node_modules/polished';

const CustomDatePicker = styled(DatePicker)``;
const DatePickerWrapper = styled.div`
  width: 100%;
  z-index: 10000;
  .react-datepicker {
    border: ${({ inline, theme }) =>
      inline ? 'none' : `solid ${rem(1)} ${theme.separatorColor}`};
    width: 100%;
    display: flex;
  }


  .react-datepicker__input-container {
    width: 100%;
    
    input {
      width: 98%;
      user-select: ${({ disabled }) => disabled && 'none'};
      border-radius: ${({ theme }) => `${theme.largeBorderRadius}`};
      border: ${({ theme, error }) =>
        error
          ? `solid ${rem(1)} ${theme.color.danger}`
          : `solid ${rem(1)} ${theme.separatorColor}`};
      background-color: ${({ theme, error }) =>
        error && `${theme.color.danger}18`};
      padding: ${rem(10)} ${rem(10)};
      color: ${({ theme }) => theme.textColor}
      font-size: ${({ theme }) => theme.fontSize || rem(14)};
      box-sizing: border-box;
    }

    input:disabled {
      color: ${({ theme }) => theme.separatorColor}
    }
  }

  .react-datepicker-wrapper {
    width: 100%;
  }

  .react-datepicker__time-list {
    padding: 0;
    width: 100%;
  }

  .react-datepicker__time-container {
    padding: 0;
    margin: 0;
    width: 100%;

  }
  .react-datepicker__time-list--selected {
    background-color: ${({ theme }) => theme.color.primary}
  }

  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
    padding: ${({ inline }) => inline && '0'};
  }

  .react-datepicker__month {
    display: flex;
    width: 100%;
    margin: 0;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
  }

  .react-datepicker__current-month {
    display: ${({ inline }) => inline && 'none'};
  }

  .react-datepicker__month-container {
    width: 100%;
  }

  .react-datepicker__day {
    padding: ${({ inline }) => (inline ? rem(4) : rem(2))};
    margin: ${({ inline }) => (inline ? rem(4) : rem(2))};
    border-radius: 50%;
    :hover {
      border-radius: 50%;
      background-color: ${({ theme }) => `${theme.color.primary}22`};
      color: ${({ theme }) => theme.color.primary};
    }
  }

  .react-datepicker__day-name {
    padding: ${({ inline }) => (inline ? rem(4) : rem(2))};
    margin: ${({ inline }) => (inline ? rem(4) : rem(2))};
    font-weight: bold;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  .react-datepicker__day-names {
    padding-bottom: ${rem(10)};
  }

  .selected-week {
    color: ${({ theme }) => theme.color.primary};
  }

  .events {
    position: relative
    &::before {
      content: '.';
      position: absolute;
      text-align: center;
      margin-top: ${rem(8)};
      margin-left: 0;
      margin-right: 0;
      left: 0;
      right: 0;
      font-weight: bold;
    }
  }

  .react-datepicker__day--today {
    color: ${({ theme }) => theme.color.danger};
  }

  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.color.primary};
    color: #fff;
    :hover {
      background-color: ${({ theme }) => theme.color.primary};
      border-radius: 50%;
      color: #fff;
    }
  }

  .react-datepicker__navigation {
    display: ${({ inline }) => inline && 'none'};
  }

  .react-datepicker__day--keyboard-selected {
    color: white;
  }
`;

function StyledDatePicker(props) {
  return (
    <DatePickerWrapper inline={props.inline} error={props.error}>
      <CustomDatePicker
        {...props}
        readOnly={props.readOnly}
        locale={props.appLang}
      />
    </DatePickerWrapper>
  );
}

StyledDatePicker.propsTypes = {
  inline: PropsTypes.bool,
  error: PropsTypes.bool,
  readOnly: PropsTypes.bool,
};

StyledDatePicker.defaultProps = {
  inline: false,
  error: false,
  readOnly: false,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(StyledDatePicker);
