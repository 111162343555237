import { __e as __ } from '../Utils';

export default [
  { name: __('Číslo objednávky'), clickable: true, sorterValue: '_id' },
  {
    name: __('Zákazník'),
    clickable: true,
    otherProps: {
      minWidth: 200,
    },
    sorterValue: 'customer',
  },
  {
    name: __('Dátum a čas vytvorenia'),
    clickable: true,
    sorterValue: 'order_date',
  },
  { name: __('Faktúra'), clickable: false },
  { name: __('Dodací list'), clickable: false },
  { name: __('Celková suma'), clickable: true, sorterValue: 'total_price' },
  {
    name: __('Celková suma bez DPH'),
    clickable: true,
    sorterValue: 'total_price',
  },
];
