import { __e as __ } from '../Utils';

export default [
  { name: __('Číslo objednávky'), clickable: true, sorterValue: 'order_id' },
  {
    name: __('Zákazník'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
    // sorterValue: 'customer',
  },
  {
    name: __('Dátum a čas hodnotenia'),
    clickable: true,
    sorterValue: 'created_date',
  },
  {
    name: __('Hodnotenie'),
  },
  {
    name: __('Voucher'),
  },
];
