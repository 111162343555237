import { translateClaimSolutions } from '../Utils';

export default [
  {
    name: translateClaimSolutions('restitution'),
    value: 'restitution',
    key: 1,
  },
  {
    name: translateClaimSolutions('new_merchandise'),
    value: 'new_merchandise',
    key: 2,
  },
  {
    name: translateClaimSolutions('fix_merchandise'),
    value: 'fix_merchandise',
    key: 3,
  },
];
