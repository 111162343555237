import { __e as __ } from '../Utils';

export default [
  { name: __('Meno a priezvisko'), clickable: true, sorterValue: 'name' },
  {
    name: __('Email'),
    clickable: true,
    sorterValue: 'email',
  },
  { name: __('Tel. číslo'), clickable: false },
  {
    name: __('Dátum a čas registrovania'),
    clickable: true,
    sorterValue: 'created_date',
  },
  { name: __('Aktivovaný účet'), clickable: false },
];
