import { __e as __ } from '../Utils';

export default [
  {
    name: __('Číslo požiadavky'),
    clickable: true,
    sorterValue: 'claim_number',
  },
  { name: __('Typ požiadavky'), clickable: false },
  {
    name: __('Zákazník'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
    sorterValue: 'customer',
  },
  { name: __('Produkt'), clickable: false },
  {
    name: __('Dátum zadania'),
    clickable: true,
    sorterValue: 'created_date',
  },
  {
    name: __('Dátum poslednej aktualizácie'),
    clickable: true,
    sorterValue: 'update_date',
  },
  { name: __('Stav požiadavky'), clickable: false, sorterValue: 'state' },
  {
    name: __('Spôsob vyriešenia'),
    clickable: false,
  },
  {
    name: __('Spôsob ukončenia'),
    clickable: false,
  },
  {
    name: __('Počet dní do ukončenia'),
    clickable: false,
  },
];
