import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import DailyReportList from '../../Containers/DailyReportList';
import DailyReportDetail from '../../Containers/DailyReportDetail';

function DailyReportCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={DailyReportDetail} />
      <Route path={`${match.url}`} component={DailyReportList} />
    </Switch>
  );
}

DailyReportCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default DailyReportCard;
