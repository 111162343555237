import { __e as __ } from '../Utils';

export default [
  {
    name: '',
    clickable: false,
    otherProps: {
      maxWidth: 40,
    },
  },
  { name: __('Číslo objednávky'), clickable: true, sorterValue: '_id' },
  {
    name: __('Zákazník'),
    clickable: true,
    otherProps: {
      minWidth: 200,
    },
    sorterValue: 'customer',
  },
  {
    name: '',
    clickable: false,
    otherProps: {
      maxWidth: 40,
    },
  },
  {
    name: '',
    clickable: false,
    otherProps: {
      maxWidth: 40,
    },
  },
  {
    name: __('Dátum a čas objednania'),
    clickable: true,
    sorterValue: 'order_date',
  },
  { name: __('Adresa doručenia'), clickable: false },
  { name: __('Platba'), clickable: true, sorterValue: 'payment_type' },
  { name: __('Doprava'), clickable: true, sorterValue: 'delivery_type' },
  { name: __('Stav platby'), clickable: false, sorterValue: 'payment_state' },
  { name: __('Stav objednávky'), clickable: false },
  { name: __('Celková suma'), clickable: true, sorterValue: 'total_price' },
  {
    name: __('Celková suma bez DPH'),
    clickable: true,
    sorterValue: 'total_price',
  },
];
