import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import cookie from 'react-cookies';
import Img from 'react-image';
import { Link } from 'react-router-dom';
import { PopUpList } from 'oxyrion-ui/lib';
import Select from 'oxyrion-ui/lib/Select';
import userMenuConfig from '../../ContentConfig/userMenu';
import { __, getAvailableYears } from '../../Utils';
import { FlexBoxEnd, FlexBoxStart, FlexBox } from '../ReusableComponents';

const UserMenuWrapper = styled(FlexBoxEnd)`
  align-items: stretch;
  font-size: ${({ theme }) => theme.fontSize || '14px'};
  padding: 0 ${rem(20)} 0 0;
  position: ${({ display }) => (display ? '' : 'absolute')};
  top: ${({ display }) => (display ? '' : '-100vh')};
`;

const StyledImg = styled(Img)`
  height: ${rem(34)};
  border-radius: 50%;
`;

// const IconWrapper = styled.div`
//   padding: 0 ${rem(8)};
//   display: flex;
//   align-items: center;

//   :hover {
//     cursor: pointer;
//     & ul {
//       display: block;
//     }
//   }

//   svg {
//     width: 18px;
//     height: 18px;
//   }
// `;

const UserPhoto = styled(FlexBoxStart)`
  border-radius: 50%;
  padding: 0 ${rem(8)};

  &:hover {
    img {
      box-shadow: 0 0 7px 5px ${({ theme }) => theme.separatorColor};
    }
    & ul {
      display: block;
    }
  }
`;

const FlagWrapper = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 ${rem(5)};
  img {
    margin: ${({ margin }) => (margin ? rem(5) : 0)};
  }

  img:nth-of-type(3) {
    display: none;
  }

  &:hover {
    & ul {
      display: block;
    }
    img:nth-of-type(2) {
      display: none;
    }
    img:nth-of-type(3) {
      display: inline-block;
    }
  }
`;

const Item = styled.p`
  padding: 0 ${rem(10)};
  margin: ${rem(5)} 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSIze || '14px'};

  :hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

const LogoutItem = styled.a`
  padding: 0 ${rem(10)};
  margin: ${rem(5)} 0;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSIze || '14px'};
  text-decoration: none;
  color: black;
  :hover {
    color: ${({ theme }) => theme.color.primary};
  }
`;

// const LangSwitchWrapper = styled.div`
//   display: flex;
//   width: 100%;
//   justify-content: flex-start;
//   // margin: ${rem(4)};
//   margin-left: ${rem(10)}
//   margin-bottom: ${rem(12)}

// `;

// const LangeLabel = styled.div`
//   font-size: ${rem(10)};
//   margin-bottom: ${rem(6)};
//   margin-left: ${rem(10)};
//   margin-top: ${rem(8)};
// `;

// const FlagImag = styled(Img)`
//   height: ${rem(12)};
//   margin-right: ${rem(8)};
//   cursor: pointer;
//   border: solid ${props => (props.selected ? '1px' : 0)} black;
//   box-shadow: 0;
// `;

const Name = styled(Item)`
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  color: ${({ theme }) => theme.color.primary};
  padding: 0 ${rem(10)} ${rem(10)};
`;

const FlagItemWrapper = styled(FlexBox)`
  justify-content: space-between;
  padding: 0 ${rem(5)};
`;

const Li = styled.li`
  cursor: pointer;
  list-style: none;
  &:hover {
    color: ${({ theme }) => theme.color.primary};
  }
  a {
    font-size: ${({ theme }) => theme.fontSIze || '14px'};
    color: ${({ theme }) => theme.color.base};
    display: block;
    text-decoration: none;
    margin: ${rem(10)};
    &:visited {
      color: ${({ theme }) => theme.fontColor || theme.color.base};
    }
    &:hover {
      color: ${({ theme }) => theme.color.primary};
    }
    &:focus {
      color: ${({ theme }) => theme.color.primary};
    }
  }
`;

const CustomSelect = styled(Select)`
  min-width: ${rem(60)};
  padding: 0;
  select {
    padding: 0;
    padding-left: ${rem(2)};
    padding-right: ${rem(2)};
  }
`;

const photoBasePath = `${process.env.REACT_APP_IMG_DOMAIN}/images/500x500`;

class UserMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDisplay: false,
      tokenForBackLogin: undefined,
      localLang: undefined,
    };
  }

  componentDidMount() {
    const tokenForBackLogin = cookie.load('oldAccessToken');

    this.setState({
      tokenForBackLogin,
      localLang: this.props.appLang,
    });
  }

  componentWillReceiveProps(nextProps) {
    const { appLang } = this.props;
    if (appLang !== nextProps.appLang) {
      this.setState({
        localLang: nextProps.appLang,
      });
    }
  }

  backToMyAccount() {
    cookie.save('oxyrionAccessToken', this.state.tokenForBackLogin, {
      path: '/',
      expires: new Date(Date.now() + 3600),
      maxAge: 3600,
    });
    cookie.remove('oldAccessToken', { path: '/' });
    window.location = '';
  }

  render() {
    const {
      display,
      firm,
      top,
      userName,
      match,
      userPhotoPath,
      year,
    } = this.props;

    // const { localLang } = this.state;

    const years = getAvailableYears();
    return (
      <UserMenuWrapper display={display}>
        {/* <HidingIconWrapper onClick={() => this.props.onRealoadClick()}>
          <img
            src="/images/icons/history.svg"
            height="24px"
            alt="history icon"
          />
          <PopUpList bordered top={top} right={rem(185)}>
            <AnimatedFormMessageWrapper display={historyError}>
              <Message error message={historyError} />
            </AnimatedFormMessageWrapper>
            {history &&
              history.map(item =>
                // <RouteLinkWraper to={item.url}>{item.title}</RouteLinkWraper>
                renderLink(item.url, item.title, false, rem(14)),
              )}
            <br />
            {renderLink(
              `/${firm}/history`,
              __('Celá história'),
              false,
              rem(14),
            )}
          </PopUpList>
        </HidingIconWrapper> */}
        {/* <HidingIconWrapper onClick={() => pinPage()}>
          <Icon
            name="pin"
            color={defaultPage ? Theme.color.primary : 'black'}
          />
        </HidingIconWrapper> */}
        <FlagWrapper>
          <img src="/images/icons/add.svg" height="30px" alt="add icon" />
        </FlagWrapper>
        <FlagWrapper>
          <CustomSelect
            name={__('Účtovný rok')}
            size="s"
            value={year}
            onChange={e => {
              this.props.onYearClick(Number(e.target.value));
            }}
          >
            {years.map(y => {
              return (
                <option key={y} value={y} selected={y === year}>
                  {y}
                </option>
              );
            })}
          </CustomSelect>
        </FlagWrapper>
        <FlagWrapper margin>
          <img src={userMenuConfig.flags[firm]} height="22px" alt="flag" />
          <img
            src="/images/icons/arrow-down.svg"
            height="4px"
            alt="control arrow"
          />
          <img
            src="/images/icons/arrow-up.svg"
            height="4px"
            alt="control arrow"
          />
          <PopUpList bordered top={top} right={rem(85)}>
            {userMenuConfig.firm.map(name => (
              <FlagItemWrapper key={name}>
                <img
                  src={userMenuConfig.flags[name]}
                  width="24px"
                  alt={`${name} flag`}
                />
                <Item
                  onClick={e => this.props.onCountryClick(e.target.innerHTML)}
                >
                  {name}
                </Item>
              </FlagItemWrapper>
            ))}
          </PopUpList>
        </FlagWrapper>
        <UserPhoto>
          <StyledImg
            src={[
              `${photoBasePath}/${userPhotoPath}`,
              '/images/icons/avatar.svg',
            ]}
          />

          <PopUpList
            bordered
            display={this.state.userDisplay}
            top={this.props.top}
            right={rem(20)}
          >
            <Name>{userName}</Name>

            {userMenuConfig.userItems.map(item => (
              <Li key={`${item.href}${item.name}`}>
                <Link to={`${match.url}/${item.href}`}>{item.name}</Link>
              </Li>
            ))}

            <LogoutItem href={process.env.REACT_APP_LANDING_PAGE}>
              {__('App space')}
            </LogoutItem>
          </PopUpList>
        </UserPhoto>
      </UserMenuWrapper>
    );
  }
}

UserMenu.propTypes = {
  display: PropTypes.bool,
  top: PropTypes.string,
  firm: PropTypes.string.isRequired,
  userName: PropTypes.string.isRequired,
  onRealoadClick: PropTypes.func.isRequired,
  onCountryClick: PropTypes.func.isRequired,
  appLang: PropTypes.string,
  match: PropTypes.object.isRequired, //eslint-disable-line
  pinPage: PropTypes.func.isRequired,
  defaultPage: PropTypes.bool,
  history: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.url,
    }),
  ),
  historyError: PropTypes.string,
  year: PropTypes.number.isRequired,
  onYearClick: PropTypes.func.isRequired,
  userPhotoPath: PropTypes.string,
};

UserMenu.defaultProps = {
  display: true,
  top: '',
  defaultPage: false,
  userPhotoPath: null,
  historyError: '',
  history: [],
  appLang: 'SK',
};

export default UserMenu;
