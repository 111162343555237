import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
  formatCurrencyByFirm,
} from '../../Utils';
import { searchCashRegisters } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/invoicesPaymentsList';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

class InvoicesPaymentsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Uhradené faktúry na predajni');
    this.accessors = ['id', 'cashRegisterId', 'amount_vat', 'created_date'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      showFilter: false,
      activeMessage: 'Všetky',
      sorter: '-created_date',
      filters: [
        {
          name: 'cashRegisterId',
          label: __('Pokladňa'),
          value: '',
          type: 'search-select',
          onSearch: q => searchCashRegisters(q, this.props.firm),
        },
        {
          name: 'fromDate',
          label: __('Dátum od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum do'),
          value: '',
          type: 'date',
        },
      ],
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      const newData = await API2.getInvoicesPaymentsAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(
          renderLink(`./invoices-payments/${item._id}`, `${item.invoiceId}`),
        ),
        cashRegisterId: _shapeData(item.cashRegisterId),
        amount_vat: _shapeData(
          `${item.price_vat.amount} ${formatCurrencyByFirm(this.props.firm)}`,
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Uhradené faktúry na predajni')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return <React.Fragment>{super.render()}</React.Fragment>;
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(InvoicesPaymentsList);
