import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../Store';
import { getIdFromProps, __ } from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import AddCampaign from '../../Components/CampaignDetail';
import {
  FlexInfoTableWrapper,
  FlexInfotableSubWrapper,
} from '../../Components/ReusableComponents';

import API2 from '../../API2';

const CustomSubWrapper = styled(FlexInfotableSubWrapper)`
  margin: ${rem(10)} 0;
`;

class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      campaign: null,
    };
  }

  async componentDidMount() {
    await this.fetchCampaingData();
  }

  async fetchCampaingData() {
    try {
      const { firm } = this.props;

      const campaign = await API2.getCampaignAction(
        firm,
        getIdFromProps(this.props),
      );

      this.setState({ campaign });
    } catch (e) {
      console.log(e);
    }
  }

  renderControlBar() {
    const { history } = this.props;
    return (
      <React.Fragment>
        <ControllBar history={history} name={__('Kampaň')} />
      </React.Fragment>
    );
  }

  render() {
    const { campaign } = this.state;

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <FlexInfoTableWrapper>
          <CustomSubWrapper>
            <AddCampaign
              modify
              firm={this.props.firm}
              callback={async () => await this.fetchCampaingData()}
              campaignId={getIdFromProps(this.props)}
              status={
                campaign && campaign.approval_request
                  ? campaign.approval_request.status
                  : 'for_approval'
              }
              readOnly
              approved_by={
                campaign &&
                campaign.approval_request &&
                campaign.approval_request.approved_by
              }
              approved_date={
                campaign &&
                campaign.approval_request &&
                campaign.approval_request.updated_date
              }
            />
          </CustomSubWrapper>
        </FlexInfoTableWrapper>
      </React.Fragment>
    );
  }
}
CampaignDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
  userData: PropTypes.shape({}),
};

CampaignDetail.defaultProps = {
  userData: { firm: 'SK' },
  firm: 'SK',
};

const mapStateToProps = state => {
  return { userData: state };
};

export default connect(state => mapStateToProps(state))(CampaignDetail);
