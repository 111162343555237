import cookie from 'react-cookies';
import cookieConfig from '../ContentConfig/cookie';
import config from '../ContentConfig/sectionConfig';
import { normalizeSectionConfig } from '../Utils';

export default {
  setLangs: (langs, newLangs) => ({
    langs: newLangs,
  }),
  setAppLang: (lang, newLang) => ({
    appLang: newLang,
  }),
  setUser: (user, newUser) => ({
    user: newUser,
  }),
  setLoggingOut: (loggingOut, value) => {
    return { loggingOut: value };
  },
  loggout: async () => {
    window.location.href = '/';
    //const AccessToken = cookie.load(cookieConfig.name);
    cookie.remove(cookieConfig.name, { path: '/' });
    //await API.oauthRevokeAction({ data: { access_token: AccessToken } });
  },
  setFirm: (firm, newFirm) => {
    return {
      firm: newFirm,
    };
  },
  setPath: (history, newHistory) => {
    return { history: newHistory };
  },
  setAclList: (aclList, newAclList) => {
    return { aclList: newAclList };
  },
  setSettings: (settings, newSettings) => ({
    settings: newSettings,
  }),

  setCrmSettings: (settings, newCrmSettings, user, firm) => {
    const modifiedConfig = normalizeSectionConfig(
      newCrmSettings,
      config,
      user,
      firm,
    );

    return { crm_settings: modifiedConfig };
  },

  setHistory: (userHistory, newHistory) => ({
    userHistory: newHistory,
  }),
  addHistory: (state, newHistoryItem) => {
    const history = [newHistoryItem, ...state.userHistory].slice(0, 10);
    return {
      userHistory: history,
    };
  },
  setYear: (year, newYear) => {
    cookie.save('year', Number(newYear), {
      path: '/',
      expires: new Date(Date.now() + 3600),
      maxAge: 3600,
    });

    return {
      year: Number(cookie.load('year')),
    };
  },
};
