import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'oxyrion-ui/lib/Icon';
import { rem } from 'polished';
import Loader from 'oxyrion-ui/lib/Loader';

import { __ } from '../../Utils';
import { connect } from '../../Store';
import PopUpWrapper from '../PopUpWrapper';
import ConfirmDialog from '../ConfirmDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: ${props => (props.width ? rem(props.width) : '100%')};
`;

const IconWrapper = styled.div`
  cursor: pointer;
`;

const Text = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  &:hover {
    text-decoration: underline;
  }
  font-size: ${rem(12)};
  cursor: pointer;
  margin-right: ${rem(8)};
  color: ${props => props.color};
`;

const photoBasePath = `${process.env.REACT_APP_IMG_DOMAIN}/images/0x0`;

class FileViewDownload extends React.Component {
  constructor() {
    super();
    this.state = {
      showDownloadPopup: false,
      fileLoader: false,
    };
  }

  async downloadFileRedirect(redirect = false) {
    const { history, name, error, type, fullPath } = this.props;

    try {
      this.setState({
        fileLoader: true,
      });

      if (redirect) {
        if (type === 'application/pdf') {
          const file = await this.props.downloadFunction();
          history.push({
            pathname: `/${this.props.firm}/fileviewer`,
            state: { fileUrl: file.url, name },
          });
        } else if (type.startsWith('image/') && fullPath) {
          window.open(`${photoBasePath}/${fullPath}`, '_blank');
        } else {
          const file = await this.props.downloadFunction();
          window.location.href = file.url;
        }
      } else {
        const file = await this.props.downloadFunction();
        window.location.href = file.url;
      }
      this.setState({
        fileLoader: false,
        showDownloadPopup: false,
      });
    } catch (e) {
      console.log(e);
      if (redirect) {
        error(__(`Súbor ${name} sa nepodarilo otvoriť`));
      } else {
        error(__(`Súbor ${name} sa nepodarilo stiahnuť`));
      }
      this.setState({
        fileLoader: false,
        showDownloadPopup: false,
      });
    }
  }

  render() {
    const { showDownloadPopup, fileLoader } = this.state;
    const { name, customWidth } = this.props;

    return (
      <Wrapper width={customWidth}>
        <PopUpWrapper
          display={showDownloadPopup}
          small
          onClose={() =>
            this.setState({
              showDownloadPopup: false,
            })
          }
        >
          <ConfirmDialog
            message={`${__('Prajete si stiahnuť súbor')} ${name} ?`}
            onDismiss={() =>
              this.setState({
                showDownloadPopup: false,
              })
            }
            loading={fileLoader}
            onConfirm={() => this.downloadFileRedirect(false)}
          />
        </PopUpWrapper>
        {fileLoader ? (
          <Loader size="m" color={this.props.color} />
        ) : (
          <Text
            color={this.props.color}
            title={__('Zobraziť súbor')}
            onClick={() => {
              this.downloadFileRedirect(true);
            }}
          >
            {name}
          </Text>
        )}

        <IconWrapper
          title={__('Stiahnúť súbor')}
          onClick={() =>
            this.setState({ showDownloadPopup: !showDownloadPopup })
          }
        >
          <Icon name="download" size="l" color={this.props.color} />
        </IconWrapper>
      </Wrapper>
    );
  }
}

FileViewDownload.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  downloadFunction: PropTypes.func,
  error: PropTypes.func,
  customWidth: PropTypes.number,
  history: PropTypes.shape({}),
  fullPath: PropTypes.string,
  color: PropTypes.string,
};

FileViewDownload.defaultProps = {
  name: '-',
  downloadFunction: () => {},
  error: () => {},
  customWidth: null,
  history: {},
  type: 'application/pdf',
  fullPath: '',
  color: '#1D71B8',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FileViewDownload);
