import { __e as __ } from '../Utils';

export default [
  {
    name: __('Prehľad'),
    href: '/home',
    dropdown: [],
  },
  {
    name: __('Objednávky'),
    href: '/orders',
    dropdown: [
      {
        name: __('Objednávky'),
        href: '/orders',
      },
      {
        name: __('Správy'),
        href: '/messages',
      },
      {
        name: __('Hodnotenia'),
        href: '/order-ratings',
      },
    ],
  },
  {
    name: __('Reklamácie'),
    href: '/warranty-claims',
    dropdown: [],
  },
  {
    name: __('Preprava'),
    href: '/123-courier',
    dropdown: [
      {
        name: __('123 Kurier'),
        href: '/123-courier',
      },
      {
        name: __('Packeta'),
        href: '/packeta',
      },
    ],
  },
  {
    name: __('Produkty'),
    dropdown: [
      {
        name: __('Sklad'),
        href: '/warehouse',
      },
      {
        name: __('Otázky'),
        href: '/questions',
      },
      {
        name: __('Recenzie'),
        href: '/reviews',
      },
    ],
  },
  {
    name: __('Štatistiky'),
    dropdown: [
      {
        name: __('Zľavové kupóny'),
        href: '/stats',
      },
      {
        name: __('Predaj - Eshop'),
        href: '/eshop-sales',
      },
      {
        name: __('Predaj - Predajne'),
        href: '/store-sales',
      },
    ],
  },
  {
    name: __('Predajňa'),
    dropdown: [
      {
        name: __('Predajné doklady'),
        href: '/bills',
      },
      {
        name: __('Kampane'),
        href: '/campaigns',
      },
      {
        name: __('Denné uzávierky'),
        href: '/daily-report',
      },
      {
        name: __('Vklady a výbery'),
        href: '/deposit-withdrawals',
      },
      {
        name: __('Denný prehľad'),
        href: '/daily-overview',
      },
      {
        name: __('Vystavené objednávky'),
        href: '/cash-register-orders',
      },
      {
        name: __('Registrácie cez kasu'),
        href: '/contact-registers',
      },
      {
        name: __('Úhrady faktúr'),
        href: '/invoices-payments',
      },
    ],
  },
];
