import React from 'react';
import BaseTable from '../BaseTable';
import { __, getEshopProductUrl, formatPrice } from '../../Utils';
import { connect } from '../../Store';
import tableHeaders from '../../ContentConfig/statsTableHeaders';
import styled from 'styled-components';

const ProductLinkWrapper = styled.div`
  display: flex;
  justify-content: left;
  align-items: left;
  width: 100%;

  .icon {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
  }
`;

class StatsTable extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'priority' });
    this.headers = props.showPricesColumn
      ? tableHeaders.concat({
          name: __('Suma za predané produkty bez DPH'),
          clickable: false,
        })
      : tableHeaders;
    this.segmentName = __('Štatistiky predaja');
    this.accessors = props.showPricesColumn
      ? ['productName', 'brand', 'count', 'price_amount']
      : ['productName', 'brand', 'count'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      showEditProduct: null,
    };
  }

  componentDidUpdate(props) {
    if (
      this.state.content.length === 0 &&
      props.data &&
      props.data.length > 0
    ) {
      const content = this.normalizeColumns(this.props.data);
      this.setState({
        content,
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: _shapeData(item._id),
        productName: _shapeData(
          <ProductLinkWrapper>
            {item.name && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={getEshopProductUrl(this.props.firm, item.url)}
                className="icon"
              >
                {item.name}
              </a>
            )}
          </ProductLinkWrapper>,
        ),
        brand: _shapeData(item.brand),
        count: _shapeData(item.count),
        price_amount:
          item.eshop_sold_amount &&
          _shapeData(formatPrice(item.eshop_sold_amount)),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    return <div />;
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(StatsTable);
