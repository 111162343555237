import { __e as __ } from '../Utils';

export default [
  {
    name: __('Template'),
  },
  {
    name: __('Objednávka'),
  },
  {
    name: __('Dátum a čas odoslania'),
  },
  {
    name: __('Status'),
  },
  {
    name: __('Prijímatelia'),
  },
];
