import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Icon from 'oxyrion-ui/lib/Icon';
import Loader from 'oxyrion-ui/lib/Loader';
import { rem } from 'polished';
import { FlexBox } from '../../Components/ReusableComponents';

const Button = styled(FlexBox)`
  padding: ${rem(5)} ${rem(10)};
  border-right: ${({ theme }) => `solid ${rem(1)} ${theme.separatorColor}4`};
  background-color: ${({ theme, warning, danger }) =>
    danger ? theme.color.danger : warning ? '#e07c12' : '#a447a3'};
  border-radius: ${({ theme }) => theme.baseBorderRadius};
  color: #fff
  margin: ${rem(9)} 0;
  margin-left: ${({ danger, warning }) => (danger || warning) && rem(10)};

  :last-of-type {
    border-right: none;
  }

  :hover {
    border-radius: ${({ theme }) => theme.baseBorderRadius}
    cursor: pointer;
    background-color: ${({ theme, danger }) =>
      !danger ? theme.color.fadedPrimary : 'white'};
    background-color: ${({ theme, danger }) =>
      !danger ? theme.color.fadedPrimary || '#e0ecf6' : 'white'};
    color: ${({ theme, danger }) =>
      !danger ? theme.color.primary : theme.color.danger};
    svg {
      path {
        fill: ${({ theme, danger }) =>
          !danger ? theme.color.primary : theme.color.danger};
      }
    }
  }
`;
const Link = styled.p`
  font-size: ${({ theme }) => theme.fontSize || rem(11)};
  // text-decoration: underline;
  white-space: nowrap;
  margin: 0;
  display: none;
  ${({ theme }) => theme.media.l`
    display: block
    margin-left: ${rem(5)}
  `};
`;

class ControlBarButton extends React.Component {
  render() {
    return (
      <Button
        title={this.props.children}
        danger={this.props.danger}
        warning={this.props.warning}
        {...this.props}
      >
        {this.props.showIcon && <Icon name={this.props.icon} color="#fff" />}
        <Link>{this.props.children}</Link>
        {this.props.loading ? <Loader size="s" color="#fff" /> : ''}
      </Button>
    );
  }
}

ControlBarButton.propTypes = {
  icon: PropTypes.string,
  showIcon: PropTypes.bool,
};

ControlBarButton.defaultProps = {
  icon: 'plus',
  showIcon: true,
};

export default ControlBarButton;
