import { __e as __ } from '../Utils';

export const location = {};

export const base = [
  {
    name: __('Domov'),
    path: '/',
  },
];
