import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../Store';

class CheckAccess extends React.Component {
  render() {
    const { aclList, operation, ComponentNotAllowed, Component } = this.props;
    if (aclList[operation]) {
      return Component;
    }
    return ComponentNotAllowed;
  }
}

CheckAccess.propTypes = {
  aclList: PropTypes.shape({}).isRequired,
  operation: PropTypes.string.isRequired,
  Component: PropTypes.element.isRequired,
  ComponentNotAllowed: PropTypes.element,
};

CheckAccess.defaultProps = {
  ComponentNotAllowed: <React.Fragment />,
};

export default connect(state => state)(CheckAccess);
