import React from 'react';
import { rem } from 'polished';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexBox, H2 } from '../ReusableComponents';
import ControlBarInfoBlock from './ControlBarInfoBlock';
import ControlbarSearch from './ControlBarSearch';
import ControlbarSearchSelect from './ControlBarSearchSelect';
import { __ } from '../../Utils';
import CheckAccess from '../../ACL/CheckAccess';

const Wrapper = styled(FlexBox)`
  justify-content: ${({ justifyContent }) => justifyContent};
  padding: ${rem(5)} ${rem(15)}
  position: sticky;
  background-color: ${({ theme, backgroundColor }) =>
    backgroundColor || theme.color.primary};
  border-bottom: solid ${rem(1)} ${({ theme }) => theme.separatorColor};
  z-index: ${({ noOverFlow }) => (noOverFlow ? '0' : '105')};
  top: ${rem(51)};
  color: ${props => props.textColor || 'white'};
`;

const Name = styled.div`
  display: ${({ display }) => (display ? 'inline-block' : 'none')};
  margin-bottom: ${rem(5)};
`;

class ControllBar extends React.Component {
  render() {
    const {
      justifyContent,
      name,
      onChange,
      onOwnersChange,
      noOverFlow,
      defaultValue,
      fetchOwners,
      selectedOwner,
      showSearch,
      backgroundColor,
      textColor,
    } = this.props;
    return (
      <Wrapper
        backgroundColor={backgroundColor}
        justifyContent={justifyContent}
        noOverFlow={noOverFlow}
        textColor={textColor}
      >
        {this.props.history && (
          <ControlBarInfoBlock
            textColor={textColor}
            history={this.props.history}
            right={!onChange}
            showLocation={this.props.showLocation}
          >
            <Name display={name}>
              <H2>{name}</H2>
            </Name>
          </ControlBarInfoBlock>
        )}
        {onChange && showSearch && (
          <ControlbarSearch
            defaultValue={defaultValue}
            onChange={val => onChange(val)}
          />
        )}

        {onOwnersChange && (
          <CheckAccess
            operation="putCampaignApprovalRequestDecisionAction" // nie uplne najcastnejsie ale vieme ze tuto akciu bude vzdy moct iba manager
            Component={
              <ControlbarSearchSelect
                value={selectedOwner}
                fetchData={q => fetchOwners(q)}
                onChange={val => onOwnersChange(val)}
                searchLabel={__('Vlastník')}
              />
            }
          />
        )}
        {this.props.children}
      </Wrapper>
    );
  }
}

ControllBar.propTypes = {
  justifyContent: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onOwnersChange: PropTypes.func,
  fetchOwners: PropTypes.func.isRequired,
  showLocation: PropTypes.bool,
  noOverFlow: PropTypes.bool,
  defaultValue: PropTypes.string,
  selectedOwner: PropTypes.string,
  color: PropTypes.string,
  hideHistory: PropTypes.bool,
  showSearch: PropTypes.bool,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  history: PropTypes.object.isRequired, //eslint-disable-line
};

ControllBar.defaultProps = {
  justifyContent: 'flex-end',
  selectedOwner: null,
  name: undefined,
  showLocation: true,
  onChange: null,
  onOwnersChange: null,
  noOverFlow: false,
  showSearch: true,
  backgroundColor: null,
  textColor: null,
  defaultValue: '',
  hideHistory: false,
};

export default ControllBar;
