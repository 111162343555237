import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { ColumnFlexBox } from '../ReusableComponents';
import ControlBarLocation from './ControlBarLocation';

const CustomFlexBox = styled(ColumnFlexBox)`
  margin-right: ${({ right }) => right && 'auto'};
  align-items: start;
`;

class ControlBarInfoBlock extends React.Component {
  render() {
    const { right, showLocation, textColor } = this.props;
    return (
      <CustomFlexBox right={right}>
        {showLocation && (
          <ControlBarLocation
            textColor={textColor}
            history={this.props.history}
          />
        )}
        {this.props.children}
      </CustomFlexBox>
    );
  }
}

ControlBarInfoBlock.propTypes = {
  history: PropTypes.any.isRequired, //eslint-disable-line
  right: PropTypes.bool,
  showLocation: PropTypes.bool,
  textColor: PropTypes.string,
};

ControlBarInfoBlock.defaultProps = {
  right: true,
  showLocation: true,
  textColor: null,
};

export default ControlBarInfoBlock;
