import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from 'oxyrion-ui/lib/Input';

class OwnStateInput extends Component {
  state = {
    inputValue: '',
  };

  componentDidUpdate(oldProps) {
    if (oldProps.value !== this.props.value) {
      this.setState({ inputValue: this.props.value });
    }
  }

  handleOnChange(value) {
    this.setState(
      {
        inputValue: value,
      },
      () => {
        this.props.onChange(value);
      },
    );
  }

  render() {
    return (
      <Input
        {...this.props}
        value={this.state.inputValue}
        onChange={e => this.handleOnChange(e.target.value)}
      />
    );
  }
}

OwnStateInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
};

OwnStateInput.defaultProps = {
  value: '',
  onChange: () => {},
};

export default OwnStateInput;
