import React from 'react';
import { formatDate, FORMAT_HUMANE_DATETIME, __ } from '../../Utils';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/email_logs_headers';
import moment from 'moment';
import API2 from '../../API2';

class Courier123Packages extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('123 Kurier - balíky');
    this.accessors = [
      'template_id',
      'order',
      'created_date',
      'status',
      'addreses',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearchValue: '',
      showFilter: false,
      invoiceNumber: '',
      deliveryNoteNumber: '',
      activeMessage: 'Všetky',
      filters: [],
    };
  }
  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter } = this.state;

      const filterToSend = {};

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      let newData = [];

      if (this.props.orderId) {
        newData = await API2.getEshopOrderEmailLogsAction(
          this.props.firm,
          this.props.orderId,
          params,
        );
      }

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);

      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        template_id: _shapeData(item.template_id),
        order: _shapeData(item.order_id),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        status: _shapeData(
          item.type === 'success'
            ? __('Úspešné odoslanie')
            : __('Neúspešné odoslanie'),
        ),
        addreses: _shapeData(
          item.addresses &&
            item.addresses.length > 0 &&
            item.addresses.map(a => a.to).join(', '),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const {
      history,
      showSearch,
      controlBarColor,
      textColor,
      hideHistory,
    } = this.props;

    return (
      <ControllBar
        history={history}
        showLocation={!hideHistory}
        justifyContent="space-between"
        name={__('Odoslané emaily k objednávke')}
        onChange={val => this.loadSearchResult(val)}
        showSearch={showSearch}
        backgroundColor={controlBarColor}
        textColor={textColor}
      />
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Courier123Packages);
