import { __e as __ } from '../Utils';

export default [
  {
    name: __('Zľavovy kód'),
    clickable: false,
    otherProps: {
      maxWidth: 200,
    },
  },
  {
    name: __('Počet použití'),
    clickable: false
  },
  {
    name: __('Celková cena objednávok'),
    clickable: false
  },
  {
    name: __('Dátum použitia'),
    clickable: false
  }
];
