import { __e as __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Status'),
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Platnost od'),
    clickable: true,
    sorterValue: 'valid_from',
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Platnosť do'),
    clickable: true,
    sorterValue: 'valid_to',
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Predajne'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Vytvorená'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
];
