import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { rem } from 'polished';
import { Message, Button } from 'oxyrion-ui/lib';
import { __ } from '../../Utils';
import { FlexBox, H2, AnimatedFormMessageWrapper } from '../ReusableComponents';

const Wrapper = styled.div`
  padding: ${rem(20)} ${rem(10)};
  color: black;
  z-index: 100;
`;

const AdditionalContent = styled.div`
  padding: ${rem(5)} ${rem(10)} ${rem(30)};
  z-index: 1000;
`;

const ButtonWrapper = styled(FlexBox)`
  justify-content: space-around;
  width: 80%;
  margin: auto;
`;

const CustomH2 = styled(H2)`
  margin: 0 ${rem(20)} ${rem(20)};
  text-align: center;
`;

// const Space = styled.div`
//   width: ${rem(15)};
// `;

function ConfirmDialog(props) {
  const {
    onConfirm,
    onDismiss,
    message,
    additionalContent,
    error,
    confirmMessage,
    dismissMessage,
    loading,
    dismissLoading,
    dismissDisable
  } = props;
  return (
    <React.Fragment>
      <AnimatedFormMessageWrapper display={error}>
        {error && <Message error message={error} />}
      </AnimatedFormMessageWrapper>
      <Wrapper>
        <CustomH2>{message}</CustomH2>
        {additionalContent ? (
          <AdditionalContent>{additionalContent}</AdditionalContent>
        ) : (
          ''
        )}
        <ButtonWrapper>
          <Button
            loading={loading}
            small
            danger
            basic
            size="s"
            onClick={() => onConfirm()}
          >
            {__(confirmMessage)}
          </Button>
          <Button
            disabled={dismissDisable}
            loading={dismissLoading}
            small
            basic
            primary
            onClick={() => onDismiss()}
          >
            {__(dismissMessage)}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </React.Fragment>
  );
}

ConfirmDialog.propTypes = {
  error: PropTypes.arrayOf(PropTypes.string),
  message: PropTypes.string.isRequired,
  confirmMessage: PropTypes.string,
  dismissMessage: PropTypes.string,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  additionalContent: PropTypes.string,
  dismissLoading: PropTypes.bool,
  dismissDisable: PropTypes.bool
};

ConfirmDialog.defaultProps = {
  error: null,
  confirmMessage: __('Áno'),
  dismissMessage: __('Nie'),
  loading: false,
  additionalContent: '',
  dismissLoading: false,
  dismissDisable: false
};

export default ConfirmDialog;
