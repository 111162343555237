import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { Icon } from 'oxyrion-ui/lib';
import BaseTable from '../BaseTable';
import { formatPrice, getCurrencyByFirm, __ } from '../../Utils';
import { connect } from '../../Store';
import tableHeaders from '../../ContentConfig/orderProducts';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ProductEdit from '../../Components/ProductEdit';

const EditButton = styled.div`
  color: ${props => props.theme.color.primary};
  cursor: pointer;
  text-decoration: underline;
`;

const ProductNameWrapper = styled.div`
  padding-top: ${rem(8)};
  padding-bottom: ${rem(8)};
  .package-name {
    display: flex;
    flex-direction: row;
  }
  .catalog_name {
    font-weight: bold;
    margin-bottom: ${rem(4)};
  }

  .variant_name {
  }
`;

const ProductLinkWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .icon {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
  }
`;

class OrderProducts extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'priority' });
    this.headers = tableHeaders.map(t =>
      Object.assign(t, {
        name: t.name.replace('{currency}', getCurrencyByFirm(this.props.firm)),
      }),
    );
    this.segmentName = __('Produkty objednávky');
    this.accessors = [
      '_id',
      'admin',
      'eshop',
      'product_name',
      'products_package',

      'unit_price_wd_vat_exlc',
      'quantity',
      'total_price_wd_vat_excl',
      'discount_percent',
      'discount_amount',
      'total_price',
      'vat_percent',
      'vat_amount',
      'total_price_vat',
      'edit',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      showEditProduct: null,
    };
  }

  componentDidUpdate(props) {
    if (
      this.state.content.length === 0 &&
      props.products &&
      props.products.length > 0
    ) {
      const content = this.normalizeColumns(this.props.products);
      this.setState({
        content,
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: _shapeData(<ProductNameWrapper>{item._id}</ProductNameWrapper>),
        admin: _shapeData(
          <ProductLinkWrapper>
            {item.catalog_product && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_ADMIN_BASE_URL}/${this.props.firm}/products/${item.catalog_product._id}`}
                className="icon"
              >
                <Icon name="search" color="#A448A3" />
              </a>
            )}
          </ProductLinkWrapper>,
        ),
        eshop: _shapeData(
          <ProductLinkWrapper>
            {item.catalog_product && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  this.props.firm === 'CZ'
                    ? `https://xbarvy.cz/produkt/${item.catalog_product.url}`
                    : `https://xfarby.sk/produkt/${item.catalog_product.url}`
                }
                className="icon"
              >
                <Icon name="search" color="#A448A3" />
              </a>
            )}
          </ProductLinkWrapper>,
        ),
        product_name: _shapeData(
          <ProductNameWrapper>
            {item.catalog_product && (
              <div className="catalog_name">{item.catalog_product.name}</div>
            )}
            <div className="variant_name">{item.name}</div>
          </ProductNameWrapper>,
        ),
        products_package: _shapeData(
          item.products_package && (
            <div className="package-name">
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_ADMIN_BASE_URL}/${this.props.firm}/products-packages/${item.products_package._id}`}
                className="catalog_name"
              >
                {item.products_package.name[this.props.firm.toUpperCase()]}
              </a>
            </div>
          ),
        ),
        unit_price_wd_vat_exlc: _shapeData(
          formatPrice(item.unit_price_wd_vat_exlc),
        ),
        quantity: _shapeData(item.quantity),
        total_price_wd_vat_excl: _shapeData(
          formatPrice(item.total_price_wd_vat_excl),
        ),
        discount_percent: _shapeData(item.discount_percent, null, 0),
        discount_amount: _shapeData(formatPrice(item.discount_amount), null, 0),
        total_price: _shapeData(formatPrice(item.total_price)),
        vat_percent: _shapeData(item.vat_percent),
        vat_amount: _shapeData(formatPrice(item.vat_amount)),
        total_price_vat: _shapeData(formatPrice(item.total_price_vat)),
        edit: _shapeData(
          item.total_price_vat.amount > 0 && (
            <EditButton
              onClick={() =>
                this.setState({
                  showEditProduct: item,
                })
              }
            >
              {__('Editovať')}
            </EditButton>
          ),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  saveProduct(data) {
    this.props.saveProduct(data._id, {
      quantity: data.quantity,
      unit_price: data.unit_price_wd_vat_exlc,
      line_discount_percent: data.discount_percent,
    });
  }

  renderControlBar() {
    return <div />;
  }

  render() {
    const { showEditProduct, productEditLoading } = this.state;

    return (
      <div>
        <PopUpWrapper
          display={showEditProduct}
          small
          customWidth={300}
          onClose={() => this.setState({ showEditProduct: false })}
          onEscClose={() => this.setState({ showEditProduct: false })}
          message={showEditProduct && showEditProduct.name}
        >
          {showEditProduct && (
            <ProductEdit
              data={showEditProduct}
              saveLoading={productEditLoading}
              onDismiss={() => this.setState({ showEditProduct: false })}
              onConfirm={d => this.saveProduct(d)}
            />
          )}
        </PopUpWrapper>
        {super.render()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrderProducts);
