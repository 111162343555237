import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from '../../../Store';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  LabelComp,
  __,
} from '../../../Utils';
import ControllBar from '../../../Components/ControllBar';
import moment from 'moment';
import { LoaderWrapper } from '../../../Components/ReusableComponents';
import { Loader } from 'oxyrion-ui/lib';
import API2 from '../../../API2';

export const getRating = (rating, big = false) => {
  if (rating === 'POSITIVE') {
    return (
      <LabelComp big={big} background="#C1D375">
        {__('Pozitívne')}
      </LabelComp>
    );
  }

  return (
    <LabelComp big={big} background="#EF476F">
      {__('Negatívne')}
    </LabelComp>
  );
};

const getQuestionTranslation = text => {
  switch (text) {
    case 'not_found_product':
      return __('Nenašiel/a som tovar, ktorý som hľadal/a.');
    case 'not_found_information':
      return __('Nenašiel/a som informáciu o tovare, ktorú som hľadal/a.');
    case 'not_found_payment':
      return __('Nemohol/a som zaplatiť spôsobom, akým som chcel/a.');
    case 'order_process_complicated':
      return __('Spôsob objednania je veľmi zložitý/zdĺhavý.');
    case 'what_can_we_improve_date':
      return __('Nebol dodržaný termín dodania?');
    case 'order_wasnt_available':
      return __('Tovar mal byť na sklade, ale nebol.');
    case 'order_was_late':
      return __('Tovar dorazil neskôr, ako bolo avizované.');
    default:
      return __(text);
  }
};

class OrdersRatingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      responses: null,
      voucher: null,
      loading: true,
      sendOrderDateNotify: false,
      notifyCustomer: true,
      orderEmailConflictData: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getEshopOrderRatingAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      const baseInfo = [
        {
          label: __('Číslo objednávky'),
          value: (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`/${this.props.firm}/orders/${result.order_id}`}
            >
              {result.order_id}
            </StyledA>
          ),
        },
        {
          label: __('Dátum hodnotenia'),
          value: formatDate(
            moment(result.created_date),
            FORMAT_HUMANE_DATETIME,
          ),
        },
        {
          label: __('Meno zákazníka'),
          value: (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${result.contact_id}`}
            >
              {`${result.contact && result.contact.name} ${result.contact &&
                result.contact.surname}`}
            </StyledA>
          ),
        },
        {
          label: __('Email'),
          value: (result.contact && result.contact.email) || '',
        },
        {
          label: __('Tel. číslo'),
          value: (result.contact && result.contact.phone) || '',
        },
      ];

      this.setState({
        loading: false,
        data: Object.assign(result, {
          baseInfo,
        }),
        responses: result.responses,
        voucher: result.voucher,
      });
    } catch (e) {
      this.setState({
        error: __('Hodnotenie sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return (
      <ControllBar
        history={history}
        name={`${__('Hodnotenie')} ${data.order_id}`}
      />
    );
  }

  renderRow(i) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { data, responses, voucher, loading } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    const liked = responses && responses.liked;
    const improve_other = responses && responses.improve_other;
    const eshop_issues = responses && responses.eshop_issues;
    const eshop_issues_other = responses && responses.eshop_issues_other;
    const delivery_date_issues = responses && responses.delivery_date_issues;
    const delivery_date_issues_other =
      responses && responses.delivery_date_issues_other;

    return (
      <React.Fragment>
        {this.renderControlBar()}
        {data && (
          <MainWrapper>
            <HeaderLabel>
              <div className="text">{__('Hodnotenie')}</div>
            </HeaderLabel>
            <InfoWrapper>{getRating(data.rating, true)}</InfoWrapper>
            {voucher && (
              <React.Fragment>
                <HeaderLabel>
                  <div className="text">{__('Voucher')}</div>
                </HeaderLabel>

                <InfoWrapper>
                  <div className="row">
                    <div className="label">
                      {`${voucher.usages ? __('Použitý') : __('Nepoužitý')}`}
                    </div>
                    <div>{`${voucher.voucher}`}</div>
                  </div>
                </InfoWrapper>
              </React.Fragment>
            )}
            <HeaderLabel>
              <div className="text">{__('Informácie o účte')}</div>
            </HeaderLabel>
            <InfoWrapper>
              {data.baseInfo && data.baseInfo.map(i => this.renderRow(i))}
            </InfoWrapper>
            {liked && (
              <React.Fragment>
                <HeaderLabel>
                  <div className="text">{__('Čo sa vám páčilo?')}</div>
                </HeaderLabel>
                <InfoWrapper>
                  <div className="row">{liked}</div>
                </InfoWrapper>
              </React.Fragment>
            )}
            {improve_other && (
              <React.Fragment>
                <HeaderLabel>
                  <div className="text">{__('Čo máme zlepšiť?')}</div>
                </HeaderLabel>
                <InfoWrapper>
                  <div className="row">{improve_other}</div>
                </InfoWrapper>
              </React.Fragment>
            )}
            {eshop_issues && (
              <React.Fragment>
                <HeaderLabel>
                  <div className="text">
                    {__('Čo by sme mali na e-shope zlepšiť?')}
                  </div>
                </HeaderLabel>
                <InfoWrapper>
                  {eshop_issues &&
                    eshop_issues.map(i => {
                      return (
                        <div className="row">{getQuestionTranslation(i)}</div>
                      );
                    })}
                  <div className="row">{eshop_issues_other}</div>
                </InfoWrapper>
              </React.Fragment>
            )}
            {delivery_date_issues && (
              <React.Fragment>
                <HeaderLabel>
                  <div className="text">
                    {__('Nebol dodržaný termín dodania?')}
                  </div>
                </HeaderLabel>
                <InfoWrapper>
                  {delivery_date_issues &&
                    delivery_date_issues.map(i => {
                      return (
                        <div className="row">{getQuestionTranslation(i)}</div>
                      );
                    })}
                  <div className="row">{delivery_date_issues_other}</div>
                </InfoWrapper>
              </React.Fragment>
            )}
          </MainWrapper>
        )}
      </React.Fragment>
    );
  }
}

const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }

  .description-wrapper {
    margin-left: 12px;
    font-size: 14px;
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

OrdersRatingDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrdersRatingDetail);
