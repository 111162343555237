import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
} from '../../Utils';
import { searchContacts } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/ordersRatings';
// import orderStates from '../../ContentConfig/order_states';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { StyledA, TestLabel } from '../../Components/ReusableComponents';
import { getRating } from './Detail';
import API2 from '../../API2';

class OrdersRatings extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'order_id' });
    this.headers = tableHeaders;
    this.segmentName = __('Hodnotenia');
    this.accessors = [
      'order_id',
      'customer',
      'created_date',
      'rating',
      'voucher_used',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'orderNo',
          label: __('Číslo objednávky'),
          value: '',
          type: 'input',
        },
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'rating',
          label: __('Hodnotenie'),
          values: [
            {
              name: __('Pozitívne'),
              value: 'POSITIVE',
              key: 1,
            },
            {
              name: __('Negatívne'),
              value: 'NEGATIVE',
              key: 2,
            },
          ],
          type: 'select',
        },
        {
          name: 'orderDateFrom',
          label: __('Dátum objednania od'),
          value: '',
          type: 'date',
        },
        {
          name: 'orderDateTo',
          label: __('Dátum objednania do'),
          value: '',
          type: 'date',
        },
      ],
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearch, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const [newData, { items: testEmails }] = await Promise.all([
        API2.getEshopOrderRatingsAction(this.props.firm, params),
        API2.getTestEmailsList(),
      ]);

      const content = this.normalizeColumns(newData.ratings, testEmails);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data, test_emails) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        order_id: _shapeData(
          renderLink(`./order-ratings/${item._id}`, item.order_id),
        ),
        customer: _shapeData(
          item.customer && item.customer._id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${item.contact_id}`}
            >
              {test_emails.includes(item.customer.email) && (
                <TestLabel>test</TestLabel>
              )}
              {item.customer.email}
            </StyledA>
          ) : (
            `${item.customer.email || ''} - ${__('odstranený')}`
          ),
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        rating: _shapeData(getRating(item.rating)),
        voucher_used: _shapeData(
          item.voucher_used ? __('Použitý') : __('Nepoužitý'),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Hodnotenia')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrdersRatings);
