import { __e as __ } from '../Utils';

export default [
  {
    name: __('Kód produktu'),
    clickable: false,
    otherProps: {
      maxWidth: 200,
    },
  },
  {
    name: __('Názov produktu'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
  },
  {
    name: __('Predaný počet'),
    clickable: false,
    otherProps: {
      maxWidth: 150,
    },
  },
];
