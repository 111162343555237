import moment from 'moment';
import { rem } from 'polished';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import {
  formatDate,
  formatPrice,
  __,
  renderLink,
  getStateLabel,
  getTypeOfClaimLabel,
  getEndDaysLabel,
  FORMAT_HUMANE_DATE,
} from '../../Utils';

const Wrapper = styled.div`
  .ReactTable {
    border: none;
    border-radius: 8px;
  }

  .ReactTable.-striped .rt-tr.-odd {
    background: #f8f1f8;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(40)};
  color: #6f6f6f;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ isComp }) => (isComp ? '#ee6500' : '#434245')};
  font-weight: ${({ isComp }) => (isComp ? '700' : '500')};
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

export default class WarrantyClaimsTable extends PureComponent {
  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  createColumns() {
    return [
      {
        accessor: 'claim_number',
        Header: () => <StyledHeader>{__('Číslo požiadavky')}</StyledHeader>,

        Cell: props => {
          return (
            <StyledTd>
              {renderLink(
                `./warranty-claims/${props.original._id}`,
                props.original.claim_number,
                true,
                16,
              )}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'customer',
        Header: () => <StyledHeader>{__('Zákazník')}</StyledHeader>,

        Cell: props => {
          return (
            <StyledTd>
              {props.value && props.value.name ? (
                <StyledA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_CRM_BASE_URL}/${
                    this.props.firm
                  }/contacts/${props.value._id}`}
                >
                  {props.value.name}
                </StyledA>
              ) : (
                `${props.value && props.value.email} - ${__('Odstranený')}`
              )}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'created_date',
        Header: () => <StyledHeader>{__('Dátum a čas zadania')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              {formatDate(moment(props.value), FORMAT_HUMANE_DATE)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'product',
        Header: () => <StyledHeader>{__('Produkt')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              <StyledA
                target="_blank"
                rel="noopener noreferrer"
                href={`${process.env.REACT_APP_ADMIN_BASE_URL}/${
                  this.props.firm
                }/products/${props.value.catalog_product_id}`}
              >
                {props.value ? props.value.item_name : '-'}
              </StyledA>
            </StyledTd>
          );
        },
      },
      {
        accessor: 'type',
        Header: () => <StyledHeader>{__('Typ požiadavky')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{getTypeOfClaimLabel(props.value)}</StyledTd>;
        },
      },
      {
        accessor: 'state',
        Header: () => <StyledHeader>{__('Stav požiadavky')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{getStateLabel(props.value)}</StyledTd>;
        },
      },
      {
        accessor: 'number_of_days_to_end',
        Header: () => (
          <StyledHeader>{__('Počet dní do ukončenia')}</StyledHeader>
        ),
        Cell: props => {
          return <StyledTd>{getEndDaysLabel(props.value)}</StyledTd>;
        },
      },
      {
        accessor: 'product',
        Header: () => <StyledHeader>{__('Cena s DPH')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{formatPrice(props.value.price)}</StyledTd>;
        },
      },
    ];
  }

  render() {
    const { data, loading } = this.props;

    return (
      <Wrapper>
        <ReactTable
          {...this.props}
          resizable={false}
          pageSize={data.length}
          columns={this.useCustomheader(this.createColumns())}
          data={data}
          showPagination={false}
          loading={loading}
          NoDataComponent={() => (
            <NoData>{__('Nie su žiadné nové reklamácie')}</NoData>
          )}
          className="-striped"
        />
      </Wrapper>
    );
  }
}
