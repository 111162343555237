import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CampaignsList from '../../Containers/CampaignsList';
import CampaignsDetail from '../../Containers/CampaignsDetail';

function CampaingsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CampaignsDetail} />
      <Route path={`${match.url}`} component={CampaignsList} />
    </Switch>
  );
}

CampaingsCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CampaingsCard;
