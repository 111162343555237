import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getOrderEomStateLabel,
  getOrderStateLabel,
  getPaymentStateLabel,
  renderLink,
  __,
  formatNumber,
} from '../../Utils';
import {
  searchContacts,
  searchDeliveryTypes,
  searchPaymentTypes,
  searchShopOrigin,
} from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/orders';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { admin_emails } from '../../ContentConfig/emails';
import OwnStateCheckbox from '../../Components/OwnStateCheckbox';
import { StyledA, TestLabel } from '../../Components/ReusableComponents';
import API2 from '../../API2';
import PopUpWrapper from '../../Components/PopUpWrapper';
import styled from 'styled-components';
import { rem } from 'polished';

const IconWrapper = styled.div`
  cursor: pointer;
`;

const PopUpContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
`;

const ImagesWrapper = styled.div``;

const ImagePreview = styled.img`
  width: ${rem(500)};
  margin-top: ${rem(5)};
  margin-right: ${rem(5)};
  border-radius: ${rem(4)};
  max-width: 98%;
`;

const orderStates = [
  {
    name: __('Nová'),
    value: 'new',
  },
  {
    name: __('Pripravuje sa'),
    value: 'ready_for_pickup',
  },
  {
    name: __('V preprave'),
    value: 'in_delivery',
  },
  {
    name: __('Doručená'),
    value: 'delivered',
  },
  {
    name: __('Dokončená'),
    value: 'finished',
  },
  {
    name: __('Stornovaná'),
    value: 'canceled',
  },
];

const baseURL = `${process.env.REACT_APP_IMG_DOMAIN}/images/500x500`;

class Orders extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'order_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Objednávky');
    this.accessors = [
      'admin_finish_checkbox',
      'id',
      'customer',
      'attachments',
      'user_finish_checkbox',
      'order_date',
      'delivery_address',
      'payment_type',
      'delivery_type',
      'payment_state',
      'order_state',
      'total_price_vat',
      'total_price',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      showAttachmentsPopUp: false,
      popupData: [],
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'orderNo',
          label: __('Číslo objednávky'),
          value: '',
          type: 'input',
        },
        {
          name: 'eomState',
          label: __('Stav'),
          value: '',
          values: orderStates,
          type: 'select',
        },
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'priceFrom',
          label: __('Celková cena od'),
          value: '',
          type: 'number',
        },
        {
          name: 'priceTo',
          label: __('Celková cena do'),
          value: '',
          type: 'number',
        },
        {
          name: 'orderDateFrom',
          label: __('Dátum objednania od'),
          value: '',
          type: 'date',
        },
        {
          name: 'orderDateTo',
          label: __('Dátum objednania do'),
          value: '',
          type: 'date',
        },
        {
          name: 'paymentType',
          label: __('Spôsob platby'),
          value: '',
          type: 'search-select',
          onSearch: q => searchPaymentTypes(q, this.props.firm),
        },
        {
          name: 'deliveryType',
          label: __('Spôsob dodania'),
          value: '',
          type: 'search-select',
          onSearch: q => searchDeliveryTypes(q, this.props.firm),
        },
        {
          name: 'originShop',
          label: __('Pôvod balíkov'),
          value: '',
          type: 'search-select',
          onSearch: () => searchShopOrigin(this.props.firm),
        },
      ],
    };
  }

  async exportCSVData() {
    const { sorter, selectedCustomer, lastSearch, filters } = this.state;

    this.setState({
      exportLoading: true,
    });

    const filterToSend = {};

    filters
      .filter(f => f.value && f.value !== '-')
      .forEach(f => {
        if (f.type === 'date') {
          filterToSend[f.name] = new Date(f.value).toISOString();
        } else if (f.type === 'search-select') {
          filterToSend[f.name] = f.value.value;
        } else {
          filterToSend[f.name] = f.value;
        }
      });

    const params = Object.assign({}, filterToSend, { sorter });

    if (!params.q) {
      params.q = lastSearch;
    }

    if (selectedCustomer) {
      params.customerId = selectedCustomer.value;
    }

    try {
      const newData = await API2.exportEshopOrdersToCSVAction(
        this.props.firm,
        params,
      );
      window.location.href = newData.url;

      this.setState({
        exportLoading: false,
      });
    } catch (e) {
      this.setState({
        error: __('Objednávky sa nepodarilo exportovať'),
        exportLoading: false,
      });
    }
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearch, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const [newData, { items: testEmails }] = await Promise.all([
        API2.getEshopOrdersAction(this.props.firm, params),
        API2.getTestEmailsList(),
      ]);

      const content = this.normalizeColumns(newData.items, testEmails);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  async postDataCheckbox(field, value, orderId) {
    try {
      let rawData = { body: {} };

      if (field === 'admin') {
        rawData.body.admin_state = value;
      } else if (field === 'user') {
        rawData.body.user_state = value;
      }

      await API2.putEshopOrderAction(this.props.firm, orderId, rawData);
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri aktualizácií dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data, test_emails) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(renderLink(`./orders/${item._id}`, item._id)),
        customer: _shapeData(
          item.customer && item.customer._id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/contacts/${item.customer._id}`}
            >
              {test_emails.includes(item.customer.email) && (
                <TestLabel>test</TestLabel>
              )}
              {item.customer.email}
            </StyledA>
          ) : (
            `${item.customer.email} - ${__('odstranený')}`
          ),
        ),
        order_date: _shapeData(
          formatDate(moment(item.order_date), FORMAT_HUMANE_DATETIME),
        ),
        delivery_address: _shapeData(
          `${item.delivery_address.city},${item.delivery_address.street},${item.delivery_address.postal_code}`,
        ),
        payment_type: _shapeData(item.payment_type && item.payment_type.label),
        delivery_type: _shapeData(
          item.delivery_type && item.delivery_type.label,
        ),
        payment_state: _shapeData(getPaymentStateLabel(item.payment_state)),
        order_state: item.eom_state
          ? _shapeData(getOrderEomStateLabel(item.eom_state))
          : _shapeData(getOrderStateLabel(item.order_state)),
        total_price_vat: _shapeData(
          formatNumber(item.total_price_vat.amount, 2),
        ),
        total_price: _shapeData(formatNumber(item.total_price.amount, 2)),
        admin_finish_checkbox:
          admin_emails.includes(this.props.user.email) &&
          _shapeData(
            <OwnStateCheckbox
              defaultChecked={item.eom_order_admin_finish}
              onChange={e => {
                this.postDataCheckbox('admin', e, item._id);
              }}
            />,
          ),
        user_finish_checkbox: _shapeData(
          <OwnStateCheckbox
            defaultChecked={item.eom_order_user_finish}
            onChange={e => {
              this.postDataCheckbox('user', e, item._id);
            }}
          />,
        ),
        attachments: item.files.length
          ? _shapeData(
              <IconWrapper
                onClick={() =>
                  this.setState({
                    showAttachmentsPopUp: true,
                    popupData: item.files,
                  })
                }
              >
                <img
                  src="/images/icons/attachment.svg"
                  height="24px"
                  alt="attachment"
                />
              </IconWrapper>,
            )
          : '',
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Objednávky')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const { showAttachmentsPopUp, popupData } = this.state;

    return (
      <React.Fragment>
        {super.render()}

        <PopUpWrapper
          display={showAttachmentsPopUp}
          onClose={() => this.setState({ showAttachmentsPopUp: false })}
          onEscClose={() => this.setState({ showAttachmentsPopUp: false })}
          message={__('Fotky balíkov')}
        >
          <PopUpContent>
            {popupData.map(a => (
              <ImagesWrapper>
                <ImagePreview src={`${baseURL}/${a.fullPath}`} />
              </ImagesWrapper>
            ))}
          </PopUpContent>
        </PopUpWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Orders);
