import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import DepositWithdrawalsList from '../../Containers/DepositWithdrawalsList';
import DepositWithdrawalDetail from '../../Containers/DepositWithdrawalDetail';

function DepositWithdrawalsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={DepositWithdrawalDetail} />
      <Route path={`${match.url}`} component={DepositWithdrawalsList} />
    </Switch>
  );
}

DepositWithdrawalsCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default DepositWithdrawalsCard;
