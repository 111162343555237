import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
  formatCurrencyByFirm,
} from '../../Utils';
import {
  searchCashRegisters,
  searchShops,
} from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/depositWithdrawalsList';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SearchableSelect from '../../Components/SearchableSelect';
import styled from 'styled-components';
import { Label } from 'oxyrion-ui/lib';
import OwnStateInput from '../../Components/OwnStateInput';

const InputWrapper = styled.div`
  width: 96%;
`;

const operationTypes = [
  {
    name: __('Vklad'),
    value: 'DEPOSIT',
  },
  {
    name: __('Výber'),
    value: 'WITHDRAWAL',
  },
];

class DepositWithdrawalsList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Vklady a výbery z pokladne');
    this.accessors = [
      'id',
      'cashRegisterId',
      'created_date',
      'action',
      'amount',
      'description',
      'nav_status',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      showFilter: false,
      activeMessage: 'Všetky',
      sorter: '-created_date',
      filters: [
        {
          name: 'shopId',
          label: __('Predajne'),
          value: '',
          type: 'search-select',
          onSearch: q => searchShops(this.props.firm),
        },
        {
          name: 'action',
          label: __('Operácia'),
          value: '',
          values: operationTypes,
          type: 'select',
        },
        {
          name: 'fromDate',
          label: __('Dátum od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum do'),
          value: '',
          type: 'date',
        },
      ],
      showDepositWithdrawPopUp: false,
      depositWithdrawLoading: false,
      depositWithdrawData: {},
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      const newData = await API2.getKasaDepositWithdrawalsListAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(
          renderLink(
            `./deposit-withdrawals/${item._id}`,
            `${formatDate(
              moment(item.created_date),
              FORMAT_HUMANE_DATETIME,
            )} - ${item.cr_id}`,
          ),
        ),
        cashRegisterId: _shapeData(item.cr_id),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        action: _shapeData(item.data.amount >= 0 ? __('VKLAD') : __('VÝBER')),
        amount: _shapeData(
          `${item.data.amount} ${formatCurrencyByFirm(this.props.firm)}`,
        ),
        description: _shapeData(item.data.description),
        nav_status: item.nav_status && _shapeData(item.nav_status.state),
      });
      return acumulator;
    }, []);
    return result;
  }

  handleChange(field, value) {
    let { depositWithdrawData } = this.state;

    depositWithdrawData[field] = value;

    if (field === 'amount') {
      const generatedID = this.generateID();
      depositWithdrawData.description = generatedID;
    }

    this.setState({ depositWithdrawData });
  }

  async saveDepositWithdraw() {
    let { depositWithdrawData, showDepositWithdrawPopUp } = this.state;

    this.setState({
      depositWithdrawLoading: true,
    });

    try {
      const body = {
        cr_id: depositWithdrawData.cr.value,
        amount:
          showDepositWithdrawPopUp === 'DEPOSIT'
            ? Math.abs(Number(depositWithdrawData.amount))
            : Number(depositWithdrawData.amount) * -1,
        description: depositWithdrawData.description,
        type: 'TRANSACTION',
      };

      await API2.postDepositWithdrawAction({ body });

      this.setState({
        showDepositWithdrawPopUp: false,
        depositWithdrawLoading: false,
      });
      this.fetchData();
    } catch (e) {
      console.log(e);
      if (e.response.status === 403) {
        this.setState({
          error: __('Nemáte potrebné práva pre túto operaciu'),
          depositWithdrawLoading: false,
          showDepositWithdrawPopUp: false,
        });
      } else if (
        e.response.status === 400 &&
        depositWithdrawData.description.length >= 40
      ) {
        this.setState({
          error:
            showDepositWithdrawPopUp === 'DEPOSIT'
              ? __('ID vkladu nesmie mať viac ako 40 znakov')
              : __('ID výberu nesmie mať viac ako 40 znakov'),
          depositWithdrawLoading: false,
          showDepositWithdrawPopUp: false,
        });
      } else {
        this.setState({
          error:
            showDepositWithdrawPopUp === 'DEPOSIT'
              ? __('Pri vklade sa vyskytla neočákavana chyba')
              : __('Pri výbere sa vyskytla neočákavana chyba'),
          depositWithdrawLoading: false,
          showDepositWithdrawPopUp: false,
        });
      }
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Vklady a výbery z pokladne')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showDepositWithdrawPopUp: 'DEPOSIT',
            })
          }
          small
          primary
          basic
          showIcon={false}
        >
          {__('Transakcia vklad')}
        </ControlBarButton>
        <ControlBarButton
          onClick={() =>
            this.setState({
              showDepositWithdrawPopUp: 'WITHDRAWAL',
            })
          }
          small
          primary
          basic
          showIcon={false}
        >
          {__('Transakcia výber')}
        </ControlBarButton>
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  generateID() {
    const { depositWithdrawData, showDepositWithdrawPopUp } = this.state;

    const date = moment();
    const formattedDate = date.format('DDMMYYYY');

    const value = depositWithdrawData.amount || 0;
    const formattedValue = value.toString().padStart(5, '0');

    const type = showDepositWithdrawPopUp === 'DEPOSIT' ? 'd' : 'w';

    const generatedID = `${formattedDate}${type}${formattedValue}`;

    return generatedID;
  }

  render() {
    const {
      showDepositWithdrawPopUp,
      depositWithdrawData,
      depositWithdrawLoading,
    } = this.state;

    return (
      <React.Fragment>
        {super.render()}
        <PopUpWrapper
          display={showDepositWithdrawPopUp}
          small
          onClose={() => this.setState({ showDepositWithdrawPopUp: false })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showDepositWithdrawPopUp: false })}
          message={
            showDepositWithdrawPopUp === 'DEPOSIT'
              ? __('Vykonať transakciu vkladu?')
              : __('Vykonať transakciu výberu?')
          }
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showDepositWithdrawPopUp: false })}
            onDismiss={() => this.saveDepositWithdraw()}
            dismissLoading={depositWithdrawLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Odoslať')}
            message={
              <div>
                <InputWrapper>
                  <Label>{__('Id pokladne* ')}</Label>
                  <SearchableSelect
                    value={depositWithdrawData.cr}
                    loadOptions={query => searchCashRegisters(this.props.firm)}
                    handleOnChange={e => this.handleChange('cr', e)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{__('ID dokladu* ')}</Label>
                  <OwnStateInput
                    onBlur={e =>
                      this.handleChange('description', e.target.value)
                    }
                    placeholder={'O-4A4D92C9542D4A0C8D92C9542D0A0CB9'}
                    disabled={!depositWithdrawData.amount}
                    value={depositWithdrawData.description}
                    /* value={this.generateID()} */
                  />
                </InputWrapper>
                <InputWrapper>
                  <Label>{__('Suma* ')}</Label>
                  <OwnStateInput
                    onBlur={e => this.handleChange('amount', e.target.value)}
                    placeholder={'12.5'}
                    value={depositWithdrawData.amount}
                  />
                </InputWrapper>
              </div>
            }
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DepositWithdrawalsList);
