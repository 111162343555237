import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  __,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';

const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;


const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }

  .description-wrapper {
    margin-left: 12px;
    font-size: 14px;
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

class DailyReportDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      sendOrderDateNotify: false,
      notifyCustomer: true,
      orderEmailConflictData: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getKasaDailyReportDetailAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      const baseInfo = [
        {
          label: __('Dátum vytvorenia'),
          value: formatDate(moment(result.created_date), FORMAT_HUMANE_DATETIME),
        },
        {
          label: __('ID pokladne'),
          value: result.data.cashRegisterId
        },
        {
          label: __('Status'),
          value: result.data.state
        },
        {
          label: __('Fiškálny deň'),
          value: result.data.fiskalDate,
        },
        {
          label: __('ID uzávierky v pokladni'),
          value: result.data.terminalDailyCloseId,
        },
      ];


      this.setState({
        loading: false,
        submitPaymentLaoding: false,
        internalNoteSaving: false,
        data: Object.assign(result, {
          baseInfo,
        }),
      });
    } catch (e) {
      this.setState({
        error: __('Dennú uzávierku sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return <ControllBar history={history} name={`Denná uzávierka ${data._id}`} />;
  }

  renderRow(i, disabledEditButtons) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && !disabledEditButtons && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      data,
      loading,
      error,
      success,
    } = this.state;

    const disabledEditButtons = false;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>


          <HeaderLabel>
            <div className="text">{__('Základné informácie')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.baseInfo &&
              data.baseInfo.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>


        </MainWrapper>
      </React.Fragment>
    );
  }
}

DailyReportDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DailyReportDetail);
