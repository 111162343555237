import { translateClaimState } from '../Utils';

export default [
  {
    name: translateClaimState('new'),
    value: 'new',
    key: 1,
  },
  {
    name: translateClaimState('waiting_for_merchandise'),
    value: 'waiting_for_merchandise',
    key: 2,
  },
  {
    name: translateClaimState('in_process'),
    value: 'in_process',
    key: 3,
  },
  {
    name: translateClaimState('finished'),
    value: 'finished',
    key: 4,
  },
  {
    name: translateClaimState('cancelled'),
    value: 'cancelled',
    key: 5,
  },
];
