import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Label from 'oxyrion-ui/lib/Label/Label';
import Input from 'oxyrion-ui/lib/Input';
import { __ } from '../../Utils';
import { searchCashRegisters } from '../../Utils/ApiSearchFunctions';
import SearchableSelect from '../SearchableSelect';
import ConfirmDialog from '../ConfirmDialog';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

class ReceiptEdit extends Component {
  state = {
    receiptData: undefined,
  };

  handleChange(field, value) {
    let { receiptData } = this.state;

    if (!receiptData) {
      receiptData = Object.assign({}, this.props.receiptData || {});
    }
    receiptData[field] = value;
    this.setState({ receiptData });
  }

  getWarehouses() {
    const { receiptData } = this.state;

    if (!receiptData.cr || !receiptData.cr) {
      return [];
    }

    return {
      options: receiptData.cr.raw.stocks.map(item => ({
        value: item,
        label: item,
      })),
    };
  }

  getPaymentsCodes() {
    const { receiptData } = this.state;

    if (!receiptData.cr || !receiptData.cr) {
      return [];
    }

    return {
      options: receiptData.cr.raw.payment_methods.map(item => ({
        value: receiptData.cr.raw.payment_code[item],
        label: `${item} (${receiptData.cr.raw.payment_code[item]})`,
      })),
    };
  }

  handleSubmit(data) {
    if (
      data.createDate &&
      data.issueDate &&
      data.cr &&
      data.payment_code &&
      data.warehouse
    ) {
      this.props.onConfirm(data);
    }
  }

  render() {
    const { receiptData: propsData } = this.props;
    const { receiptData: stateData } = this.state;

    const data = stateData || propsData;

    return (
      <ConfirmDialog
        onConfirm={() => this.props.onDismiss()}
        onDismiss={() => this.props.onConfirm(data)}
        //dismissLoading={searchReceiptLoading}
        confirmMessage={__('Zrušiť')}
        dismissMessage={__('Odoslať do NAV')}
        message={
          <Wrapper>
            <InputWrapper>
              <Label>{__('Čas a dátum vytvorenia*')}</Label>
              <Input
                onChange={e => this.handleChange('createDate', e.target.value)}
                placeholder={'DD-MM-YYYY hh:mm:ss'}
                value={data.createDate}
                error={!data.createDate}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Čas a dátum platby*')}</Label>
              <Input
                onChange={e => this.handleChange('issueDate', e.target.value)}
                placeholder={'DD-MM-YYYY hh:mm:ss'}
                value={data.issueDate}
                error={!data.issueDate}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Id pokladne*')}</Label>
              <SearchableSelect
                value={data.cr}
                loadOptions={query => searchCashRegisters(this.props.firm)}
                handleOnChange={e => this.handleChange('cr', e)}
                error={!data.cr}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Sklad*')}</Label>
              <SearchableSelect
                key={data.cr}
                value={data.warehouse}
                loadOptions={query => this.getWarehouses()}
                handleOnChange={e => this.handleChange('warehouse', e)}
                error={!data.warehouse}
              />
            </InputWrapper>

            <InputWrapper>
              <Label>{__('Kód platby*')}</Label>
              <SearchableSelect
                key={data.cr}
                value={data.payment_code}
                loadOptions={query => this.getPaymentsCodes()}
                handleOnChange={e => this.handleChange('payment_code', e)}
                error={!data.payment_code}
              />
            </InputWrapper>
          </Wrapper>
        }
      />
    );
  }
}

ReceiptEdit.propTypes = {
  receiptData: PropTypes.shape({}),
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  firm: PropTypes.string,
};

ReceiptEdit.defaultProps = {
  receiptData: {},
  onConfirm: () => {},
  onDismiss: () => {},
  firm: 'SK',
};

export default ReceiptEdit;
