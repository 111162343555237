import { __e as __ } from '../Utils';

export default [
  {
    name: __('ID'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Pokladňa'),
    clickable: false,
    otherProps: {
      minWidth: 50,
    },
  },
  {
    name: __('Dátum'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Operácia'),
    clickable: false,
  },
  {
    name: __('Suma'),
    clickable: true,
    sorterValue: 'amount',
  },
  {
    name: __('Popis'),
    clickable: false,
  },
  {
    name: __('NAV status'),
    clickable: false,
  },
];
