import React, { PureComponent } from 'react';
import Theme from 'oxyrion-ui/lib/Themes/themeEOM';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatPrice, __ } from '../../Utils';

export default class BarChartComponent extends PureComponent {
  formatXAxis(amount, appLang) {
    return formatPrice({
      amount,
      currency: appLang === 'SK' ? 'EUR' : 'CZK',
    });
  }

  render() {
    const { data, title, subTitle, appLang, responsive } = this.props;

    const CustomToolTip = props => {
      const { active, payload } = props;
      if (!active || !payload) {
        return null;
      }

      return (
        <div className="custom-tooltip">
          {payload.map(item => (
            <p key={item.payload.name}>
              {item.name}:{' '}
              <strong>
                {formatPrice({
                  amount: item.payload.amount,
                  currency: appLang === 'SK' ? 'EUR' : 'CZK',
                })}
              </strong>
            </p>
          ))}
        </div>
      );
    };

    return (
      <Wrapper>
        <div className="title">{title}</div>
        <div className="sub_title">{subTitle}</div>
        <ResponsiveContainer
          height={350}
          minWidth={responsive ? undefined : 1200}
        >
          <BarChart
            width={150}
            height={40}
            data={data}
            margin={{
              top: 5,
              right: 35,
              left: 35,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis unit={appLang === 'SK' ? `\u00a0€` : `\u00a0Kč`} />
            <Tooltip content={<CustomToolTip />} />
            <Bar
              name={__('Celkový predaj')}
              dataKey="amount"
              fill={Theme.color.primary}
            />
          </BarChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background: white;
  padding: ${rem(16)};
  border-radius: ${rem(8)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: auto;

  .title {
    font-size: ${rem(22)};
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: ${rem(8)};
  }
  .sub_title {
    font-size: ${rem(16)};
    font-weight: bold;
    color: #7c828a;
    text-align: center;
    margin-bottom: ${rem(16)};
  }

  .test {
    background-color: rebeccapurple;
  }
`;

BarChartComponent.propTypes = {
  appLang: PropTypes.string,
  responsive: PropTypes.bool,
};

BarChartComponent.defaultProps = {
  appLang: 'SK',
  responsive: true,
};
