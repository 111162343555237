import { __e as __ } from '../Utils';

export default [
  {
    name: __('Číslo dokladu'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
  },
  {
    name: __('Pokladňa'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
  },
  {
    name: __('Zákazník'),
    clickable: false,
    otherProps: {
      minWidth: 200,
    },
  },
  {
    name: __('Cena bez DPH'),
    clickable: true,
    sorterValue: 'price',
  },
  {
    name: __('Cena s DPH'),
    clickable: true,
    sorterValue: 'price',
  },
  {
    name: __('Dátum zaúčtovania'),
    clickable: true,
    sorterValue: 'createdDate',
    otherProps: {
      minWidth: 160,
    },
  },
  {
    name: __('Typ'),
    clickable: false,
  },
  {
    name: __('Spôsob platby'),
    clickable: false,
  },
  {
    name: __('Navision stav'),
    clickable: false,
  },
];
