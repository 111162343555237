import { translateClaimResult } from '../Utils';

export default [
  {
    name: translateClaimResult('accepted'),
    value: 'accepted',
    key: 1,
  },
  {
    name: translateClaimResult('rejected'),
    value: 'rejected',
    key: 2,
  },
];
