import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import OrderList from '../../Containers/Orders';
import OrderDetail from '../../Containers/OrderDetail';

function OrdersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={OrderDetail} />
      <Route path={`${match.url}`} component={OrderList} />
    </Switch>
  );
}

OrdersCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default OrdersCard;
