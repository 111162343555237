import React, { PureComponent } from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Cell,
} from 'recharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';

export default class BarChartAdvertisementComponent extends PureComponent {
  getColorForPercentage(percentage) {
    const clampedPercentage = Math.max(0, Math.min(100, percentage));

    const red = Math.floor(255 * (1 - clampedPercentage / 100));
    const green = Math.floor(255 * (clampedPercentage / 100));

    const hexRed = red.toString(16).padStart(2, '0');
    const hexGreen = green.toString(16).padStart(2, '0');

    return `#${hexRed}${hexGreen}00`;
  }

  render() {
    const { data, title, subTitle } = this.props;

    const CustomToolTip = props => {
      const { active, payload } = props;
      if (!active || !payload) {
        return null;
      }

      return (
        <div className="custom-tooltip">
          {payload.map(item => (
            <p key={item.payload.name}>
              {item.name} {item.payload.name} {`(${item.payload.date})`}:{' '}
              <strong>{`${item.payload.value} %`}</strong>
            </p>
          ))}
        </div>
      );
    };

    return (
      <Wrapper>
        <div className="title">{title}</div>
        <div className="sub_title">{subTitle}</div>
        <ResponsiveContainer height={350}>
          <BarChart
            width={150}
            height={40}
            data={data}
            margin={{
              top: 5,
              right: 35,
              left: 35,
              bottom: 5,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis unit={`%`} />
            <Tooltip content={<CustomToolTip />} />

            <Bar name={__('Odporúčanie')} dataKey="value">
              {data &&
                data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={this.getColorForPercentage(entry.value)}
                  />
                ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  background: white;
  padding: ${rem(16)};
  border-radius: ${rem(8)};
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: ${rem(22)};
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: ${rem(8)};
  }
  .sub_title {
    font-size: ${rem(16)};
    font-weight: bold;
    color: #7c828a;
    text-align: center;
    margin-bottom: ${rem(16)};
  }

  .test {
    background-color: rebeccapurple;
  }
`;

BarChartAdvertisementComponent.propTypes = {
  appLang: PropTypes.string,
};

BarChartAdvertisementComponent.defaultProps = {
  appLang: 'SK',
};
