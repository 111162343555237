import React from 'react';
import {
  __,
  FORMAT_HUMANE_DATE,
  formatDate,
  formatPrice,
  getFirmPrice,
} from '../../Utils';
import styled from 'styled-components';
import moment from 'moment';
import { rem } from 'polished';
import DailyOrdersTable from '../../Components/DailyOrdersTable';
import API2 from '../../API2';

const Wrapper = styled.div`
  margin-top: ${rem(24)};
  font-size: ${rem(12)};
  display: flex;
  flex-direction: column;
  gap: ${rem(18)};
  justify-content: space-between;
  width: 100%;

  ${({ theme }) => theme.media.m`
    flex-direction: row;
  `};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
    font-size: ${rem(16)};
    margin-top: ${rem(16)};
  }

  .col {
    display: flex;
    flex-direction: column;
    background: white;
    border-radius: ${rem(8)};
    padding: ${rem(8)};
    width: 100%;

    ${({ theme }) => theme.media.m`
      width: 40%;
    `};

    .daily-header {
      margin-left: ${rem(12)};
      height: ${rem(40)};
      color: #a448a3;
      display: flex;
      align-items: center;
      margin-bottom: ${rem(14)};
      font-size: ${rem(20)};
    }
  }

  .table-wrapper {
    width: 100%;

    ${({ theme }) => theme.media.m`
     width: 70%;
     `};
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
    color: #434245;
  }
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  margin-top: ${rem(14)};
  background-color: #f8f1f8;
`;

class DailyOrdersDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stats: [],
      tableData: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const startToday = moment()
        .startOf('day')
        .format();
      const endToday = moment(startToday)
        .endOf('day')
        .format();

      const [orders, ordersStats] = await Promise.all([
        API2.getEshopOrdersAction(this.props.firm, {
          orderDateFrom: startToday,
          orderDateTo: endToday,
          excludeTest: true,
        }),
        API2.getEshopOrdersStatsAction(this.props.firm, {
          fromDate: startToday,
          toDate: endToday,
        }),
      ]);

      const normalizedStats = [
        {
          label: __('Dátum'),
          value: ordersStats.days[0]
            ? formatDate(moment(ordersStats.days[0].name), FORMAT_HUMANE_DATE)
            : formatDate(moment(startToday), FORMAT_HUMANE_DATE),
        },
        {
          label: __('Počet objednávok'),
          value: orders.items.length || 0,
        },
        {
          label: __('Obrat'),
          value: ordersStats.days[0]
            ? formatPrice(
                getFirmPrice(this.props.firm, ordersStats.days[0].amount),
              )
            : formatPrice(getFirmPrice(this.props.firm, 0)),
        },
      ];

      this.setState({
        tableData: orders.items,
        stats: normalizedStats,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { stats, tableData } = this.state;

    return (
      <Wrapper>
        <div className="col">
          <div className="daily-header">{__('Súhrn')}</div>
          <div>
            {stats.map((stat, index) => (
              <React.Fragment key={index}>
                <div className="row">
                  <div className="label">{stat.label}:</div>
                  <div className="value">{stat.value}</div>
                </div>
                <Line />
              </React.Fragment>
            ))}
          </div>
        </div>
        <div className="col table-wrapper">
          <DailyOrdersTable data={tableData} loading={this.props.loading} />
        </div>
      </Wrapper>
    );
  }
}

export default DailyOrdersDashboard;
