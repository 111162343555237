import React from 'react';
import BaseTable from '../BaseTable';
import headers from '../../ContentConfig/history';
import { __, renderLink } from '../../Utils';
import API2 from '../../API2';

class History extends BaseTable {
  constructor(props) {
    super({ ...props, turnOfSorter: true });
    this.headers = headers;
    this.showBulkOperations = false;
    this.accessors = ['title'];
    this.segmentName = __('História');
    this.state = {
      ...this.state,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const history = await API2.getUserRecentsAction({
        limit: 10,
      });

      this.setState({
        content: this.normalizeColumns(history.recents),
        loading: false,
      });
    } catch (e) {
      this.setState({
        error: e,
        loading: false,
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        title: _shapeData(renderLink(item.url, item.title)),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    return <React.Fragment />;
  }
}

export default History;
