import moment from 'moment';
import { rem } from 'polished';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import {
  formatDate,
  __,
  renderLink,
  FORMAT_HUMANE_DATETIME,
} from '../../Utils';

const Wrapper = styled.div`
  .ReactTable {
    border: none;
    border-radius: 8px;
  }

  .ReactTable.-striped .rt-tr.-odd {
    background: #f8f1f8;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(40)};
  color: #6f6f6f;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ isComp }) => (isComp ? '#ee6500' : '#434245')};
  font-weight: ${({ isComp }) => (isComp ? '700' : '500')};
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const StyledTdStart = styled(StyledTd)`
  display: flex;
  justify-content: flex-start;
`;

export default class MessagesTable extends PureComponent {
  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  createColumns() {
    return [
      {
        accessor: 'order_id',
        Header: () => <StyledHeader>{__('Číslo objednávky')}</StyledHeader>,
        maxWidth: 140,
        Cell: props => {
          return (
            <StyledTd>
              {renderLink(`./orders/${props.value}`, props.value, true, 16)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'created_date',
        Header: () => <StyledHeader>{__('Dátum a čas')}</StyledHeader>,
        maxWidth: 140,
        Cell: props => {
          return (
            <StyledTd>
              {formatDate(moment(props.value), FORMAT_HUMANE_DATETIME)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'contact',
        Header: () => <StyledHeader>{__('Vytvoril')}</StyledHeader>,
        maxWidth: 200,
        Cell: props => {
          return (
            <StyledTd>
              {props.value && props.value._id && (
                <StyledA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_CRM_BASE_URL}/${
                    this.props.firm
                  }/contacts/${props.value._id}`}
                >
                  {props.value.email}
                </StyledA>
              )}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'text',
        Header: () => <StyledHeader>{__('Vytvoril')}</StyledHeader>,
        Cell: props => {
          return <StyledTdStart>{props.value}</StyledTdStart>;
        },
      },
    ];
  }

  render() {
    const { data, loading } = this.props;

    return (
      <Wrapper>
        <ReactTable
          {...this.props}
          resizable={false}
          pageSize={data.length}
          columns={this.useCustomheader(this.createColumns())}
          data={data}
          showPagination={false}
          loading={loading}
          NoDataComponent={() => (
            <NoData>{__('Nie su žiadné nové objednávky')}</NoData>
          )}
          className="-striped"
        />
      </Wrapper>
    );
  }
}
