import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import BaseTable from '../BaseTable';
import {
  formatDate,
  getStateLabel,
  getTypeOfClaimLabel,
  renderLink,
  getEndDaysLabel,
  __,
} from '../../Utils';
import { searchContacts } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/warranty_claims';
import warrantyClaimTypes from '../../ContentConfig/warranty_claim_types';
import warrantyClaimStates from '../../ContentConfig/warranty_claim_states';
import warrantyResultSolutions from '../../ContentConfig/warranty_claims_result_solutions';
import warrantyResults from '../../ContentConfig/warranty_claims_results';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

class WarrantyClaims extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Reklamácie');
    this.accessors = [
      'claim_number',
      'type',
      'customer',
      'product',
      'created_date',
      'updated_date',
      'state',
      'result_solution',
      'result',
      'number_of_days_to_end',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearchValue: '',
      showFilter: false,
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'type',
          label: __('Typ'),
          values: warrantyClaimTypes,
          type: 'select',
        },
        {
          name: 'state',
          label: __('Stav'),
          values: warrantyClaimStates,
          type: 'select',
        },
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'resultSolution',
          label: __('Spôsob vyriešenia'),
          values: warrantyResultSolutions,
          type: 'select',
        },
        {
          name: 'result',
          label: __('Spôsob ukončenia'),
          values: warrantyResults,
          type: 'select',
        },
        {
          name: 'createdDateFrom',
          label: __('Dátum zadania od'),
          value: '',
          type: 'date',
        },
      ],
    };
  }
  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearchValue, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearchValue;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const newData = await API2.getEshopWarrantyClaimsAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  async loadSearchResult(value) {
    this.setState({ lastSearchValue: value.toLowerCase() });
    this.fetchData();
  }
  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        claim_number: _shapeData(
          renderLink(`./warranty-claims/${item._id}`, item.claim_number),
        ),
        type: _shapeData(getTypeOfClaimLabel(item.type)),
        customer: _shapeData(
          item.customer && item.customer._id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/contacts/${item.customer._id}`}
            >
              {item.customer.name}
            </StyledA>
          ) : (
            `${__('Odstranený')}`
          ),
        ),
        product: _shapeData(
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_ADMIN_BASE_URL}/${this.props.firm}/products/${item.product.catalog_product_id}`}
          >
            {item.product ? item.product.item_name : '-'}
          </StyledA>,
        ),
        created_date: _shapeData(formatDate(moment(item.created_date))),
        updated_date: _shapeData(formatDate(moment(item.updated_date))),
        state: _shapeData(getStateLabel(item.state)),
        result_solution: _shapeData(
          item.result_solution && item.result_solution.label,
        ),
        result: _shapeData(item.result && item.result.label),
        number_of_days_to_end: _shapeData(
          getEndDaysLabel(item.number_of_days_to_end),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter, lastSearchValue } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Reklamácie')}
        defaultValue={lastSearchValue}
        onChange={val => this.loadSearchResult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(WarrantyClaims);
