import styled from 'styled-components';
import { rem } from 'polished';
import { Tr, Td } from 'oxyrion-ui/lib/Table';

export const SummaryWrapper = styled.div`
  text-overflow: ellipsis;
  max-width: ${rem(200)};
  white-space: nowrap;
  overflow: hidden;
`;

export const FlexBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ColumnFlexBox = styled(FlexBox)`
  flex-direction: column;
`;

export const FlexBoxStart = styled(FlexBox)`
  justify-content: flex-start;
`;

export const FlexBoxEnd = styled(FlexBox)`
  justify-content: flex-end;
`;

export const Centerer = styled(FlexBox)``;

export const FlexItem = styled.div`
  flex ${({ flex }) => flex || '1'};
  width: 100%;
`;

export const ButtonRelativeWrapperLeft = styled.div`
  position: fixed;
  bottom: ${rem(10)};
  right: ${rem(10)};
  z-index: 1000;
  ${({ theme }) => theme.media.m`
  bottom: ${rem(50)};
  left: ${rem(50)};
  display: flex;
  justify-content: flex-end;
`};
`;

export const RouteLinkWraper = styled.li`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize || '14px'};
  list-style: none;
  a {
    text-decoration: none;
    margin: ${({ noMargin }) => (noMargin ? '0' : `${rem(10)}`)};
    padding: 0;
    display: block;
    color: ${({ theme, color }) => color || theme.color.primary};
    cursor: pointer;
    white-space: nowrap;
  }
  a:visited {
    color: ${({ theme, color }) => color || theme.color.primary};
  }
  a:hover {
    text-decoration: underline;
  }
`;

export const H2 = styled.h2`
  max-width: ${({ theme }) => theme.maxWidth || 'auto'};
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize || '14px'};
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const H3 = styled.h3`
  max-width: ${({ theme }) => theme.maxWidth || 'auto'};
  padding: 0;
  margin: 0;
  // margin-left: ${rem(5)};
  text-align: left;
  font-size: ${({ theme }) => theme.fontSize || '14px'};
  font-family: ${({ theme }) => theme.fontFamily};
`;

export const SegmentWrapper = styled.div`
  width: 100%;
  border-bottom: solid ${rem(2)} ${({ theme }) => theme.separatorColor};
  height: auto;
  border-left: ${({ left, theme }) =>
    left ? `solid ${rem(2)}  ${theme.separatorColor}` : 'none'}
  border-right: ${({ right, theme }) =>
    right ? `solid ${rem(2)}  ${theme.separatorColor}` : 'none'}
  margin-bottom: 1px;
`;

export const PageNavigatorWrapper = styled.div`
  margin: ${rem(15)} ${rem(10)};
  font-size: ${({ theme }) => theme.fontSize || '14px'};
`;

export const IconWrapper = styled.img`
  display: ${({ display }) => (display ? 'inline-block' : 'none')};
  margin: ${rem(2)} ${rem(5)};
`;

export const DivIconWrapper = styled.div`
  cursor: pointer;
`;

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: ${rem(50)} auto 0;
  min-height: 20vh;
`;

export const DisableAbleTrow = styled(Tr)`
  ${({ disabled, theme }) =>
    disabled ? `color: ${theme.color.disabled}` : ``};
`;

export const ErrorWrapper = styled(FlexBox)`
  width: 80%;
  margin: ${rem(30)} auto;
`;

export const OverflowBox = styled.div`
  overflow: auto;
`;

export const Close = styled.img`
  cursor: pointer;
`;

export const FlexInfoTableWrapper = styled(FlexBoxStart)`
  width: 100%;
  margin: 0;
  align-items: stretch;
  flex-direction: column;
  ${({ theme }) => theme.media.m`
    flex-direction: row;
  `};
`;

export const FlexInfotableSubWrapper = styled(ColumnFlexBox)`
  width: 100%;
  justify-content: flex-start;
  align-items: stretch;
  border-left: ${({ left, theme }) =>
    left ? `solid ${rem(2)} ${theme.separatorColor}` : 'none'};
  border-right: ${({ right, theme }) =>
    right ? `solid ${rem(2)} ${theme.separatorColor}` : 'none'};
`;

export const AnimatedFormMessageWrapper = styled.div`
  overflow: ${({ display }) => (display ? 'unset' : 'hidden')}
  position: ${({ success }) => success && 'sticky'};
  top: ${({ success }) => success && rem(50)};
  z-index: ${({ success }) => success && '2'};
  max-height: ${({ display, maxHeight }) =>
    display ? (maxHeight ? maxHeight : '1000px;') : '0px'};  
  opacity: ${({ display }) => (display ? '1' : '0')}
  transition: opacity 0.5s, max-height 1s;
  width: ${({ width }) => width || 'unset'};

`;

export const ClickableTd = styled(Td)`
  cursor: ${({ clickable }) => (clickable ? 'pointer' : '')};
  color: ${({ clickable, theme }) => (clickable ? theme.color.primary : '')};
  &:hover {
    text-decoration: ${({ clickable }) => (clickable ? 'underline' : 'none')};
  }
  &:last-of-type {
    padding-right: ${rem(15)};
  }
`;

export const WidgetFlexBox = styled(FlexBoxStart)`
  align-items: stretch;
  flex-direction: column;
  ${({ theme }) => theme.media.xl`
  flex-direction: row;
`};
`;

export const GraphTableWrapper = styled(FlexBox)`
  flex-direction: column-reverse;
  ${({ theme }) => theme.media.m`
  flex-direction: row;
  justify-content: space-around;
  align-items: stretch;
  width: 90%;
`};
`;

export const DivRouterLinkWrapper = styled.div`
  font-family: ${({ theme }) => theme.fontFamily};
  font-size: ${({ theme, fontSize }) => fontSize || theme.fontSize || '14px'};
  list-style: none;
  a {
    text-decoration: none;
    margin: ${({ noMargin }) => (noMargin ? '0' : `0 ${rem(10)}`)};
    padding: 0;
    display: block;
    color: ${({ theme, color }) => color || theme.color.primary};
    cursor: pointer;
    white-space: nowrap;
  }
  a:visited {
    color: ${({ theme, color }) => color || theme.color.primary};
  }
  a:hover {
    text-decoration: underline;
  }
`;
export const ControlButtonItem = styled.li`
  text-decoration: none;
  margin: 0 ${rem(10)};
  padding: ${rem(5)} 0;
  display: block;
  color: ${({ theme }) => theme.color.primary};
  cursor: pointer;
  white-space: nowrap;
`;

export const IFrame = styled.iframe`
  border: none;
  outline: none;
  width: 100%;
  height: ${rem(60)};
`;

export const DHeader = styled.div`
  outline: none;
  color: ${({ sorter, theme }) => sorter && theme.color.primary};
  font-size: ${rem(12)};
  align-items: center;
  display: flex;
  justify-content: start;
`;

export const P = styled.p`
  margin: 0;
  padding: 0 ${rem(10)};
  min-height: ${rem(32)}
  font-size: ${rem(12)};
  align-items: center;
  display: flex;
  word-break: break-all;
  white-space: normal;
 `;

export const ReportStepHeader = styled.div`
  background: #f9f9f9;
  height: ${rem(35)};
  display: flex;
  align-items: center;
  padding-left: ${rem(20)};
  color: #565555;
  font-size: ${rem(12)};
  justify-content: space-between;
`;

export const StyledTitle = styled.h2`
  padding: ${rem(20)} ${rem(30)};
  background-color: #f9f9f9;
  color: #555555;
  font-size: ${rem(14)};
  display: flex;
  justify-content: space-between;
  @media print {
    font-size: ${rem(18)};
  }
`;

export const TextPreview = styled.pre`
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  margin-left: ${rem(30)};
`;

export const ChartLegendColor = styled.div`
  height: ${rem(20)};
  width: ${rem(20)};
  border-radius: ${rem(4)};
  margin-right: ${rem(10)};
`;

export const SpaceWrapper = styled.span`
  margin-left: ${({ left, value }) => left && rem(value || 10)}};
  margin-right: ${({ right, value }) => right && rem(value || 10)}};
  margin-top: ${({ top, value }) => top && rem(value || 10)}};
  margin-bottom: ${({ bottom, value }) => bottom && rem(value || 10)}};
`;

export const Bold = styled.span`
  font-weight: ${({ weight }) => weight || 'bold'};
`;

export const PositionContent = styled.div`
  text-align: ${({ at }) => at || 'center'};
`;

export const LinkDiv = styled.div`
  cursor: pointer;
  color: ${({ theme, color }) => color || theme.color.primary};
  :hover {
    text-decoration: underline;
  }
`;

export const TestLabel = styled.div`
  position: absolute;
  left: -4px;
  top: -13px;
  background: red;
  padding: 3px;
  color: white;
  font-size: 12px;
  border-radius: 10px;
`;

export const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  position: relative;
`;
