import React from 'react';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import tableHeaders from '../../ContentConfig/vouchersTable';
import {
  __,
  formatPrice,
  FORMAT_HUMANE_DATE,
  formatDate,
  getFirmPrice,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import moment from 'moment';
import API2 from '../../API2';

class VouchersTable extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'name' });
    this.headers = tableHeaders;
    this.segmentName = __('Zlavové kody');
    this.accessors = ['name', 'used_count', 'total_price_vat', 'used'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      showEditProduct: null,
      filters: [
        {
          name: 'voucherCodes',
          label: __('Zľavový kód'),
          value: '',
          type: 'search-select',
          onSearch: q => this.searchVouchers(q, this.props.firm),
        },
        {
          name: 'fromDate',
          label: __('Dátum objednávky od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum objednávky do'),
          value: '',
          type: 'date',
        },
      ],
      limit: 100,
      offset: 0,
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { filters, limit, offset } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, limit, offset);

      const newData = await API2.getEshopUserVouchersStatsAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        id: _shapeData(item.voucher_code),
        name: _shapeData(item.voucher_code),
        used_count: _shapeData(item.used_count),
        total_price_vat: _shapeData(
          formatPrice(getFirmPrice(this.props.firm, item.total_price_vat)),
        ),
        used: _shapeData(
          formatDate(
            moment(`${item.used.year}-${item.used.month}-${item.used.day}`),
            FORMAT_HUMANE_DATE,
          ),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  searchVouchers = async (query, firm = 'SK') => {
    try {
      return API2.getEshopUsedVouchersAction(firm, { q: query }).then(res => {
        return {
          options: res.map(item => ({
            value: item.voucher_code,
            label: item.voucher_code,
            raw: item,
          })),
        };
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Štatistiky')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(VouchersTable);
