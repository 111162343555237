import React from 'react';
import PropTypes from 'prop-types';
import { connect } from '../../Store';
import VouchersTable from '../VouchersTable';

class Stats extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  renderControlBar() {
    return null;
  }

  render() {
    return (
      <React.Fragment>
        {this.renderControlBar()}

        <VouchersTable>
        </VouchersTable>
      </React.Fragment>
    );
  }
}

Stats.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Stats);
