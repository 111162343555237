import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Checkbox } from 'oxyrion-ui/lib';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
} from '../../Utils';
import {
  searchContacts,
  searchCreatedByTypes,
} from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/messages';
// import orderStates from '../../ContentConfig/order_states';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

class Messages extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'order_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Správy z eshopu');
    this.accessors = [
      'id',
      'customer',
      'text',
      'customer_notified_by_email',
      'customer_notified_by_message',
      'created_date',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('order_date');
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'orderNo',
          label: __('Číslo objednávky'),
          value: '',
          type: 'input',
        },
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'createdBy',
          label: __('Vytvoril'),
          value: '',
          type: 'search-select',
          onSearch: q => searchCreatedByTypes(q, this.props.firm),
        },
      ],
    };
  }
  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearch, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const newData = await API2.getEshopMessagesAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(renderLink(`./orders/${item.order_id}`, item.order_id)),
        customer_notified_by_email: _shapeData(
          <Checkbox disabled checked={item.customer_notified_by_email} />,
        ),
        customer_notified_by_message: _shapeData(
          <Checkbox disabled checked={item.customer_notified_by_message} />,
        ),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        text: _shapeData(item.text),
        customer: _shapeData(
          item.contact && item.contact._id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/contacts/${item.contact._id}`}
            >
              {item.contact.email}
            </StyledA>
          ) : (
            item.user && item.user.display_name
          ),
        ),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Objednávky')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Messages);
