import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Label from 'oxyrion-ui/lib/Label/Label';
import Input from 'oxyrion-ui/lib/Input';
import Button from 'oxyrion-ui/lib/Button';
import { __ } from '../../Utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${rem(24)};
  width: ${rem(300)};
`;

class AddressEdit extends Component {
  state = {
    data: undefined,
  };

  handleChange(field, value) {
    let { data } = this.state;

    if (!data) {
      data = Object.assign({}, this.props.data || {});
    }
    data[field] = value;
    this.setState({ data });
  }

  render() {
    const { data: propsData, onDismiss, onConfirm } = this.props;
    const { data: stateData } = this.state;

    const data = stateData || propsData;

    return (
      <Wrapper>
        <InputWrapper>
          <Label>{__('Meno a priezvisko')}</Label>
          <Input
            onChange={e => this.handleChange('contact_name', e.target.value)}
            placeholder={__('Meno a priezvisko')}
            value={data.contact_name}
            disabled
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Ulica')}</Label>
          <Input
            onChange={e => this.handleChange('street', e.target.value)}
            placeholder={__('Ulica')}
            value={data.street}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('PSČ')}</Label>
          <Input
            onChange={e => this.handleChange('postal_code', e.target.value)}
            placeholder={__('PSČ')}
            value={data.postal_code}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Mesto')}</Label>
          <Input
            onChange={e => this.handleChange('city', e.target.value)}
            placeholder={__('Mesto')}
            value={data.city}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Krajina')}</Label>
          <Input
            onChange={e => this.handleChange('country', e.target.value)}
            placeholder={__('Krajina')}
            value={data.country}
            disabled
          />
        </InputWrapper>

        <Buttons>
          <Button danger onClick={() => onDismiss()}>
            {__('Zrušiť')}
          </Button>
          <Button
            loading={this.props.saveLoading}
            onClick={() => onConfirm(data)}
            primary
          >
            {__('Uložiť')}
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

AddressEdit.propTypes = {
  data: PropTypes.shape({}),
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

AddressEdit.defaultProps = {
  data: {},
};

export default AddressEdit;
