import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect, Switch } from 'react-router-dom';
import NavBar from '../Containers/NavBar';
import navbarContent from '../ContentConfig/navbar';
import StoreSynchorizer from './StoreSynchorizer';
import History from '../Containers/History';
// import FileViewer from '../Containers/PDFFileViewer';
import OrdersCard from './OrdersCard';
import MessagesCard from './MessagesCard';
import WarrantyClaimsCard from './WarrantyClaimsCard';
import Courier123Card from './Courier123Card';
import Dashboard from '../Containers/Dashboard';
import Stats from '../Containers/Stats';
import QuestionsCard from './QuestionsCard';
import ReviewsCard from './ReviewsCard';
import OrdersRatingsCard from './OrderRatingsCard';
import BillsCard from './BillsCard';
import EshopSales from '../Containers/EshopSales';
import StoreSales from '../Containers/StoreSales';
import DailyReportCard from './DailyReportCard';
import CampaingsCard from './CampaingsCard';
import PacketaCard from './PacketaCard';
import DepositWithdrawalsCard from './DepositWithdrawalsCard';
import DailyOverviewCard from './DailyOverviewCard';
import CashRegistersOrdersCard from './CashRegistersOrdersCard';
import ContactsRegisterCard from './ContactsRegisterCard';
import CashRegistersRegisters from './ContactsRegisterCard';
import WarehouseCard from './WarehouseCard';
import InvoicesPaymentsCard from './InvoicesPaymentsCard';

class Router extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      mounted: false,
    };
  }
  componentDidMount() {
    this.setState({ mounted: true });
  }

  componentDidUpdate(newProps) {
    const { mounted, redirect } = this.state;
    if (newProps.firm !== this.props.firm && !mounted) {
      this.setState({ redirect: true });
    } else if (redirect === true) {
      this.setState({ redirect: false });
    } else if (mounted) {
      this.setState({ mounted: false });
    }
  }
  render() {
    const { year, firm, user, settings, userHistory, appLang } = this.props;

    const defaultRedirect = settings.startPageEOM
      ? `/${firm}${settings.startPageEOM}`
      : `/${firm}/home`;

    return (
      <Switch>
        {this.state.redirect ? <Redirect to={defaultRedirect} /> : ''}
        <Route
          path="/:firm"
          render={({ match, history }) => {
            return match.params.firm === 'SK' || match.params.firm === 'CZ' ? (
              <StoreSynchorizer firm={match.params.firm} year={year}>
                <NavBar
                  content={navbarContent}
                  user={user}
                  settings={settings}
                  firm={match.params.firm}
                  match={match}
                  history={userHistory}
                  year={year}
                  appLang={appLang}
                />
                <Route path={`${match.url}/orders`} component={OrdersCard} />
                <Route
                  path={`${match.url}/messages`}
                  component={MessagesCard}
                />
                <Route
                  path={`${match.url}/order-ratings`}
                  component={OrdersRatingsCard}
                />
                <Route
                  path={`${match.url}/warranty-claims`}
                  component={WarrantyClaimsCard}
                />
                <Route
                  path={`${match.url}/123-courier`}
                  component={Courier123Card}
                />

                <Route path={`${match.url}/packeta`} component={PacketaCard} />

                <Route
                  path={`${match.url}/warehouse`}
                  component={WarehouseCard}
                />
                <Route
                  path={`${match.url}/questions`}
                  component={QuestionsCard}
                />
                <Route path={`${match.url}/reviews`} component={ReviewsCard} />

                <Route path={`${match.url}/home`} component={Dashboard} />
                <Route path={`${match.url}/stats`} component={Stats} />
                <Route
                  path={`${match.url}/eshop-sales`}
                  component={EshopSales}
                />
                <Route
                  path={`${match.url}/store-sales`}
                  component={StoreSales}
                />

                <Route path={`${match.url}/bills`} component={BillsCard} />
                <Route
                  path={`${match.url}/daily-report`}
                  component={DailyReportCard}
                />
                <Route
                  path={`${match.url}/campaigns`}
                  component={CampaingsCard}
                />
                <Route
                  path={`${match.url}/contact-registers`}
                  component={CashRegistersRegisters}
                />
                <Route
                  path={`${match.url}/cash-register-orders`}
                  component={CashRegistersOrdersCard}
                />
                <Route
                  path={`${match.url}/deposit-withdrawals`}
                  component={DepositWithdrawalsCard}
                />

                <Route
                  path={`${match.url}/contacts-register`}
                  component={ContactsRegisterCard}
                />

                <Route
                  path={`${match.url}/daily-overview`}
                  component={DailyOverviewCard}
                />

                <Route
                  path={`${match.url}/history`}
                  render={() => <History history={history} />}
                />
                {/* <Route
                  path={`${match.url}/fileviewer`}
                  render={({ location }) => (
                    <FileViewer location={location} history={history} />
                  )}
                /> */}

                <Route
                  path={`${match.url}/invoices-payments`}
                  component={InvoicesPaymentsCard}
                />
              </StoreSynchorizer>
            ) : (
              <Redirect to={defaultRedirect} />
            );
          }}
        />
        <Route path="" render={() => <Redirect to={defaultRedirect} />} />
      </Switch>
    );
  }
}

Router.propTypes = {
  year: PropTypes.number.isRequired,
  firm: PropTypes.string.isRequired,
  userHistory: PropTypes.shape({}).isRequired,
  appLang: PropTypes.string.isRequired,
  user: PropTypes.shape({
    display_name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    forename: PropTypes.string.isRequired,
    mobile: PropTypes.string,
    role: PropTypes.string.isRequired,
    surname: PropTypes.string.isRequired,
    _id: PropTypes.string.isRequired,
  }).isRequired,
  settings: PropTypes.shape({}).isRequired,
};

export default Router;
