import {  __ } from '../Utils';

export default [
  {
    name: __('ID'),
    clickable: false,
    otherProps: {
      maxWidth: 120,
    },
  },
  {
    name: __('Admin'),
    clickable: false,
    otherProps: {
      maxWidth: 45,
    },
  },
  {
    name: __('Eshop'),
    clickable: false,
    otherProps: {
      maxWidth: 45,
    },
  },
  { name: __('Produkt'), clickable: false },
  {
    name: __('Produktový balíček'),
    clickable: false,
    otherProps: {
      maxWidth: 130,
    },
  },
  {
    name: __('Jednotková cena bez DPH'),
    clickable: false,
    otherProps: {
      maxWidth: 150,
    },
  },
  {
    name: __('Počet kusov'),
    clickable: false,
    otherProps: {
      maxWidth: 90,
    },
  },
  {
    name: __('Cena spolu bez DPH'),
    clickable: false,
    otherProps: {
      maxWidth: 125,
    },
  },
  {
    name: __('Zľava (%)'),
    clickable: false,
    otherProps: {
      maxWidth: 80,
    },
  },
  {
    name: __('Zľava ({currency})'),
    clickable: false,
    otherProps: {
      maxWidth: 80,
    },
  },
  {
    name: __('Výsledná cena bez DPH'),
    clickable: false,
    otherProps: {
      minWidth: 145,
      maxWidth: 145,
    },
  },
  {
    name: __('DPH (%)'),
    clickable: false,
    otherProps: {
      maxWidth: 75,
    },
  },
  {
    name: __('DPH ({currency})'),
    clickable: false,
    otherProps: {
      maxWidth: 75,
    },
  },
  {
    name: __('Výsledná cena s DPH'),
    clickable: false,
    otherProps: {
      minWidth: 145,
      maxWidth: 145,
    },
  },
  {
    name: __(''),
    clickable: false,
    otherProps: {
      maxWidth: 80,
    },
  },
];
