import React, { PureComponent } from 'react';
import Theme from 'oxyrion-ui/lib/Themes/themeEOM';
import {
  XAxis,
  YAxis,
  Tooltip,
  Area,
  ResponsiveContainer,
  Label,
  ComposedChart,
  Line,
  Legend,
} from 'recharts';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatPrice, __ } from '../../Utils';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';

export default class LineComposedChartComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isWeatherOn: true,
    };
  }

  formatXAxis(amount, appLang) {
    return formatPrice({
      amount,
      currency: appLang === 'SK' ? 'EUR' : 'CZK',
    });
  }

  render() {
    const {
      data,
      title,
      subTitle,
      appLang,
      responsive,
      isStoreSelected,
    } = this.props;
    const { isWeatherOn } = this.state;

    const CustomToolTip = props => {
      const { active, payload } = props;
      if (!active || !payload) {
        return null;
      }

      const tooltipItems = payload.map(item => {
        const { name, dataKey } = item;

        if (dataKey === 'amount') {
          return (
            <p key={`amount-${name}`}>
              {`${name}: `}
              <strong>
                {formatPrice({
                  amount: item.payload.amount,
                  currency: appLang === 'SK' ? 'EUR' : 'CZK',
                })}
              </strong>
            </p>
          );
        } else if (dataKey === 'avgTemp') {
          return (
            <p key={`avgTemp-${name}`}>
              {`${name}: `}
              <strong>{`${item.payload.avgTemp} °C`}</strong>
            </p>
          );
        } else if (dataKey === 'avgPrcp') {
          return (
            <p key={`avgPrcp-${name}`}>
              {`${name}: `}
              <strong>{`${Number(item.payload.avgPrcp)} mm`}</strong>
            </p>
          );
        }

        return null;
      });

      return <div className="custom-tooltip">{tooltipItems}</div>;
    };

    return (
      <Wrapper>
        <div className="title">{title}</div>
        <div className="sub_title">{subTitle}</div>
        {isStoreSelected && (
          <CheckboxWrapper>
            <LabeledCheckbox
              checked={isWeatherOn}
              disabled={!isStoreSelected}
              labelText={__('Počasie')}
              onChange={e =>
                this.setState({
                  isWeatherOn: e.target.checked,
                })
              }
            />
          </CheckboxWrapper>
        )}

        <ResponsiveContainer
          height={350}
          minWidth={responsive ? undefined : 1500}
          // style={{
          //   minWidth: rem(1600),
          //   overflow: 'auto',
          // }}
        >
          <ComposedChart
            data={data}
            margin={{
              top: 5,
              right: 35,
              left: 35,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop
                  offset="5%"
                  stopColor={Theme.color.primary}
                  stopOpacity={0.8}
                />
                <stop
                  offset="95%"
                  stopColor={Theme.color.primary}
                  stopOpacity={0}
                />
              </linearGradient>
            </defs>
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis
              dataKey="name"
              scale="point"
              padding={{ left: 10, right: 10 }}
            />
            <YAxis unit={appLang === 'SK' ? `\u00a0€` : `\u00a0Kč`} />
            <Tooltip content={<CustomToolTip />} />
            <Legend />
            <Area
              type="linear"
              dataKey="amount"
              stroke={Theme.color.primary}
              fillOpacity={1}
              fill="url(#colorUv)"
              name={__('Celkový nákup')}
            >
              <Label dataKey="value" position="center" />
            </Area>
            {isStoreSelected && isWeatherOn && (
              <Line
                type="linear"
                dataKey="avgTemp"
                stroke="#e3623b"
                yAxisId="right"
                name={__('Priemerná teplota')}
              />
            )}
            {isStoreSelected && isWeatherOn && (
              <Line
                type="linear"
                dataKey="avgPrcp"
                stroke="#0a88c7"
                yAxisId="right"
                name={__('Zrážky')}
              />
            )}
            {isStoreSelected && isWeatherOn && (
              <YAxis yAxisId="right" orientation="right" />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  position: relative;
  background: white;
  padding: ${rem(16)};
  border-radius: ${rem(8)};
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: ${rem(22)};
    font-weight: bold;
    color: black;
    text-align: center;
    margin-bottom: ${rem(8)};
  }
  .sub_title {
    font-size: ${rem(16)};
    font-weight: bold;
    color: #7c828a;
    text-align: center;
    margin-bottom: ${rem(16)};
  }

  .test {
    background-color: rebeccapurple;
  }
`;

const CheckboxWrapper = styled.div`
  position: absolute;
  top: ${rem(24)};
  left: ${rem(50)};
`;

LineComposedChartComponent.propTypes = {
  appLang: PropTypes.string,
  responsive: PropTypes.bool,
  isStoreSelected: PropTypes.bool,
};

LineComposedChartComponent.defaultProps = {
  appLang: 'SK',
  responsive: true,
  isStoreSelected: true,
};
