import { __ } from '../Utils';

export default [
  {
    name: __('Názov produktu'),
    clickable: false,
  },
  {
    name: __('Značka'),
    clickable: false,
  },
  {
    name: __('Počet predaných kusov'),
    clickable: false,
  },
];
