import { __e as __ } from '../Utils';

export default [
  {
    name: __('Názov'),
    clickable: true,
    sorterValue: 'Name',
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Počet kusov'),
    clickable: false,
  },
  {
    name: __('EAN'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Značka'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
];
