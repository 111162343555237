export default [
  {
    name: 'Nedoručená',
    value: 13,
  },
  {
    name: 'Doručená',
    value: 16,
    end_state: true,
  },
  {
    value: 17,
    name: 'Čaká na zvoz',
  },
  {
    name: 'Zásielka vyzdvihnutá',
    value: 18,
  },
  {
    value: 19,
    name: 'Pripravená na zvoz',
  },
  {
    name: 'Príjem na sklad',
    value: 20,
  },
  {
    value: 22,
    name: 'Zásielka v rozvoze',
  },
  {
    value: 25,
    name: 'Stornovaná',
    end_state: true,
  },
  {
    value: 27,
    name: 'Doručenie odosielateľovi',
    end_state: true,
  },
  {
    value: 28,
    name: 'Zásielka v exporte',
    end_state: true,
  },
];
