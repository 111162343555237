import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import PacketaPackages from '../../Containers/PacketaPackages';

function PacketaCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}`} component={PacketaPackages} />
    </Switch>
  );
}

PacketaCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default PacketaCard;
