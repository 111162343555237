import React from 'react';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import tableHeaders from '../../ContentConfig/dailyOverviewList';
import { __ } from '../../Utils';

class CashRegisterProductsStats extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-createdDate' });
    this.headers = tableHeaders;
    this.segmentName = __('');
    this.accessors = ['_id', 'name', 'quantity'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      showFilter: false,
      showPager: false,
      activeMessage: 'Všetky',
      sorter: '-createdDate',
      filters: [],
    };
  }

  componentDidUpdate(props) {
    if (
      this.state.content.length === 0 &&
      props.data &&
      props.data.length > 0
    ) {
      const content = this.normalizeColumns(this.props.data);
      this.setState({
        content,
      });
    }
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: _shapeData(item.nav_id),
        name: _shapeData(item.name),
        quantity: _shapeData(item.quantity),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    return <div />;
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisterProductsStats);
