import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'oxyrion-ui/lib/Checkbox';
import styled from 'styled-components';

const CustomCheckbox = styled(Checkbox)`
  position: unset;

  input {
    display: none;
  }

  span {
    position: unset;
  }
`;

class OwnStateCheckbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked || props.defaultChecked,
    };
  }

  shouldComponentUpdate() {
    return true;
  }

  componentDidUpdate(oldProps) {
    if (oldProps.checked !== this.props.checked) {
      this.setState({ checked: this.props.checked });
    }
  }

  handleOnChange(value) {
    const self = this;

    if (self.state.typingTimeout) {
      clearTimeout(self.state.typingTimeout);
    }

    self.setState({
      checked: value,
      typing: false,
      typingTimeout: setTimeout(() => {
        this.props.onChange(value);
      }, this.props.delay),
    });
  }

  render() {
    const { checked } = this.state;
    return (
      <CustomCheckbox
        {...this.props}
        checked={checked}
        onChange={e => {
          this.handleOnChange(e.target.checked);
        }}
      />
    );
  }
}

OwnStateCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
  defaultChecked: PropTypes.bool,
  delay: PropTypes.number,
};

OwnStateCheckbox.defaultProps = {
  checked: false,
  defaultChecked: false,
  delay: 300,
};

export default OwnStateCheckbox;
