import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { FlexBoxStart } from '../ReusableComponents';
import SearchableSelect from '../SearchableSelect';

const CustomFlexBox = styled(FlexBoxStart)`
  margin-right: 10px;
  align-items: center;
  width: ${rem(200)};
`;

const CustomSearchableSelect = styled(SearchableSelect)`
  min-width: 200px;
`;

class ControlbarSearchSelect extends React.Component {
  render() {
    const { fetchData, onChange, searchLabel, value } = this.props;
    return (
      <CustomFlexBox>
        <CustomSearchableSelect
          style={{ width: '100%' }}
          value={value}
          loadOptions={query => fetchData(query)}
          placeholder={searchLabel}
          name={value}
          minWidth={rem(200)}
          small
          handleOnChange={e => onChange(e)}
          filterOptions
        />
      </CustomFlexBox>
    );
  }
}

ControlbarSearchSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  value: PropTypes.shape,
  searchLabel: PropTypes.string,
  defaultValue: PropTypes.string,
};

ControlbarSearchSelect.defaultValue = {
  defaultValue: '',
  searchLabel: '',
  value: null,
};

export default ControlbarSearchSelect;
