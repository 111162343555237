import React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import Message from 'oxyrion-ui/lib/Message';
import Label from 'oxyrion-ui/lib/Label/Label';
import { PageNavigator, Button, Icon, Input } from 'oxyrion-ui/lib';
import Select from 'oxyrion-ui/lib/Select';
import TableV2 from '../../Components/TableV2';
import {
  FlexBox,
  RouteLinkWraper as LinkWrap,
  AnimatedFormMessageWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';
import PageInfo from '../../Components/PageInfo';
import ControlButton from '../../Components/ReusableComponents/ControlButton';
import {
  handlePriceForTable as _handlePrice,
  __,
  __e,
  renderLink,
  formatPaymentTerms,
  buildQuery,
} from '../../Utils';
import ControllBar from '../../Components/ControllBar';
import CheckAccess from '../../ACL/CheckAccess';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import sendRecents from '../../Utils/sendRecents';
import IconTd from '../../Components/IconTd';
import OwnStateCheckbox from '../../Components/OwnStateCheckbox';
import ExpandedError from '../../Components/ExpandedError';
import SearchableSelect from '../../Components/SearchableSelect';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import moment from 'moment';

const FiltersMainWrapper = styled.div`
  padding: ${rem(16)};

  .button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: ${rem(32)};
  }

  .space {
    width: ${rem(12)};
    height: ${rem(12)};
  }
`;

const FiltersWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 350px));
  grid-column-gap: 3px;
  grid-row-gap: 3px;

  .input-wrapper {
    width: ${rem(300)};
  }

  .select-wrapper {
    width: ${rem(324)};
  }

  .search-select-wrapper {
    width: ${rem(324)};
    z-index: 100000;
  }

  .filterItem {
    z-index: 4;
  }
`;

const DHeader = styled.div`
  outline: none;
  color: ${({ sorter, theme }) => sorter && theme.color.primary};
  font-size: ${rem(12)};
  align-items: center;
  display: flex;
  justify-content: start;
`;

const P = styled.p`
  margin: 0;
  color: ${props => props.closed && '#a9a9a9'};
  padding: 0 ${rem(10)};
  min-height: ${rem(50)};
  font-size: ${rem(12)};
  align-items: center;
  display: flex;
  justify-content: start;
`;

const PageWrapper = styled(FlexBox)`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 2px 2px ${({ theme }) => theme.separatorColor};
  justify-content: space-between;
  padding: ${rem(10)} ${rem(20)};
  min-height: ${rem(15)};
  max-width: auto;
  margin: auto;
  font-size: ${({ theme }) => theme.fontSize || rem(11)};
`;

const TableWrapper = styled.div`
  margin: ${({ small }) => (small ? 'unset' : 'auto')};
  min-height: ${({ small }) => (small ? 'unset' : '70vh')};
  width: 100%;
  margin-bottom: ${({ showPager }) => (showPager ? rem(55) : 0)};
`;

const BulkWrapper = styled.div`
  max-width: ${({ display }) => (display ? rem(200) : '0')};
  opacity: ${({ display }) => (display ? '1' : '0')};
  display: ${({ display }) => (display ? 'block' : 'none')};
  transition: max-width 0.5s, opacity 0.5s;
`;

const NavisionpPager = styled.div`
  display: flex;
`;

const ArrowWrapper = styled.div`
  width: ${rem(30)};
  height: ${rem(30)};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const NumberWrapper = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

class BaseTable extends React.Component {
  constructor(props) {
    super(props);
    this.segmentName = __('');
    this.showBulkOperations = true;
    this.fixedHeader = false;
    this.defaultSorter = '';
    this.filters = [];
    this.headers = [];
    this.setSorter(this.defaultSorter);
    this.accessors = [];
    this.state = {
      content: [],
      offset: 0,

      limit: 100,
      showPagerForNavisionServices: false,
      total: null,
      active: true,
      checkedRows: [],
      allRoswChecked: false,
      activateError: null,
      loadResults: {},
      error: null,
      lastSearchValue: '',
      activeNavisionPage: 1,
      lastOpenedId: '',
      defaultScrollPosition: 0,
    };
  }

  componentDidMount() {
    const offset = this.getLocalStorage('offset', this.segmentName);
    const nextOffset = this.getLocalStorage('nextOffset', this.segmentName);
    const total = this.getLocalStorage('total', this.segmentName);
    const lastSearchValue = this.getLocalStorage(
      'lastSearchValue',
      this.segmentName,
    );
    const lastFilters = this.getLocalStorage('filters', this.segmentName);

    const lastOpenedId = this.getLocalStorage('lastOpenedId', this.segmentName);

    const defaultScrollPosition = this.getLocalStorage(
      'defaultScrollPosition',
      this.segmentName,
    );

    this.setLocalStorage('defaultScrollPosition', 0, this.segmentName);
    this.setLocalStorage('lastOpenedId', '', this.segmentName);
    this.setLocalStorage('offset', 0, this.segmentName);
    this.setLocalStorage('nextOffset', null, this.segmentName);
    this.setLocalStorage('total', null, this.segmentName);
    this.setLocalStorage('filters', null, this.segmentName);

    const searchValue =
      lastSearchValue &&
      lastSearchValue !== 'null' &&
      lastSearchValue !== 'undefined' &&
      lastSearchValue
        ? lastSearchValue
        : '';

    const lastFilter = this.getLocalStorage('selectedFilter', this.segmentName);

    let parsedFilter = null;

    if (
      lastFilter &&
      lastFilter !== 'undefined' &&
      lastFilter !== null &&
      lastFilter !== 'null' &&
      lastFilter.length
    ) {
      parsedFilter = JSON.parse(lastFilter);
      this.setLocalStorage('selectedFilter', null, this.segmentName);
    }

    let parsedFilters = null;

    let { showFilter } = this.state;

    if (
      lastFilters &&
      lastFilters !== 'undefined' &&
      lastFilters !== null &&
      lastFilters !== 'null' &&
      lastFilters.length
    ) {
      parsedFilters = JSON.parse(lastFilters);
      // showFilter = true;
    }
    this.setLocalStorage('lastSearchValue', '', this.segmentName);

    this.setState(
      {
        selectedFilter: parsedFilter || this.state.selectedFilter,
        offset: Number(offset || 0) || 0,
        nextOffset: Number(nextOffset || 0) || 0,
        total: Number(total || 0),
        lastSearchValue: searchValue,
        defaultScrollPosition,
        lastOpenedId,
        showFilter,
        filters: parsedFilters || this.state.filters,
      },
      async () => {
        this.fetchData({
          q: searchValue,
          nextOffset,
          offset: Number(offset || 0) || 0,
          total,
        });
        sendRecents();
      },
    );
  }

  async componentDidUpdate(newProps) {
    if (newProps.loading !== this.props.loading) {
      this.setState({
        loading: !newProps.loading,
      });
    }
    if (newProps.firm !== this.props.firm) {
      await this.fetchData();
    }
  }

  componentWillUnmount() {
    const { offset, nextOffset, total, lastSearchValue, filters } = this.state;
    this.setLocalStorage('offset', offset, this.segmentName);
    this.setLocalStorage('nextOffset', nextOffset, this.segmentName);
    this.setLocalStorage('total', total, this.segmentName);
    this.setLocalStorage('lastSearchValue', lastSearchValue, this.segmentName);
    this.setLocalStorage('filters', JSON.stringify(filters), this.segmentName);
  }

  getMainContact(contacts) {
    const contact = contacts.reduce((acum, item) => {
      if (item.is_default) {
        return item;
      }
      return acum;
    }, {});
    return contact;
  }

  setSorter(sorter) {
    if (sorter !== null) {
      const { history } = this.props;
      if (sorter && history && history.length && history.push) {
        history.push({
          search: buildQuery({ sorter }),
        });
      }
    }
  }

  setConfig(entityName, crmSettings) {
    this.config = crmSettings[entityName];

    if (this.config) {
      this.headers = this.config.table || [];
      this.filters = this.config.filters || [];
    } else {
      this.headers = [];
      this.filters = [];
    }
  }

  setLocalStorage(name, value, constructorName) {
    localStorage.setItem(constructorName + name, value);
  }

  getLocalStorage(name, constructorName) {
    return localStorage.getItem(constructorName + name);
  }

  saveFiltersToLocalStorage(selectedFilter) {
    const { lastSearch } = this.state;

    if (selectedFilter) {
      this.setLocalStorage(
        'lastSearch',
        lastSearch !== null && lastSearch !== undefined ? lastSearch : '',
        this.segmentName,
      );

      this.setLocalStorage(
        'selectedFilter',
        JSON.stringify(selectedFilter),
        this.segmentName,
      );
    }
  }

  saveScrollPositions(value, rowInfo) {
    this.setLocalStorage('defaultScrollPosition', value, this.segmentName);
    this.setLocalStorage(
      'lastOpenedId',
      rowInfo.original._id,
      this.segmentName,
    );
  }

  buildParamsByFilter() {
    return {};
  }

  onPageClick(page) {
    const { limit } = this.state;
    this.fetchData({
      offset: (page - 1) * limit,
    });
  }
  onIncrease() {
    const { nextOffset } = this.state;
    this.fetchData({
      offset: nextOffset,
    });
  }
  onDecrease() {
    const { limit, offset } = this.state;
    this.fetchData({
      offset: offset - limit,
    });
  }

  navisionOnDecrease() {
    const { limit, activeNavisionPage, keyMap } = this.state;

    if (activeNavisionPage > 1) {
      this.fetchData(
        {
          lastKey:
            activeNavisionPage - 2 >= 0 &&
            keyMap[activeNavisionPage - 2] &&
            keyMap[activeNavisionPage - 2].firstKey,
          limit: -limit,
        },
        false,
      );
    }

    this.setState({
      activeNavisionPage: activeNavisionPage - 1,
    });
  }

  handleFilterChange(field, value) {
    const { selectedFilter } = this.state;
    if (selectedFilter.find(s => s.filterValue === field)) {
      selectedFilter.find(s => s.filterValue === field).selected = value;
    }

    this.setState(
      {
        selectedFilter,
      },
      () => this.fetchData({}),
    );
  }

  navisionOnIncrease() {
    const { lastKey, activeNavisionPage } = this.state;

    this.fetchData({
      lastKey,
    });

    this.setState({
      activeNavisionPage: activeNavisionPage + 1,
    });
  }

  fetchUsers(query) {
    try {
      return API2.getAllUsersAction({
        q: query,
        limit: 10,
      }).then(res => {
        return {
          options: res.users.map(item => ({
            value: item._id,
            label: item.display_name,
          })),
        };
      });
    } catch (e) {
      return [];
    }
  }

  async sortBy(sorterType, ascending) {
    const sign = ascending ? '' : '-';
    const { lastSearchOwner, lastSearchValue } = this.state;
    this.setState({ sorter: `${sign}${sorterType}` }, () => {
      this.fetchData({
        owner: lastSearchOwner,
        q: lastSearchValue,
      });
    });
  }

  async sortByV2(sorterValue) {
    const { sorter } = this.state;

    let newSorter = Object.assign({}, sorter);

    if (sorterValue) {
      if (sorter === sorterValue) {
        newSorter = `-${sorterValue}`;
      } else {
        newSorter = sorterValue;
      }
      this.setSorter(newSorter);
      this.setState({ sorter: newSorter }, () =>
        this.fetchData({
          q: this.state.lastSearchValue,
          owner: this.state.lastSearchOwner,
        }),
      );
    }
  }

  async fetchData() {}

  createColumns(sorter) {
    const { allRowsChecked, checkedRows } = this.state;

    let columns = this.headers.map((header, index) => {
      if (header.clickable) {
        return {
          accessor: this.accessors[index],
          sortable: true,
          sorterValue: header.sorterValue,
          Header: () => (
            <IconTd
              sorter={
                sorter === header.sorterValue ||
                sorter === `-${header.sorterValue}`
                  ? sorter
                  : null
              }
            >
              {header.name}
            </IconTd>
          ),
          Cell: props => {
            return (
              <P closed={props.original.closed}>
                {props && props.value && props.value.value && props.value.value}
              </P>
            );
          },
          ...header.otherProps,
        };
      }
      return {
        accessor: this.accessors[index],
        Header: () => <DHeader>{header.name}</DHeader>,
        Cell: props => {
          return (
            <P closed={props.original.closed}>
              {props && props.value && props.value.value && props.value.value}
            </P>
          );
        },
        ...header.otherProps,
      };
    });

    if (this.showBulkOperations) {
      const checkBoxesColumn = {
        Header: () => (
          // <OwnStateCheckbox
          //   checked={allRowsChecked}
          //   onChange={() => this.handleAllRowsOnChange()}
          // />
          <div />
        ),
        fixed: 'right',
        Cell: props => {
          return (
            <P closed={props.original.closed}>
              <OwnStateCheckbox
                checked={
                  allRowsChecked || checkedRows.indexOf(props.original.id) > -1
                }
                onChange={() => this.handleRowOnChange(props.original.id)}
              />
            </P>
          );
        },
        maxWidth: 55,
      };

      columns = [...columns, checkBoxesColumn];
    }

    return columns;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null) => {
      return {
        value: value || '-',
        handleOnClick: handleOnClick || null,
      };
    };

    const _shapeOwnership = (arr, handleOnCLick = null) => {
      const r = {
        value: '',
        handleOnClick: handleOnCLick || null,
      };
      let value = ['-'];
      if (arr && arr.length) {
        value = arr.map(e => {
          return e.display_name;
        });
      }
      return Object.assign(r, { value: value.join(',') });
    };

    // const { firm } = this.props;

    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        name: _shapeData(renderLink(`./customers/${item._id}`, item.name)),
        city: _shapeData(item.billing_address.city),
        turnover_plan: _shapeData(_handlePrice(item.plan)),
        cummulative: _shapeData(_handlePrice(item.cummulative)),
        turnover_actual: _shapeData(_handlePrice(item.actual)),
        percent: _shapeData(
          item.actual && item.cummulative && item.cummulative.amount
            ? `${Math.round(
                ((item.actual.amount / item.cummulative.amount) * 10000) / 100,
              )} %`
            : '',
        ),
        invoice_due: _shapeData(_handlePrice(item.invoice_due)),
        invoice_overdue: _shapeData(_handlePrice(item.invoice_overdue)),
        owner: _shapeOwnership(item.ownership),
        credit_limit: _shapeData(_handlePrice(item.credit_limit)),
        payment_terms: _shapeData(formatPaymentTerms(item.payment_terms)),
        disabled: item.status === 'inactive',
        id: item._id,
      });
      return acumulator;
    }, []);
    return result;
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 'unset',
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeData(a, b) {
    const newHeaders = this.normalizedHeaders(a);
    const data = this.normalizeColumns(b);

    return { headers: newHeaders, rows: data };
  }

  handleContactOnClick(id) {
    const { history, firm } = this.props;
    if (history) {
      history.push(`/${firm}/contacts/${id}`);
    }
  }

  handleNameOnClick(id) {
    const { history, match, customNameOnClick } = this.props;
    if (customNameOnClick) {
      customNameOnClick(id);
    } else {
      if (history) {
        history.push(`${match.url}/${id}`);
      }
    }
  }

  handleRowOnChange(id) {
    const { checkedRows } = this.state;
    if (checkedRows.includes(id)) {
      this.setState({
        checkedRows: checkedRows.filter(f => f !== id),
      });
    } else {
      this.setState({ checkedRows: [...checkedRows, id] });
    }
  }

  handleAllRowsOnChange() {
    const { content, allRoswChecked } = this.state;
    if (allRoswChecked) {
      this.setState({ checkedRows: [], allRoswChecked: false });
    } else {
      this.setState({
        checkedRows: content.map(item => item.id),
        allRoswChecked: true,
      });
    }
  }

  async usersActivation(status = 'active') {
    try {
      const { firm } = this.props;
      const { checkedRows, lastSearchOwner, lastSearchValue } = this.state;

      await API2.putCustomersStatusAction(firm, {
        data: {
          newStatus: status,
          customers: checkedRows,
        },
      });

      // const newContent = content.filter(f => !checkedRows.includes(f.id));
      this.fetchData({ owner: lastSearchOwner, q: lastSearchValue });
      this.setState({
        // content: newContent,
        checkedRows: [],
        allRoswChecked: false,
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            activateError: __('Na úžívateľa nemáte potrebné práva'),
          });
          break;
        default:
          this.setState({
            activateError: __('Vyskytla sa chyba'),
          });
      }
    }
  }

  sort(sorter) {
    this.setSorter(sorter);
    this.setState({ sorter }, () => {
      this.fetchData();
    });
  }

  async loadSearchRrsult(value) {
    this.setState({ loading: true });
    const { loadResults, lastSearchOwner } = this.state;
    if (loadResults[value]) {
      this.setState({
        content: this.normalizeColumns(loadResults[value].items),
        loading: false,
        limit: loadResults[value].limit,
        offset: loadResults[value].offset,
        total: loadResults[value].total,
        nextOffset: loadResults[value].next_offset,
      });
    } else {
      const newData = await this.fetchData(
        value !== ''
          ? { q: value.toLowerCase(), owner: lastSearchOwner }
          : { owner: lastSearchOwner },
      );
      loadResults[value] = newData;
      this.setState({ loadResults });
    }
  }

  async searchDataByOwner(value) {
    this.setState({
      loading: true,
      selectedOwner: value,
    });
    const { loadResults, lastSearchValue } = this.state;

    const newData = await this.fetchData(
      value && value.value
        ? { owner: value.value, q: lastSearchValue }
        : { q: lastSearchValue },
    );
    loadResults[value] = newData;
    this.setState({ loadResults });
  }

  renderConfigFilters(selectedFilter) {
    return this.filters.map(filter => {
      const { selected } = selectedFilter.find(
        s => s.filterValue === filter.filterValue,
      );

      return (
        <ControlButton
          name={__e(filter.values.find(v => v.value === selected).name)}
        >
          <LinkWrap>
            {filter.values.map(value => (
              <a
                href="/"
                onClick={() =>
                  this.handleFilterChange(filter.filterValue, value.value)
                }
              >
                {__e(value.name)}
              </a>
            ))}
          </LinkWrap>
        </ControlButton>
      );
    });
  }

  renderControlBar() {
    const { checkedRows, active, lastSearchValue, selectedFilter } = this.state;
    const { onClose, history, showSearch } = this.props;
    return (
      <React.Fragment>
        <ControllBar
          showSearch={showSearch}
          name={this.segmentName}
          history={history}
          defaultValue={lastSearchValue}
          onChange={val => this.loadSearchRrsult(val)}
          // onOwnersChange={val => this.searchDataByOwner(val)}
          // fetchOwners={q => this.fetchUsers(q)}
          selectedOwner={this.state.selectedOwner}
        >
          {this.renderConfigFilters(selectedFilter)}

          {onClose && (
            <Button small basic danger onClick={() => onClose()}>
              {__('Zrušiť výber')}
            </Button>
          )}
          <CheckAccess
            operation="putCustomersStatusAction"
            Component={
              <BulkWrapper display={checkedRows.length && active !== true}>
                <ControlBarButton
                  small
                  primary
                  basic
                  onClick={() => this.usersActivation()}
                >
                  {__('Aktivovať vybraných')}
                </ControlBarButton>
              </BulkWrapper>
            }
          />
          <CheckAccess
            operation="putCustomersStatusAction"
            Component={
              <BulkWrapper display={checkedRows.length && active !== false}>
                <ControlBarButton
                  small
                  primary
                  basic
                  onClick={() => this.usersActivation('inactive')}
                >
                  {__('Deaktivovat vybraných')}
                </ControlBarButton>
              </BulkWrapper>
            }
          />
        </ControllBar>
      </React.Fragment>
    );
  }

  renderPageNavigation(limit, offset, total) {
    const { showPagerForNavisionServices, activeNavisionPage } = this.state;

    if (showPagerForNavisionServices) {
      return (
        <PageWrapper>
          <NavisionpPager>
            {/* <ArrowWrapper onClick={() => this.navisionOnDecrease()}>
              <Icon name="arrow-left" />
            </ArrowWrapper> */}

            <ArrowWrapper
              onClick={() => {
                if (activeNavisionPage !== 1) this.navisionOnDecrease();
              }}
            >
              <Icon
                name="arrow-left"
                color={activeNavisionPage !== 1 ? 'rgb(85, 85, 85)' : 'white'}
              />
            </ArrowWrapper>

            <NumberWrapper> {activeNavisionPage} </NumberWrapper>
            <ArrowWrapper onClick={() => this.navisionOnIncrease()}>
              <Icon name="arrow-right" />
            </ArrowWrapper>
          </NavisionpPager>
        </PageWrapper>
      );
    }

    return (
      <PageWrapper>
        <PageInfo offset={offset} limit={limit} total={total} />
        <PageNavigator
          activePage={offset / limit + 1}
          end={total / limit + 1}
          onPageClick={page => this.onPageClick(page)}
          onDecrease={() => this.onDecrease()}
          onIncrease={() => this.onIncrease()}
        />
      </PageWrapper>
    );
  }

  renderFilterItem(item, index) {
    const { filters } = this.state;

    if (item.type === 'input') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <Input
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
            value={item.value}
            placeholder={item.label}
          />
        </div>
      );
    }

    if (item.type === 'select') {
      return (
        <div className="select-wrapper">
          <Label>{item.label}</Label>
          <Select
            size="s"
            placeholder={item.label}
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
          >
            <option>-</option>
            {item.values.map(selectItem => (
              <option
                key={selectItem.key}
                value={selectItem.value}
                selected={selectItem.value === item.value}
              >
                {selectItem.name}
              </option>
            ))}
          </Select>
        </div>
      );
    }

    if (item.type === 'number') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <Input
            type="number"
            onChange={e => {
              filters[index].value = e.target.value;
              this.setState({
                filters,
              });
            }}
            value={item.value}
            placeholder={item.label}
          />
        </div>
      );
    }

    if (item.type === 'search-select') {
      return (
        <div className="search-select-wrapper">
          <Label>{item.label}</Label>
          <SearchableSelect
            value={item.value}
            loadOptions={query => item.onSearch(query)}
            placeholder={item.label}
            name={item.name}
            handleOnChange={e => {
              filters[index].value = e;
              this.setState({
                filters,
              });
            }}
          />
        </div>
      );
    }

    if (item.type === 'date') {
      return (
        <div className="input-wrapper">
          <Label>{item.label}</Label>
          <DateAndTimerPicker
            timePlaceHolder="Čas"
            datePlaceHolder="Dátum"
            value={item.value && moment(item.value)}
            onChange={e => {
              filters[index].value = e;
              this.setState({
                filters,
              });
            }}
          />
        </div>
      );
    }
  }

  renderFilters() {
    const { filters } = this.state;

    return (
      <FiltersMainWrapper>
        <FiltersWrapper key={this.segmentName}>
          {filters &&
            filters.map((f, index) => (
              <div
                className="filterItem"
                style={{
                  zIndex: f.type === 'search-select' ? 100 : 4,
                }}
              >
                {this.renderFilterItem(f, index)}
              </div>
            ))}
        </FiltersWrapper>
        <div className="button-wrapper">
          <Button
            small
            danger
            onClick={() => {
              this.setState(
                {
                  filters: this.state.filters.map(f =>
                    Object.assign(f, { value: '' }),
                  ),
                },
                () => this.applyFilter(),
              );
            }}
          >
            {__('Zrušiť filter')}
          </Button>
          <div className="space" />
          <Button small primary onClick={() => this.applyFilter()}>
            {__('Aplikovať filter')}
          </Button>
          <div className="space" />
          {this.exportCSVData && (
            <Button
              loading={this.state.exportLoading}
              small
              primary
              onClick={() => this.exportCSVData()}
            >
              {__('Exportovať výsledok')}
            </Button>
          )}
        </div>
      </FiltersMainWrapper>
    );
  }

  render() {
    const { showPager, small, showHeader } = this.props;
    const {
      loading,
      limit,
      total,
      offset,
      content,
      sorter,
      error,
      activateError,
      lastOpenedId,
      defaultScrollPosition,
    } = this.state;

    return (
      <React.Fragment>
        {showHeader && this.renderControlBar()}
        {activateError && (
          <AnimatedFormMessageWrapper display={activateError}>
            <Message error message={activateError} />
          </AnimatedFormMessageWrapper>
        )}
        <AnimatedFormMessageWrapper display={error}>
          {error && (
            <ExpandedError
              error={error}
              errorTitle={__('Pri načítaní dát nastala chyba')}
              customCodeId={`${this.segmentName} - zoznam`}
            />
          )}
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={this.state.showFilter}>
          {this.renderFilters()}
        </AnimatedFormMessageWrapper>
        <TableWrapper small={small} showPager={showPager}>
          <TableV2
            saveScrollPositions={(position, rowInfo) =>
              this.saveScrollPositions(position, rowInfo)
            }
            defaultScrollPosition={defaultScrollPosition}
            columns={this.createColumns(sorter)}
            minWidth={10}
            loading={loading}
            data={content}
            lastOpenedId={lastOpenedId}
            loadingColor="#A448A3"
            getTdProps={() => {
              return { style: { padding: 0, margin: 'auto' } };
            }}
            getTheadThProps={(state, rowInfo, column) => {
              if (column && column.sortable) {
                return {
                  onClick: e => {
                    this.sortByV2(column.sorterValue);
                  },
                };
              }
              return {};
            }}
            className="-highlight -striped"
          />
        </TableWrapper>
        {showPager ? this.renderPageNavigation(limit, offset, total) : ''}
      </React.Fragment>
    );
  }
}

BaseTable.propTypes = {
  match: PropTypes.object.isRequired, //eslint-disable-line
  history: PropTypes.object.isRequired, //eslint-disable-line
  firm: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  customNameOnClick: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  showPager: PropTypes.bool,
  showHeader: PropTypes.bool,
  small: PropTypes.bool,
  showSearch: PropTypes.bool,
  crm_settings: PropTypes.shape({}),
};

BaseTable.defaultProps = {
  customNameOnClick: null,
  onClose: null,
  loading: true,
  showPager: true,
  showHeader: true,
  small: false,
  showSearch: true,
  crm_settings: {},
};

export default BaseTable;
