import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  __,
  getApprovalStatus,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 600px;
  padding-left: 32px;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameAndDateWrapper = styled.div`
  .date {
    font-size: ${rem(12)};
    color: #8b8989;
  }
`;

const ImageBigPreview = styled.img`
  position: absolute;
  z-index: 1000;
  bottom: 60px;
  border-radius: 8px;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
`;

const ImagesWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .big {
    position: absolute;
    display: none;
  }

  :hover {
    .big {
      display: block;
    }
  }
`;

const ImagePreview = styled.img`
  height: ${rem(100)};
  width: ${rem(100)};
  margin-right: ${rem(4)};
  margin-bottom: ${rem(4)};
  border-radius: ${rem(4)};
`;

const Review = styled.div`
  font-size: ${rem(12)};
  /* display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem; */
  display: flex;
  width: 100%;
  margin-bottom: ${rem(12)};
  gap: ${rem(100)};

  .group {
    display: flex;
    flex-direction: column;
    gap: ${rem(16)};
  }

  .row {
    display: flex;
    max-width: ${rem(600)};
    align-items: center;
    margin-left: ${rem(12)};
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const baseURL = process.env.REACT_APP_IMG_DOMAIN;

class ReviewDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      newComment: { text: '' },
      saveLoading: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getEshopProductReviewDetailAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.setState({
        loading: false,
        data: Object.assign(result, {}),
      });
    } catch (e) {
      this.setState({
        error: __('Recenziu sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  handleNewCommentChange(field, value) {
    const { newComment } = this.state;
    newComment[field] = value;

    this.setState({
      newComment,
    });
  }

  async deleteReview() {
    try {
      this.setState({
        deleteLoading: true,
      });

      await API2.deleteEshopProductReviewAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.props.history.push(`/${this.props.firm}/reviews`);
    } catch (e) {
      this.setState({
        error: __('Recenziu sa nepodarilo odstrániť'),
        deleteLoading: false,
      });
    }
  }

  async approveReview(approve) {
    const { data } = this.state;
    try {
      this.setState({
        approveLoading: true,
      });

      const resultData = await API2.putReviewAprovalRequestAction(
        this.props.firm,
        getIdFromProps(this.props),
        { approval: approve },
      );

      this.setState({
        approveLoading: false,
        data: Object.assign({}, data, { approval: resultData.approval }),
      });
    } catch (e) {
      this.setState({
        error: __('Recenziu sa nepodarilo schváliť'),
        approveLoading: false,
      });
    }
  }

  renderGroup(group, disabledEditButtons) {
    return (
      <div className="group">
        <div className="row">
          <div className="label">{group.label}</div>
          {group.button && !disabledEditButtons && (
            <div className="button" onClick={() => group.button.onClick()}>
              {group.button.title}
            </div>
          )}
        </div>
        {group.values.map(v => (
          <div className="row">
            <div className="value">{v}</div>
          </div>
        ))}
      </div>
    );
  }

  renderControlBar() {
    const { history } = this.props;
    const { data, approveLoading } = this.state;

    return (
      <ControllBar history={history} name="Detail recenzie">
        {['waiting', 'declined'].includes(data.approval) && (
          <ControlBarButton
            small
            primary
            showIcon={false}
            loading={approveLoading}
            onClick={() => this.approveReview('approved')}
          >
            {__('Schváliť')}
          </ControlBarButton>
        )}

        {['waiting', 'approved'].includes(data.approval) && (
          <ControlBarButton
            small
            primary
            warning
            loading={approveLoading}
            showIcon={false}
            onClick={() => this.approveReview('declined')}
          >
            {__('Zamietnúť')}
          </ControlBarButton>
        )}

        <ControlBarButton
          small
          primary
          danger
          showIcon={false}
          onClick={() => this.deleteReview()}
        >
          {__('Odstrániť recenziu')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const { data, loading, error, success } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <HeaderLabel>
            <div className="text">{__(`Informácie`)}</div>
          </HeaderLabel>
          <Review>
            <div className="group">
              <div className="row">
                <div className="label">{__('Používateľ')}:</div>
              </div>
              {data.contact ? (
                <React.Fragment>
                  <div className="row">
                    <div className="label">{__('Meno')}:</div>
                    <div className="value">{data.contact.name}</div>
                  </div>
                  <div className="row">
                    <div className="label">{__('Priezvisko')}:</div>
                    <div className="value">{data.contact.surname}</div>
                  </div>
                  <div className="row">
                    <div className="label">{__('Email')}:</div>
                    <div className="value">{data.contact.email}</div>
                  </div>
                </React.Fragment>
              ) : (
                <div className="row">
                  <div className="value">{data.customer_name}</div>
                </div>
              )}
            </div>

            <div className="group">
              <div className="row">
                <div className="label">{__('Recenzia')}:</div>
              </div>

              <div className="row">
                <div className="label">{__('Produkt')}:</div>
                <div className="value">{data.product.name}</div>
              </div>

              <div className="row">
                <div className="label">{__('Komentár')}:</div>
                <div className="value">{data.text}</div>
              </div>

              <div className="row">
                <div className="label">{__('Hodnotenie')}:</div>
                <div className="value">{data.rating} / 5</div>
              </div>

              <div className="row">
                <div className="label">{__('Meno zákazníka')}:</div>
                <div className="value">{data.customer_name}</div>
              </div>

              <div className="row">
                <div className="label">{__('Dátum vytvorenia')}:</div>
                <div className="value">
                  {formatDate(
                    moment(data.created_date),
                    FORMAT_HUMANE_DATETIME,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="label">{__('Stav')}:</div>
                <div className="value">{getApprovalStatus(data.approval)}</div>
              </div>
            </div>
          </Review>

          <HeaderLabel>
            <div className="text">{__(`Obrázky`)}</div>
          </HeaderLabel>

          <FilesWrapper>
            {data.images.map(a => (
              <FileWrapper key={a.fullPath}>
                <ImagesWrapper>
                  <ImagePreview
                    src={`${baseURL}/images/100x100/${a.fullPath}`}
                  />
                  <div className="big">
                    <ImageBigPreview
                      src={`${baseURL}/images/320x320/${a.fullPath}`}
                    />
                  </div>
                </ImagesWrapper>
                <NameAndDateWrapper>
                  <div className="date">{a.name}</div>
                  <div className="date">
                    {formatDate(moment(a.created_date), FORMAT_HUMANE_DATETIME)}
                  </div>
                </NameAndDateWrapper>
              </FileWrapper>
            ))}
          </FilesWrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

ReviewDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ReviewDetail);
