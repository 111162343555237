import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Loader from 'oxyrion-ui/lib/Loader';
import moment from 'moment';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import { formatPrice, __ } from '../../Utils';
import API2 from '../../API2';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import Label from 'oxyrion-ui/lib/Label/Label';
import DatePicker from '../../Components/DatePicker';
import CashRegisterProductsStats from '../CashRegisterProductsStats';
import Select from 'oxyrion-ui/lib/Select';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${rem(1760)};
  width: 100%;
  padding: ${rem(16)};
  padding-top: ${rem(40)};
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }

  .description-wrapper {
    margin-left: 12px;
    font-size: 14px;
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const DatePickerWrapper = styled.div`
  max-width: ${rem(200)};
  margin-right: ${rem(10)};

  .react-datepicker {
    right: 0px;
  }

  @media (min-width: 480px) {
    .react-datepicker {
      right: 50px !important;
    }
  }
`;

class DailyOverview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dailyStats: {},
      loading: false,
      selectedDate: moment(),
      selectedCashRegister: 'all',
      cashRegisters: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  handleDateSelect(value) {
    this.setState(
      {
        selectedDate: value,
      },
      () => {
        this.fetchData();
      },
    );
  }

  async fetchData() {
    try {
      this.setState({ loading: true });
      const { sorter, selectedDate, selectedCashRegister } = this.state;

      const parameters = {
        fromDate:
          selectedDate &&
          moment(selectedDate)
            .startOf('day')
            .toISOString(),
        toDate:
          selectedDate &&
          moment(selectedDate)
            .endOf('day')
            .toISOString(),
      };

      if (selectedCashRegister && selectedCashRegister !== 'all') {
        parameters.cashRegisterId = selectedCashRegister;
      }

      const params = Object.assign({}, parameters, { sorter });
      const [result, cashBalance, cashRegisters] = await Promise.all([
        API2.getCashRegisterSummary(this.props.firm, params),
        API2.getActualCashRegisterCashBalanceAction(this.props.firm, params),
        API2.getCashRegistersListAction({
          companyId: this.props.firm,
          onlyProduction: true,
        }),
      ]);

      const paymentsTypes = [];

      result.payments.forEach(p => {
        paymentsTypes.push({
          label: p.type,
          value: formatPrice({ amount: p.amount, currency: p.currency }),
        });
      });

      paymentsTypes.push({
        label: __('Celkovo'),
        value: formatPrice(result.total),
      });

      const baseInfo = [
        {
          label: __('Počet dokladov'),
          value: result.number_of_recipes,
        },
      ];

      const cashStats = [
        {
          label: __('Vklady'),
          value: formatPrice(cashBalance.deposits),
        },
        {
          label: __('Výbery'),
          value: formatPrice(cashBalance.withdrawals),
        },
        {
          label: __('Hotovostné platby'),
          value: formatPrice(cashBalance.payments),
        },
        {
          label: __('Stav v pokladni'),
          value: formatPrice(cashBalance.balance),
        },
      ];

      this.setState({
        loading: false,
        dailyStats: result,
        paymentsTypes,
        baseInfo,
        cashStats,
        cashRegisters: cashRegisters.items,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  renderRow(i) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
      </div>
    );
  }

  handleCrChange(value) {
    this.setState({ selectedCashRegister: value }, () => {
      this.fetchData();
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { selectedDate, selectedCashRegister, cashRegisters } = this.state;

    const windowWidth = window.innerWidth;

    return (
      <ControllBar history={history} name={__('Denný prehľad')}>
        <Select
          size="s"
          value={selectedCashRegister}
          onChange={e => {
            this.handleCrChange(e.target.value);
          }}
        >
          <option value="all" selected>
            {__('Všetky pokladne')}
          </option>
          {cashRegisters.map(cr => {
            return (
              <option value={cr._id}>
                {cr._id} - {cr.name}
              </option>
            );
          })}
        </Select>
        <Label>{__('Prehľad dňa')}</Label>
        <DatePickerWrapper>
          <DatePicker
            placeholderText={__('Dátum')}
            selected={selectedDate}
            onChange={e => this.handleDateSelect(e)}
            withPortal={windowWidth > 480 ? false : true}
          />
        </DatePickerWrapper>
      </ControllBar>
    );
  }

  render() {
    const {
      loading,
      dailyStats,
      paymentsTypes,
      baseInfo,
      cashStats,
    } = this.state;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <MainWrapper>
          <Wrapper>
            <HeaderLabel>
              <div className="text">{__('Základne informácie')}</div>
            </HeaderLabel>
            <InfoWrapper>
              {baseInfo && baseInfo.map(i => this.renderRow(i))}
            </InfoWrapper>

            <HeaderLabel>
              <div className="text">{__('Prehľad podľa platobných metód')}</div>
            </HeaderLabel>
            <InfoWrapper>
              {paymentsTypes && (
                <div>{paymentsTypes.map(i => this.renderRow(i))}</div>
              )}
            </InfoWrapper>

            <HeaderLabel>
              <div className="text">
                {__('Prehľad hotovostných transakcii')}
              </div>
            </HeaderLabel>
            <InfoWrapper>
              {cashStats && <div>{cashStats.map(i => this.renderRow(i))}</div>}
            </InfoWrapper>

            <HeaderLabel>
              <div className="text">
                {__('Denný prehľad predaných produktov')}
              </div>
            </HeaderLabel>
            <CashRegisterProductsStats
              small
              data={dailyStats.sold_products}
              showPager={false}
            />
          </Wrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

DailyOverview.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(DailyOverview);
