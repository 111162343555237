/* eslint-disable */
import 'isomorphic-fetch';

class CustomAPI {
  /**
   * Get finPortalRecipe detail
   * @method
   * @param {} billId - The ID of the receipt
   */
  async getFinPortalRecipeDetailAction(billId) {
    const finFindUrl =
      'https://ekasa.financnasprava.sk/mdu/api/v1/opd/receipt/find';
    const header = new Headers();
    header.append('Content-Type', 'application/json');

    const body = JSON.stringify({
      receiptId: billId,
    });

    const options = {
      method: 'POST',
      headers: header,
      body: body,
    };

    const response = await fetch(finFindUrl, options);

    if (!response.ok) {
      throw new Error('Failed to fetch receipt detail');
    }

    const data = await response.json();
    return data;
  }
}

export default new CustomAPI();
