import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
  formatNumber,
} from '../../Utils';
import {
  searchCashRegisters,
  searchContacts,
} from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/cashRegisterOrders';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import { StyledA } from '../../Components/ReusableComponents';
import API2 from '../../API2';
import FileViewDownload from '../../Components/FileViewDownload';

class CashRegisterOrdersList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'order_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Objednávky');
    this.accessors = [
      'id',
      'customer',
      'order_date',
      'invoice',
      'shipment',
      'total_price',
      'total_price_vat',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      showAttachmentsPopUp: false,
      popupData: [],
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'cashRegisterId',
          label: __('Pokladňa'),
          value: '',
          type: 'search-select',
          onSearch: q => searchCashRegisters(q, this.props.firm),
        },
      ],
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const newData = await API2.getCashRegisterOrdersAction(
        this.props.firm,
        params,
      );

      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(
          renderLink(`./cash-register-orders/${item._id}`, item._id),
        ),
        customer: _shapeData(
          item.customer && item.customer._id ? (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/contacts/${item.customer._id}`}
            >
              {item.customer.email}
            </StyledA>
          ) : (
            `${item.customer.email} - ${__('odstranený')}`
          ),
        ),
        order_date: _shapeData(
          formatDate(moment(item.order_date), FORMAT_HUMANE_DATETIME),
        ),
        invoice: _shapeData(
          <FileViewDownload
            customWidth={350}
            name={item.invoice_id}
            type={'application/pdf'}
            history={this.props.history}
            downloadFunction={async () => {
              return API2.getSalesInvoicePdfAction(
                this.props.firm,
                item.invoice_id,
              );
            }}
            error={e => {
              this.setState({ error: e });
            }}
          />,
        ),
        shipment: _shapeData(
          <FileViewDownload
            customWidth={350}
            name={item.shipment_id}
            type={'application/pdf'}
            history={this.props.history}
            downloadFunction={async () => {
              return API2.getSalesShipmentPdfAction(
                this.props.firm,
                item.shipment_id,
              );
            }}
            error={e => {
              this.setState({ error: e });
            }}
          />,
        ),
        total_price_vat: _shapeData(
          formatNumber(item.total_price_vat.amount, 2),
        ),
        total_price: _shapeData(formatNumber(item.total_price.amount, 2)),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Vystavené objednávky cez kasu')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisterOrdersList);
