import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { Button } from 'oxyrion-ui/lib';
import { rem } from 'polished';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  transledOrderState,
  __,
} from '../../Utils';
import Label from 'oxyrion-ui/lib/Label/Label';
import Textarea from 'oxyrion-ui/lib/Textarea';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import API2 from '../../API2';

const Wrapper = styled.div`
  .no-comments {
    font-size: ${rem(12)};
    margin-left: 12px;
    color: #909090;
  }
`;

const CommentRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(4)};
  font-size: ${rem(14)};
  border-bottom: ${rem(1)} solid #eee;
  margin-bottom: ${rem(12)};

  .top-part {
    display: flex;
  }

  .bottom-part {
    margin-top: ${rem(8)};
    padding: 12px;
    background: #eee;
    font-weight: normal;
  }

  .value {
    margin-right: ${rem(8)};
    padding-right: ${rem(8)};
    border-right: ${rem(1)} solid black;
    :last-of-type {
      border-right: none;
    }
  }
`;

const NewComment = styled.div`
  margin-bottom: ${rem(24)};
  .row {
    margin-bottom: ${rem(12)};
  }
`;

class OrderComments extends React.Component {
  state = {
    comments: [],
    newComment: {},
  };

  async componentDidMount() {
    const { id } = this.props;
    try {
      const orders = await API2.getEshopOrderCommentsAction(
        this.props.firm,
        id,
      );
      this.setState({
        comments: orders.comments || [],
      });
    } catch (e) {
      console.log(e);
    }
  }

  notificationInfo(email, message) {
    let string = __('nenotifikovaný');

    if (email && message) {
      string = __('emailom, správou');
    } else if (email) {
      string = __('emailom');
    } else if (message) {
      string = __('správou');
    }

    return string;
  }

  translateCommentRole(role) {
    if (role === 'customer') {
      return __('Zákazník');
    }

    if (role === 'crm_user') {
      return __('Predajca');
    }

    if (role === 'system') {
      return __('Systém');
    }

    return role;
  }

  handleNewCommentChange(field, value) {
    const { newComment } = this.state;
    newComment[field] = value;

    this.setState({
      newComment,
    });
  }

  async saveComment() {
    this.setState({ saveLoading: true });
    const { firm, id, onSuccces, onError, orderState } = this.props;
    const { newComment } = this.state;

    if (newComment.text && newComment.text.length > 0) {
      try {
        const orders = await API2.postEshopOrderCommentAction(firm, id, {
          notifyUserByMessage: newComment.notifyByMessage,
          notifyUserByEmail: newComment.notifyByEmail,
          body: {
            text: newComment.text,
            order_state: orderState,
          },
        });
        onSuccces();
        this.setState({
          comments: orders.comments || [],
          saveLoading: false,
          newComment: {},
        });
      } catch (e) {
        onError();
        this.setState({ saveLoading: false });
      }
    }
  }

  render() {
    const { comments, newComment, saveLoading } = this.state;

    return (
      <Wrapper>
        {!comments ||
          (comments.length === 0 && (
            <div className="no-comments">
              {__('Doteraz neboli k objednávke pridané žiadne zápisy')}
            </div>
          ))}
        {comments.map(comment => (
          <CommentRow>
            <div className="top-part">
              <div className="value">
                {formatDate(
                  moment(comment.created_date),
                  FORMAT_HUMANE_DATETIME,
                )}
              </div>
              <div className="value">{`${comment.user.name} ${comment.user.surname}`}</div>
              <div className="value">
                {this.translateCommentRole(comment.user.role)}
              </div>
              <div className="value">
                {transledOrderState(comment.order_state)}
              </div>

              <div className="value">
                <b
                  style={{
                    marginRight: rem(3),
                  }}
                >
                  {__('Zákazník')}:
                </b>
                {this.notificationInfo(
                  comment.customer_notified_by_email,
                  comment.customer_notified_by_message,
                )}
              </div>
            </div>
            <div className="bottom-part">{comment.text}</div>
          </CommentRow>
        ))}
        <NewComment>
          <Label>{__('Nový komentár')}</Label>
          <Textarea
            style={{
              width: rem(400),
              height: rem(120),
              marginBottom: rem(12),
            }}
            value={newComment.text || ''}
            placeholder={`${__('Zadajte komentár k objednávke')}`}
            onChange={e => this.handleNewCommentChange('text', e.target.value)}
          />
          <div className="row">
            <LabeledCheckbox
              checked={newComment.notifyByEmail}
              labelText={__('Notifikovať zákazníka emailom')}
              onChange={e =>
                this.handleNewCommentChange('notifyByEmail', e.target.checked)
              }
            />
          </div>
          <div className="row">
            <LabeledCheckbox
              checked={newComment.notifyByMessage}
              labelText={__('Zobraziť v objednávke na eshope')}
              onChange={e =>
                this.handleNewCommentChange('notifyByMessage', e.target.checked)
              }
            />
          </div>

          <Button
            small
            primary
            loading={saveLoading}
            disabled={saveLoading}
            onClick={() => this.saveComment()}
          >
            {__('Potvrdiť komentár')}
          </Button>
        </NewComment>
      </Wrapper>
    );
  }
}

export default OrderComments;
