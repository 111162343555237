import moment from 'moment';
import { rem } from 'polished';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import {
  formatDate,
  formatPrice,
  __,
  renderLink,
  getPaymentStateLabel,
  getOrderStateLabel,
  FORMAT_HUMANE_DATETIME,
  getOrderEomStateLabel,
} from '../../Utils';
import { Centerer } from '../ReusableComponents';

const Wrapper = styled.div`
  .ReactTable {
    border: none;
    border-radius: 8px;
  }

  .ReactTable.-striped .rt-tr.-odd {
    background: #f8f1f8;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${rem(40)};
  color: #6f6f6f;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: 15px;
`;

const StyledTd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ isComp }) => (isComp ? '#ee6500' : '#434245')};
  font-weight: ${({ isComp }) => (isComp ? '700' : '500')};
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  position: relative;
`;

const TestLabel = styled.div`
  position: absolute;
  right: -21px;
  top: -13px;
  background: red;
  padding: 3px;
  color: white;
  font-size: 12px;
  border-radius: 10px;
`;

export default class OrdersTable extends PureComponent {
  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  createColumns() {
    const { TEST_EMAILS } = this.props;
    return [
      {
        accessor: '_id',
        Header: () => <StyledHeader>{__('Číslo objednávky')}</StyledHeader>,

        Cell: props => {
          return (
            <StyledTd>
              {renderLink(`./orders/${props.value}`, props.value, true, 16)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'customer',
        Header: () => <StyledHeader>{__('Zákazník')}</StyledHeader>,

        Cell: props => {
          return (
            <StyledTd>
              {props.value._id ? (
                <StyledA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_CRM_BASE_URL}/${
                    this.props.firm
                  }/contacts/${props.value._id}`}
                >
                  {TEST_EMAILS.includes(props.value.email) && (
                    <TestLabel>test</TestLabel>
                  )}
                  {props.value.email}
                </StyledA>
              ) : (
                `${props.value.email} - ${__('Odstranený')}`
              )}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'order_date',
        Header: () => <StyledHeader>{__('Dátum objednania')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              {formatDate(moment(props.value), FORMAT_HUMANE_DATETIME)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'delivery_type',
        Header: () => <StyledHeader>{__('Doprava')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{props.value ? props.value.label : '-'}</StyledTd>;
        },
      },
      {
        accessor: 'payment_type',
        Header: () => <StyledHeader>{__('Platba')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{props.value ? props.value.label : '-'}</StyledTd>;
        },
      },
      {
        accessor: 'payment_state',
        Header: () => <StyledHeader>{__('Stav platby')}</StyledHeader>,
        Cell: props => {
          return <Centerer>{getPaymentStateLabel(props.value)}</Centerer>;
        },
      },
      {
        accessor: 'eom_state',
        Header: () => <StyledHeader>{__('Stav objednávky')}</StyledHeader>,
        Cell: props => {
          return (
            <Centerer>
              {getOrderEomStateLabel(props.value) ||
                getOrderStateLabel(props.original.order_state)}
            </Centerer>
          );
        },
      },
      {
        accessor: 'total_price',
        Header: () => <StyledHeader>{__('Cena bez DPH')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{formatPrice(props.value)}</StyledTd>;
        },
      },
    ];
  }

  render() {
    const { data, loading } = this.props;

    return (
      <Wrapper>
        <ReactTable
          {...this.props}
          resizable={false}
          pageSize={data.length}
          columns={this.useCustomheader(this.createColumns())}
          data={data}
          showPagination={false}
          loading={loading}
          NoDataComponent={() => (
            <NoData>{__('Nie su žiadné nové objednávky')}</NoData>
          )}
          // getTheadProps={() => {
          //   return Object.assign(
          //     {},
          //     {
          //       style: {
          //         boxShadow: 'unset',
          //         borderBottom: `solid ${rem(0.5)} #eee`,
          //         top: 0,
          //       },
          //     },
          //   );
          // }}
          className="-striped"
        />
      </Wrapper>
    );
  }
}
