import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Loader from 'oxyrion-ui/lib/Loader';
import moment from 'moment';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import { __ } from '../../Utils';
import API2 from '../../API2';
import { LoaderWrapper } from '../../Components/ReusableComponents';
import BarChartStats from '../../Components/BarChartStats';
import Select from 'oxyrion-ui/lib/Select';
import StatsTable from '../StatsTable';
import LineComposedChartComponent from '../../Components/LineComposedChart';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.l`
 flex-direction: row;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${rem(1760)};
  width: 100%;
  padding: ${rem(16)};
  padding-top: ${rem(40)};
`;
const Space = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

class StoreSales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shops: [],
      dayStats: [],
      monthStats: [],
      weekDaysStats: [],
      productsStats: [],
      loader: false,
      selectedShop: 'all',
      selectedCity: '',
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      this.setState({
        loader: true,
      });

      const [
        { items: shops },
        { months, week, weekDays },
        { products },
      ] = await Promise.all([
        API2.getShopsAction({ companyId: this.props.firm }),
        API2.getShopsStatsAction(this.props.firm, this.state.selectedShop, {
          year: this.props.year,
        }),
        API2.getTopProductsSalesShopAction(this.props.firm),
      ]);

      let monthlyWeather = [];
      let dailyWeekWeather = [];
      if (this.state.selectedShop !== 'all') {
        dailyWeekWeather = await API2.getWeatherByDate(this.props.firm, {
          city: this.state.selectedCity,
          from: moment()
            .clone()
            .startOf('isoWeek'),
          to: moment()
            .clone()
            .endOf('isoWeek'),
        });

        monthlyWeather = await API2.getWeatherByMonthAction(this.props.firm, {
          city: this.state.selectedCity,
          year: this.props.year,
        });
      }

      this.setState({
        dayStats: this.normalizeDayStats(dailyWeekWeather, week),
        monthStats: this.normalizeMonthlyStats(monthlyWeather, months),
        weekDaysStats: weekDays.map(m => ({
          name: moment(m._id, 'E').format('ddd'),
          amount: m.total,
          count: m.count,
        })),
        loader: false,
        shops,
        productsStats: products.map(ps =>
          Object.assign(ps, { count: ps.shop_sold_count }),
        ),
      });
    } catch (e) {
      console.log(e);
    }
  }

  normalizeDayStats(weather, stats) {
    const groupItems = stats.map(s => {
      const getWeather = weather.find(w => w.date === s._id);

      const res = Object.assign(s, {
        ...getWeather,
        name: moment(s._id).format('dd'),
        amount: s.total,
        count: s.count,
      });

      return res;
    });

    return groupItems;
  }

  normalizeMonthlyStats(weather, stats) {
    const groupItems = stats.map(s => {
      const getWeather = weather.find(w => w.month === s._id);
      const res = Object.assign(s, {
        ...getWeather,
        name: moment(s._id, 'MM').format('MMM'),
        amount: s.total,
        count: s.count,
      });

      return res;
    });

    return groupItems;
  }

  handleShopChange(value) {
    this.setState(
      { selectedShop: value._id, selectedCity: value.shopId },
      () => {
        this.fetchData();
      },
    );
  }

  currentWeekString() {
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    return `${weekStart.format('Do MMM')} - ${weekEnd.format('Do MMM')}`;
  }

  renderControlBar() {
    const { history } = this.props;
    const { shops, selectedShop } = this.state;

    return (
      <ControllBar history={history} name={__('Štatistiky predaja - predajne')}>
        <Select
          size="s"
          value={selectedShop}
          onChange={e => {
            this.handleShopChange(JSON.parse(e.target.value));
          }}
        >
          <option value="all" selected>
            {__('Všetky predajne')}
          </option>
          {shops.map(cr => {
            return (
              <option value={JSON.stringify(cr)} key={cr._id}>
                {cr.shopId} - {cr.name}
              </option>
            );
          })}
        </Select>
      </ControllBar>
    );
  }

  render() {
    const {
      loader,
      monthStats,
      dayStats,
      weekDaysStats,
      productsStats,
      selectedShop,
    } = this.state;

    if (loader) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <MainWrapper>
          <Wrapper>
            <ChartsWrapper>
              <LineComposedChartComponent
                data={monthStats}
                title={__('Predaje v mesiacoch za rok')}
                subTitle={this.props.year}
                appLang={this.props.appLang}
                isStoreSelected={selectedShop !== 'all'}
              />
              <Space />
              <LineComposedChartComponent
                data={dayStats}
                title={__('Predaje počas týždna')}
                subTitle={this.currentWeekString()}
                appLang={this.props.appLang}
                isStoreSelected={selectedShop !== 'all'}
              />
            </ChartsWrapper>
            <Space />
            <ChartsWrapper>
              <BarChartStats
                data={weekDaysStats}
                title={__('Predaje v dňoch za rok')}
                subTitle={this.props.year}
                appLang={this.props.appLang}
              />
            </ChartsWrapper>
            <Space />
            <HeaderLabel>
              <div className="text">
                {__('TOP 10 najpredavanejších produktov')}
              </div>
            </HeaderLabel>
            <StatsTable small data={productsStats} showPager={false} />
          </Wrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

StoreSales.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(StoreSales);
