import { __e as __ } from '../Utils';

export default [
  {
    name: __('ID'),
    clickable: false,
    otherProps: {
      maxWidth: 300,
      minWidth: 250,
    },
  },
  {
    name: __('Meno zákazníka'),
    clickable: false,
    otherProps: {
      maxWidth: 150,
    },
  },
  {
    name: __('Text otázky'),
    clickable: false,
    otherProps: {
      minWidth: 300,
    },
  },
  {
    name: __('Počet odpovedí'),
    clickable: false,
    otherProps: {
      maxWidth: 130,
    },
  },
  {
    name: __('Dátum'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: {
      maxWidth: 150,
    },
  },
];
