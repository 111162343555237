import { __e as __ } from '../Utils';

export default [
  {
    name: __('Číslo zásielky'),
    otherProps: {
      maxWidth: 150,
    },
  },
  {
    name: __('Stav'),
    otherProps: {
      minWidth: 150,
    },
  },
  {
    name: __('Objednávka'),
    clickable: true,
    sorterValue: '_id',
    otherProps: {
      maxWidth: 120,
    },
  },
  {
    name: __('Príjemca'),
    clickable: true,
    sorterValue: 'customer',
  },
  {
    name: __('Dátum objednávky'),
    clickable: true,
    sorterValue: 'order_state',
  },
  {
    name: __(''),
    otherProps: {
      maxWidth: 120,
    },
  },
  {
    name: __(''),
    otherProps: {
      maxWidth: 80,
    },
  },
];
