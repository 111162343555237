import { __ } from '../Utils';

const campaignLabels = [
  {
    name: __('Akciová dodatočná zľava na všetko'),
    type: 'D',
  },
  {
    name: __('Kampaň na vybrané produkty'),
    type: 'F',
  },
  {
    name: __(
      'Dočasné zvýšenie základnej zľavy A pre zákazníka na vybrané produkty',
    ),
    type: 'K',
  },
  { name: __('Kampaň výrobok k objednávke za 1 cent / 1 €'), type: 'G' },
  { name: __('Kampaň kúp jeden výrobok a dostaneš iný so zľavou'), type: 'H' },
  { name: __('Kampaň výrobok k objednávke zadarmo'), type: 'L' },
  {
    name: __('Kampaň na vybrané produkty za minimálnu hodnotu, získaj zľavu'),
    type: 'I',
  },
  {
    name: __('Kampaň na vybrané produkty za minimálnu hodnotu, získaj produkt'),
    type: 'J',
  },
  {
    name: __('Kupónová kampaň'),
    type: 'M',
    onlyMarketingList: true,
  },
];

export default campaignLabels;
