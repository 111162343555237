import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Label from 'oxyrion-ui/lib/Label/Label';
import Input from 'oxyrion-ui/lib/Input';
import Button from 'oxyrion-ui/lib/Button';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';
import API2 from '../../API2';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${rem(24)};
  width: ${rem(300)};
`;

class CompanyEdit extends Component {
  state = {
    data: undefined,
  };

  handleChange(field, value) {
    let { data } = this.state;

    if (!data) {
      data = Object.assign({}, this.props.data || {});
    }

    data[field] = value;

    this.setState({ data });
  }

  async getFinstatData(no) {
    const { data } = this.state;
    try {
      const result = await API2.getBusinessInfo(this.props.firm, no);

      data.vat_no = result.customer.vat_nr;
      data.company_name = result.customer.full_name;
      data.city = result.customer.billing_address.city;
      data.street = result.customer.billing_address.street;
      data.postal_code = result.customer.billing_address.postal_code;
      data.country = result.customer.billing_address.country;

      this.setState({
        data,
      });
    } catch (e) {}
  }

  render() {
    const { data: propsData, onDismiss, onConfirm } = this.props;
    const { data: stateData } = this.state;

    const data = stateData || propsData;

    return (
      <Wrapper>
        <InputWrapper>
          <Label>{__('IČO')}</Label>
          <Input
            onChange={e => this.handleChange('company_no', e.target.value)}
            placeholder={__('IČO')}
            value={data.company_no}
            onBlur={e => this.getFinstatData(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('IČ DPH')}</Label>
          <Input
            onChange={e => this.handleChange('vat_no', e.target.value)}
            placeholder={__('IČ DPH')}
            value={data.vat_no}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Názov')}</Label>
          <Input
            onChange={e => this.handleChange('company_name', e.target.value)}
            placeholder={__('Názov')}
            value={data.company_name}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Mesto')}</Label>
          <Input
            onChange={e => this.handleChange('city', e.target.value)}
            placeholder={__('Mesto')}
            value={data.city}
          />
        </InputWrapper>
        <InputWrapper>
          <Label>{__('Ulica')}</Label>
          <Input
            onChange={e => this.handleChange('street', e.target.value)}
            placeholder={__('Ulica')}
            value={data.street}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('PSČ')}</Label>
          <Input
            onChange={e => this.handleChange('postal_code', e.target.value)}
            placeholder={__('PSČ')}
            value={data.postal_code}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Krajina')}</Label>
          <Input
            onChange={e => this.handleChange('country', e.target.value)}
            placeholder={__('Krajina')}
            value={data.country}
          />
        </InputWrapper>

        <Buttons>
          <Button danger onClick={() => onDismiss()}>
            {__('Zrušiť')}
          </Button>
          <Button onClick={() => onConfirm(data)} primary>
            {__('Uložiť')}
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

CompanyEdit.propTypes = {
  data: PropTypes.shape({}),
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

CompanyEdit.defaultProps = {
  data: {},
};

export default CompanyEdit;
