import React, { Component } from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import { __ } from '../../Utils';

const ShowMoreWrapper = styled.div`
  max-width: ${rem(200)};
  word-wrap: break-word;
  white-space: initial;
`;

const StandardWrapper = styled.div`
  text-overflow: ellipsis;
  max-width: ${rem(200)};
  white-space: nowrap;
  overflow: hidden;
`;

const Wrapper = styled.div``;

const ShowMoreLessButton = styled.div`
  color: ${({ theme }) => theme.color.primary};
  text-decoration: underline;
  :hover {
    cursor: pointer;
  }
`;

class ExtendableSummary extends Component {
  state = {
    showMore: false,
  };

  render() {
    const { text } = this.props;
    const { showMore } = this.state;

    if (showMore) {
      return (
        <Wrapper>
          <ShowMoreWrapper>{text}</ShowMoreWrapper>
          <ShowMoreLessButton
            onClick={() =>
              this.setState({
                showMore: false,
              })
            }
          >
            {__('Skryť viac')}
          </ShowMoreLessButton>
        </Wrapper>
      );
    }

    if (text && text.length > 35)
      return (
        <Wrapper>
          <StandardWrapper>{text}</StandardWrapper>
          <ShowMoreLessButton
            onClick={() =>
              this.setState({
                showMore: true,
              })
            }
          >
            {__('Zobraziť viac')}
          </ShowMoreLessButton>
        </Wrapper>
      );

    return <StandardWrapper>{text}</StandardWrapper>;
  }
}

ExtendableSummary.propTypes = {
  text: PropTypes.string,
};

ExtendableSummary.defaultProps = {
  text: '-',
};

export default ExtendableSummary;
