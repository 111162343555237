import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { Input, Textarea } from 'oxyrion-ui/lib';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import Label from 'oxyrion-ui/lib/Label/Label';

import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  formatPrice,
  FORMAT_HUMANE_DATETIME,
  getOrderEomStateLabel,
  getIdFromProps,
  getOrderStateLabel,
  getPaymentStateLabel,
  transledEomOrderState,
  validateEmail,
  __,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  FlexBox,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import DatePicker from '../../Components/DatePicker';
import AddressEdit from '../../Components/AddressEdit';
import CompanyEdit from '../../Components/CompanyEdit';
import ProductsTable from '../ProductsTable';
import OrderComments from '../OrderComments';
// import Courier123Packages from '../Courier123Packages';
import EmailLogs from '../EmailLogs';
import FileViewDownload from '../../Components/FileViewDownload';
import PacketaPackages from '../PacketaPackages';
import API2 from '../../API2';
import CustomEndpoints from '../../CustomEndpoints';

const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HoursSince = styled.div`
  margin-top: ${rem(8)};
  font-size: ${rem(12)};
  color: ${props => props.late && props.theme.color.danger};
`;

const Courier123Wrapper = styled.div`
  margin-top: ${rem(12)};
  min-height: ${rem(300)};
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const SmallHeaderLabel = styled(HeaderLabel)`
  font-size: ${rem(14)};
  justify-content: space-between;
`;

const ValueRow = styled(SmallHeaderLabel)`
  height: ${rem(30)};
  background: none;
  color: black;
`;

const HistoryWrapper = styled.div`
  font-size: ${rem(12)};
  width: 100%;

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      border-width: 2px;
      border-color: rgba(0, 0, 0, 0.05);
      border-style: solid;
      padding: 3px;
    }
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(12)};
  }

  .value {
    margin-right: ${rem(12)};
  }
`;

const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }

  .description-wrapper {
    margin-left: 12px;
    font-size: 14px;
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const AddressesInfo = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const InputWrapper = styled.div`
  margin-bottom: ${rem(24)};
  width: ${rem(250)};
`;

const ChangeDeliveryDataWrapper = styled.div`
  height: ${rem(270)};
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

const DescriptionWrapper = styled.div`
  padding: ${rem(12)};
  font-size: ${rem(12)};
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.m`
 flex-direction: row;
`};
`;

const SubWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.m`
  width: 50%;
`};
  padding: ${rem(12)};
`;

const DropZoneWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin: 16px;
`;

const AddPhotosButton = styled(Dropzone)`
  width: 400px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: ${rem(14)};
  color: #8e8e8e;
  font-weight: bold;
  text-align: center;
  :hover {
    text-decoration: underline;
  }

  padding: 16px;
  border-style: dashed;
  border-color: #8e8e8e;
  border-width: 2px;
`;

const FilesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding-left: 24px;
`;

const FileWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NameAndDateWrapper = styled.div`
  .date {
    font-size: ${rem(12)};
    color: #8b8989;
  }
`;

const ImageBigPreview = styled.img`
  position: absolute;
  z-index: 1000;
  bottom: 60px;
  border-radius: 8px;
  box-shadow: 0 ${rem(3)} ${rem(5)} 0 rgba(0, 0, 0, 0.16);
`;

const ImagesWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .big {
    position: absolute;
    display: none;
  }

  :hover {
    .big {
      display: block;
    }
  }
`;

const ImagePreview = styled.img`
  height: ${rem(50)};
  width: ${rem(50)};
  margin-right: ${rem(4)};
  margin-bottom: ${rem(4)};
  border-radius: ${rem(4)};
`;

const Delete = styled.div`
  cursor: pointer;
  margin-left: 16px;
  color: red;
  font-size: 12px;
  :hover {
    text-decoration: underline;
  }
`;

const baseURL = `${process.env.REACT_APP_IMG_DOMAIN}/images/500x500`;

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      sendOrderDateNotify: false,
      notifyCustomer: true,
      orderEmailConflictData: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  onPackageCreate() {
    this.fetchData();
  }

  async onAddAttachments(accepted, rejected) {
    if (rejected && rejected.length > 0) {
      this.setState({
        error: `${__(`Niektoré súbory sa nepodarilo nahrať`)}`,
      });
    }

    await Promise.all(
      accepted.map(item =>
        CustomEndpoints.postEomOrderFileAction(
          this.props.firm,
          getIdFromProps(this.props),
          {
            attachment: item,
          },
        ),
      ),
    );

    this.fetchData();
  }

  async onFileDelete(orderId, fullPath) {
    await API2.deleteEomOrderFileAction(
      this.props.firm,
      orderId,
      encodeURIComponent(fullPath),
    );

    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getEshopOrderAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      const baseInfo = [
        {
          label: __('Dátum objednania'),
          value: formatDate(moment(result.order_date), FORMAT_HUMANE_DATETIME),
        },
        {
          label: __('Meno zákazníka'),
          value: (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${result.customer._id}`}
            >
              {result.customer.display_name}
            </StyledA>
          ),
        },
        {
          label: __('Email'),
          value: result.customer.email,
        },
        {
          label: __('Vytvorená cez'),
          value: result.created_from,
        },
        {
          label: __('Tel. číslo'),
          value: result.customer.phone,
        },
        {
          label: __('Dátum dodania'),
          value: formatDate(moment(result.estimate_delivery_date)),
          button: {
            title: __('Editovať'),
            onClick: () =>
              this.setState({
                showEditDeliveryDate: true,
              }),
          },
        },
      ];

      const deliveryInfo = [
        {
          label: __('Platobné informácie'),
          value: '',
        },
        {
          label: __('Spôsob dodania'),
          value: result.delivery_type.label,
        },
        {
          label: __('Cena'),
          value: result.price_for_delivery
            ? formatPrice(result.price_for_delivery)
            : formatPrice({
                amount: 0,
                currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
              }),
        },
      ];

      let paymentConfirm = __('Nebola potrvrdená');

      if (result.payment && result.payment.user.display_name) {
        paymentConfirm = `${result.payment.user.display_name} - ${formatDate(
          moment(result.payment.confirmed_date),
          FORMAT_HUMANE_DATETIME,
        )}`;
      } else if (result.payment_state === 'paid') {
        paymentConfirm = __('Gopay - automaticky');
      }

      const paymentInfo = [
        {
          label: __('Dodacie informácie'),
          value: '',
        },
        {
          label: __('Spôsob platby'),
          value: result.payment_type.label,
        },
        {
          label: __('Stav platby'),
          value: getPaymentStateLabel(result.payment_state),
        },
        {
          label: __('Platbu potvrdil'),
          value: paymentConfirm,
        },
        {
          label: __('Cena'),
          value: result.price_for_payment
            ? formatPrice(result.price_for_payment)
            : formatPrice({
                amount: 0,
                currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
              }),
        },
      ];

      const billingAddress = {
        label: __('Fakturačná adresa'),
        button: {
          title: __('Editovať'),
          onClick: () =>
            this.setState({
              showEditBillingAddress: true,
            }),
        },
        values: [
          result.billing_address.contact_name,
          result.billing_address.street,
          `${result.billing_address.postal_code} ${result.billing_address.city}`,
          result.billing_address.country,
        ],
      };

      const deliveryAddress = {
        label: __('Dodacia adresa'),
        button: {
          title: __('Editovať'),
          onClick: () =>
            this.setState({
              showEditDeliveryAddress: true,
            }),
        },
        values: [
          result.delivery_address.contact_name,
          result.delivery_address.street,
          `${result.delivery_address.postal_code} ${result.delivery_address.city}`,
          result.delivery_address.country,
        ],
      };

      const companyInfo = result.company_data &&
        result.company_data.company_name && {
          label: __('Spoločnosť'),
          button: {
            title: __('Editovať'),
            onClick: () =>
              this.setState({
                showEditCompany: true,
              }),
          },
          values: [
            result.company_data.company_name,
            `${result.company_data.street}, ${result.company_data.city}, ${result.company_data.postal_code}`,
            `${__('IČO')}: ${result.company_data.company_no}`,
            `${__('IČ DPH')}: ${result.company_data.vat_no}`,
          ],
        };

      const internal_note = result.internal_note || '';

      const documentInfo = [
        {
          label: __('Faktúra'),
          value: (
            <FileViewDownload
              name={result.invoice_id ? result.invoice_id : __('Faktúra')}
              type={'application/pdf'}
              history={this.props.history}
              downloadFunction={async () => {
                console.log(result);
                if (result.invoice_id) {
                  return API2.getSalesInvoicePdfAction(
                    this.props.firm,
                    result.invoice_id,
                  );
                } else {
                  if (!result.shipment_id) {
                    // first generate shipment_id
                    await this.handleGenerateShipmentInvoice(result._id);
                  }

                  const invoiceId = await this.handleGenerateInvoice(
                    result._id,
                  );

                  return API2.getSalesInvoicePdfAction(
                    this.props.firm,
                    invoiceId,
                  );
                }
              }}
              error={e => {
                this.setState({ error: e });
              }}
            />
          ),
        },
        {
          label: __('Dodací list'),
          value: (
            <FileViewDownload
              name={result.shipment_id ? result.shipment_id : __('Dodací list')}
              type={'application/pdf'}
              history={this.props.history}
              downloadFunction={async () => {
                if (result.shipment_id) {
                  return API2.getSalesShipmentPdfAction(
                    this.props.firm,
                    result.shipment_id,
                  );
                } else {
                  const shipmentId = await this.handleGenerateShipmentInvoice(
                    result._id,
                  );

                  return API2.getSalesShipmentPdfAction(
                    this.props.firm,
                    shipmentId,
                  );
                }
              }}
              error={e => {
                this.setState({ error: e });
              }}
            />
          ),
        },
      ];

      this.setState({
        loading: false,
        submitPaymentLaoding: false,
        internalNoteSaving: false,
        data: Object.assign(result, {
          baseInfo,
          deliveryInfo,
          paymentInfo,
          billingAddress,
          deliveryAddress,
          companyInfo,
          documentInfo,
          internal_note,
        }),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  async handleGenerateInvoice(orderId) {
    const { invoiceId } = await API2.postInvoiceOrderAction(
      this.props.firm,
      orderId,
    );

    this.fetchData();

    return invoiceId;
  }

  async handleGenerateShipmentInvoice(orderId) {
    const { shipmentId } = await API2.postShipmentInvoiceOrderAction(
      this.props.firm,
      orderId,
    );

    this.fetchData();

    return shipmentId;
  }

  handleEstimateDeliveryDate(e) {
    const { data } = this.state;
    data.estimate_delivery_date = e;
    this.setState({ data });
  }

  handleInternalNote(value) {
    const { data } = this.state;
    data.internal_note = value;
    this.setState({ data });
  }

  finishOrder() {
    const { data } = this.state;

    try {
      this.setState({
        showFinishOrderLoading: true,
      });

      // api put

      data.order_state = 'Sent';

      // this.fetchData();

      this.setState({
        showFinishOrderDialog: false,
        showFinishOrderLoading: false,
        success: __('Objednávka bola úspešne odoslaná'),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo odoslať'),
        showFinishOrderDialog: false,
        showFinishOrderLoading: false,
      });
    }
  }

  cancelWarehouse() {
    const { data } = this.state;

    try {
      this.setState({
        showCancelWarehouseLoading: true,
      });

      // api put

      data.order_state = 'Open';

      // this.fetchData();

      this.setState({
        showCancelWarehouseLoading: false,
        showCancelWarehouseDialog: false,
        success: __('Objednávka bola úspešne vrátená späť zo skladu'),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo vrátiť zo skladu'),
        showCancelWarehouseLoading: false,
        showCancelWarehouseDialog: false,
      });
    }
  }

  sendOrderToWarehouse() {
    const { data } = this.state;

    try {
      this.setState({
        showSendOrderToWarehouseLoading: true,
      });

      // api put

      data.order_state = 'Pending_Prepayment';

      // this.fetchData();

      this.setState({
        showSendOrderToWarehouseLoading: false,
        showSendOrderToWarehouseDialog: false,
        data,
        success: __('Objednávka bola úspešne odoslaná do skladu'),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo odoslať do skladu'),
        showSendOrderToWarehouseLoading: false,
        showSendOrderToWarehouseDialog: false,
      });
    }
  }

  sendOrderEmail() {
    try {
      this.setState({
        sendOrderEmailLoading: true,
      });

      // api put

      this.fetchData();

      this.setState({
        sendOrderEmailLoading: false,
        success: __('Email úspešne odoslaný'),
      });
    } catch (e) {
      this.setState({
        error: __('Email sa nepodarilo odoslať'),
        sendOrderEmailLoading: false,
      });
    }
  }

  async cancelOrder() {
    try {
      this.setState({
        cancelOrderLoading: true,
      });

      await API2.putEshopOrderStateAction(
        this.props.firm,
        this.state.data._id,
        {
          body: {
            new_eom_state: 'canceled',
            notifyCustomer: this.state.notifyCustomer,
          },
        },
      );

      this.fetchData();

      this.setState({
        showCancelOrderDialog: false,
        cancelOrderLoading: false,
        success: __('Objednávka bola úspešne stornová'),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo stornovať'),
        cancelOrderLoading: false,
        showCancelOrderDialog: false,
      });
    }
  }

  async changeEomState(newEomState) {
    try {
      this.setState({
        changeOrderStateLoading: true,
      });

      await API2.putEshopOrderStateAction(
        this.props.firm,
        this.state.data._id,
        {
          body: {
            new_eom_state: newEomState,
            notifyCustomer:
              newEomState !== 'finished' ? this.state.notifyCustomer : false,
          },
        },
      );

      this.fetchData();

      this.setState({
        showChangeOrderStateDialog: false,
        changeOrderStateLoading: false,
        success: __('Stav objednávky bol úspešne zmenený'),
      });
    } catch (e) {
      this.setState({
        showChangeOrderStateDialog: false,
        changeOrderStateLoading: false,
        error: __('Stav objednávky sa nepodarilo zmeniť'),
      });
    }
  }

  async saveBillingAddress(d) {
    try {
      this.setState({
        editBillingAddressLoading: true,
      });

      await API2.putEshopOrderBillingAddressAction(
        this.props.firm,
        getIdFromProps(this.props),
        {
          body: d,
        },
      );

      await this.fetchData();

      this.setState({
        editBillingAddressLoading: false,
        showEditBillingAddress: false,
        success: __('Fakturačná adresa úspešne zmenená'),
      });
    } catch (e) {
      this.setState({
        error: __('Fakturačná adresu sa nepodarilo zmeniť'),
        editBillingAddressLoading: false,
        showEditBillingAddress: false,
      });
    }
  }

  saveCompanyInfo(d) {
    try {
      this.setState({
        editCompanyLoading: true,
      });

      // api put

      this.fetchData();

      this.setState({
        editCompanyLoading: false,
        success: __('Spoločnosť úspešne zmenená'),
      });
    } catch (e) {
      this.setState({
        error: __('Spoločnosť sa nepodarilo zmeniť'),
        editCompanyLoading: false,
      });
    }
  }

  async saveDeliveryAddress(d) {
    try {
      this.setState({
        editDeliveryAddressLoading: true,
      });

      await API2.putEshopOrderDeliveryAddressAction(
        this.props.firm,
        getIdFromProps(this.props),
        {
          body: d,
        },
      );

      await this.fetchData();

      this.setState({
        editDeliveryAddressLoading: false,
        showEditDeliveryAddress: false,
        success: __('Dodacia adresa úspešne zmenená'),
      });
    } catch (e) {
      this.setState({
        error: __('Dodaciu adresu sa nepodarilo zmeniť'),
        editDeliveryAddressLoading: false,
        showEditDeliveryAddress: false,
      });
    }
  }

  async changeDeliveryDate() {
    const { data, sendOrderDateNotify } = this.state;

    try {
      this.setState({
        editDeliveryDateLoading: true,
      });

      await API2.putEshopOrderDeliveryDateAction(
        this.props.firm,
        getIdFromProps(this.props),
        {
          body: {
            estimate_delivery_date: new Date(data.estimate_delivery_date),
            notify_customer: sendOrderDateNotify,
          },
        },
      );

      await this.fetchData();

      this.setState({
        editDeliveryDateLoading: false,
        showEditDeliveryDate: false,
      });
    } catch (e) {
      this.setState({
        editDeliveryDateError: __('Dátum dodania sa nepodarilo uložiť'),
        editDeliveryDateLoading: false,
        showEditDeliveryDate: false,
      });
    }
  }

  async submitPayment() {
    try {
      this.setState({
        submitPaymentLaoding: true,
      });

      await API2.putEshopOrderPaymentConfirmAction(
        this.props.firm,
        this.state.data._id,
      );

      this.fetchData();
    } catch (e) {
      this.setState({
        editDeliveryDateError: __('Platbu sa nepodarilo potvrdiť'),
        submitPaymentLaoding: false,
      });
    }
  }

  async saveInternalNote() {
    const { data } = this.state;

    try {
      this.setState({
        internalNoteSaving: true,
      });

      await API2.putEshopOrderInternalNoteAction(
        this.props.firm,
        getIdFromProps(this.props),
        {
          body: {
            internal_note: data.internal_note,
          },
        },
      );

      await this.fetchData();

      this.setState({
        internalNoteSaving: false,
        success: __('Poznámka úspešne uložená'),
      });
    } catch (e) {
      this.setState({
        error: __('Poznámku sa nepodarilo uložiť'),
        internalNoteSaving: false,
      });
    }
  }

  backToOrders() {
    this.props.history.push('../orders');
  }

  async sendOrderConfirmEmail(forced = false) {
    this.setState({
      orderEmailSendLoading: true,
    });

    if (!validateEmail(this.state.data.customer.email)) {
      this.setState({
        error: __('Email nie je validný'),
        orderEmailSendLoading: false,
      });

      return;
    }

    try {
      await API2.sendEshopOrderConfirmationEmailAction(
        this.props.firm,
        this.state.data._id,
        {
          forced,
          email: this.state.data.customer.email,
        },
      );

      this.setState({
        orderEmailSendLoading: false,
        success: __('Email bol úspešne odoslaný'),
        orderEmailConflictData: null,
      });
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({
          orderEmailConflictData: e.details.payload,
          orderEmailSendLoading: false,
        });
      } else {
        this.setState({
          error: __('Email sa nepodarilo odoslať'),
          orderEmailSendLoading: false,
        });
      }
    }
  }

  handleEmailChange(value) {
    const { data } = this.state;

    data.customer.email = value;

    this.setState({
      data,
    });
  }

  formatDiscount(discount, type) {
    if (type === 'discount') {
      return ` ${discount} % ${__('zľava')}`;
    }

    return formatPrice({
      amount: discount,
      currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
    });
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return <ControllBar history={history} name={data._id} />;
  }

  renderButtonsByState() {
    const { data } = this.state;

    const backButton = (
      <div className="button-wrapper">
        <Button small onClick={() => this.backToOrders()} primary>
          {__('Späť')}
        </Button>
      </div>
    );

    const sendOrderEmailButton = (
      <div className="button-wrapper">
        <div className="inpit-wrapper">
          <Input
            value={data.customer.email}
            onChange={e => this.handleEmailChange(e.target.value)}
          />
        </div>
        <Button
          loading={this.state.orderEmailSendLoading}
          small
          onClick={() => this.sendOrderConfirmEmail()}
          primary
        >
          {__('Odoslať email s potvrdením objednávky')}
        </Button>
      </div>
    );

    return (
      <React.Fragment>
        {backButton}
        <div className="righ">{sendOrderEmailButton}</div>
      </React.Fragment>
    );
  }

  renderEomButtonsByState() {
    const { data } = this.state;
    const stornoButton = (
      <div className="button-wrapper">
        <Button
          onClick={() =>
            this.setState({
              showCancelOrderDialog: true,
            })
          }
          danger
        >
          {__('Storno')}
        </Button>
      </div>
    );

    const hoursSinceCreated = moment().diff(data.order_date, 'hours');
    const changeToPreparingButton = (
      <div className="button-wrapper">
        <Button
          onClick={() =>
            this.setState({ showChangeOrderStateDialog: 'preparing' })
          }
          primary
        >
          {__('Prevziať objednávku')}
        </Button>
        <HoursSince late={hoursSinceCreated > 1}>
          {__('Vytvorená pred ')}
          {hoursSinceCreated}
          {__(' h')}
        </HoursSince>
      </div>
    );

    const changeToReadyToPickUpButton = (
      <div className="button-wrapper">
        <Button
          onClick={() =>
            this.setState({ showChangeOrderStateDialog: 'ready_for_pickup' })
          }
          primary
        >
          {__('Potvrdiť pripravenosť objednávky')}
        </Button>
      </div>
    );

    const changeToFinishedButton = (
      <div className="button-wrapper">
        <Button
          onClick={() =>
            this.setState({ showChangeOrderStateDialog: 'finished' })
          }
          primary
        >
          {data.delivery_type.name === 'DAP'
            ? __('Potvrdiť dodanie zásielky')
            : __('Potvrdiť prevziatie zásielky')}
        </Button>
      </div>
    );

    const sendOrderEmailButton = (
      <div className="button-wrapper">
        <div className="inpit-wrapper">
          <Input
            value={data.customer.email}
            onChange={e => this.handleEmailChange(e.target.value)}
          />
        </div>
        <Button
          loading={this.state.orderEmailSendLoading}
          small
          onClick={() => this.sendOrderConfirmEmail()}
          primary
        >
          {__('Odoslať email s potvrdením objednávky')}
        </Button>
      </div>
    );

    const eomState = data.eom_state;

    let rightButtons = [];

    if (eomState === 'new') {
      rightButtons = [
        changeToPreparingButton,
        stornoButton,
        sendOrderEmailButton,
      ];
    } else if (eomState === 'preparing' && data.delivery_type.name !== 'DAP') {
      rightButtons = [
        changeToReadyToPickUpButton,
        stornoButton,
        sendOrderEmailButton,
      ];
    } else if (eomState === 'preparing' && data.delivery_type.name === 'DAP') {
      rightButtons = [stornoButton, sendOrderEmailButton];
    } else if (eomState === 'ready_for_pickup' || eomState === 'in_delivery') {
      rightButtons = [
        changeToFinishedButton,
        stornoButton,
        sendOrderEmailButton,
      ];
    }

    return (
      <React.Fragment>
        <div className="right">{rightButtons}</div>
      </React.Fragment>
    );
  }

  renderRow(i, disabledEditButtons) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && !disabledEditButtons && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }

  renderGroup(group, disabledEditButtons) {
    return (
      <div className="group">
        <div className="row">
          <div className="label">{group.label}</div>
          {group.button && !disabledEditButtons && (
            <div className="button" onClick={() => group.button.onClick()}>
              {group.button.title}
            </div>
          )}
        </div>
        {group.values.map(v => (
          <div className="row">
            <div className="value">{v}</div>
          </div>
        ))}
      </div>
    );
  }

  renderConfirmDialogs() {
    const { data, sendOrderDateNotify, notifyCustomer } = this.state;

    // zobrazenie
    const {
      showEditDeliveryDate,
      showEditBillingAddress,
      showEditDeliveryAddress,
      showEditCompany,
      showCancelOrderDialog,
      showSendEmailDialog,
      showSendOrderToWarehouseDialog,
      showCancelWarehouseDialog,
      showFinishOrderDialog,
      showChangeOrderStateDialog,
    } = this.state;

    // loadingy
    const {
      editDeliveryDateLoading,
      editBillingAddressLoading,
      editDeliveryAddressLoading,
      editCompanyLoading,
      cancelOrderLoading,
      sendOrderEmailLoading,
      showSendOrderToWarehouseLoading,
      showCancelWarehouseLoading,
      showFinishOrderLoading,
      orderEmailConflictData,
      orderEmailSendLoading,
      changeOrderStateLoading,
    } = this.state;

    // errory
    const { editDeliveryDateError } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={showFinishOrderDialog}
          showSendOrderToWarehouseDialog
          onClose={() => this.setState({ showFinishOrderDialog: false })}
          small
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showFinishOrderDialog: false })}
          message={__('Odoslať objednávku zákazníkovy?')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showFinishOrderDialog: false })}
            onDismiss={() => this.finishOrder()}
            loading={showFinishOrderLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Odoslať')}
            message={__('Naozaj chcete odoslať objednávku zákazníkovy?')}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showCancelWarehouseDialog}
          showSendOrderToWarehouseDialog
          onClose={() => this.setState({ showCancelWarehouseDialog: false })}
          small
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showCancelWarehouseDialog: false })}
          message={__('Vrátiť objednávku zo skladu?')}
        >
          <ConfirmDialog
            onConfirm={() =>
              this.setState({ showCancelWarehouseDialog: false })
            }
            onDismiss={() => this.cancelWarehouse()}
            loading={showCancelWarehouseLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Vrátiť')}
            message={__(
              'Naozaj chcete vrátiť objednávku z skladu? Po vrátení bude možné upravovať položky objednávky.',
            )}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={orderEmailConflictData}
          onClose={() => this.setState({ orderEmailConflictData: null })}
          small
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ orderEmailConflictData: null })}
          message={__('Odoslať email zákazníkovi?')}
        >
          {orderEmailConflictData && (
            <ConfirmDialog
              onConfirm={() => this.setState({ orderEmailConflictData: null })}
              onDismiss={() => this.sendOrderConfirmEmail(true)}
              dismissLoading={orderEmailSendLoading}
              confirmMessage={__('Zrušiť')}
              dismissMessage={__('Odoslať znovu')}
              message={`Email bol zákazníkovi úspešne odoslaný ${
                orderEmailConflictData.count
              } krát. Naposledy ${formatDate(
                moment(orderEmailConflictData.last.created_date),
                FORMAT_HUMANE_DATETIME,
              )} na adresy: ${orderEmailConflictData.last.addresses
                .map(a => a.to)
                .join(', ')}. Status: ${orderEmailConflictData.last.type}`}
            />
          )}
        </PopUpWrapper>

        <PopUpWrapper
          display={showSendOrderToWarehouseDialog}
          showSendOrderToWarehouseDialog
          onClose={() =>
            this.setState({ showSendOrderToWarehouseDialog: false })
          }
          small
          statusName="showDeleteActivity"
          onEscClose={() =>
            this.setState({ showSendOrderToWarehouseDialog: false })
          }
          message={__('Odoslať objednávku do skladu?')}
        >
          <ConfirmDialog
            onConfirm={() =>
              this.setState({ showSendOrderToWarehouseDialog: false })
            }
            onDismiss={() => this.sendOrderToWarehouse()}
            loading={showSendOrderToWarehouseLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Odoslať')}
            message={__(
              'Naozaj chcete odoslať objednávku do skladu? Zákazníkovy bude odoslaná notifikácia “Objednávka sa pripravuje”. Sklad začne objednávku baliť.',
            )}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showSendEmailDialog}
          small
          onClose={() => this.setState({ showSendEmailDialog: false })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showSendEmailDialog: false })}
          message={__('Odoslať email?')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showSendEmailDialog: false })}
            onDismiss={() => this.sendOrderEmail()}
            loading={sendOrderEmailLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Odoslať')}
            message={__(
              'Naozaj chcete odoslať email o potvrdení objednávky zákazníkovy?',
            )}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showCancelOrderDialog}
          small
          onClose={() => this.setState({ showCancelOrderDialog: false })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showCancelOrderDialog: false })}
          message={__('Stornovať objednávku?')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showCancelOrderDialog: false })}
            onDismiss={() => this.cancelOrder()}
            loading={cancelOrderLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Stornovať')}
            message={__('Naozaj chcete stornovať túto objednávku?')}
            additionalContent={
              <FlexBox>
                <LabeledCheckbox
                  checked={notifyCustomer}
                  labelText={__('Notifikovať zákazníka')}
                  onChange={e =>
                    this.setState({
                      notifyCustomer: e.target.checked,
                    })
                  }
                />
              </FlexBox>
            }
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showChangeOrderStateDialog}
          small
          onClose={() => this.setState({ showChangeOrderStateDialog: false })}
          statusName="showChangeOrderState"
          onEscClose={() =>
            this.setState({ showChangeOrderStateDialog: false })
          }
          message={__('Zmena stavu objednávky EOM')}
        >
          <ConfirmDialog
            onDismiss={() => this.changeEomState(showChangeOrderStateDialog)}
            onConfirm={() =>
              this.setState({ showChangeOrderStateDialog: false })
            }
            dismissLoading={changeOrderStateLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={transledEomOrderState(showChangeOrderStateDialog)}
            message={`${__(
              'Naozaj chcete zmeniť stav objednávky z',
            )} "${transledEomOrderState(
              data.eom_state,
            )}" na "${transledEomOrderState(showChangeOrderStateDialog)}"?`}
            additionalContent={
              showChangeOrderStateDialog !== 'finished' && (
                <FlexBox>
                  <LabeledCheckbox
                    checked={notifyCustomer}
                    labelText={__('Notifikovať zákazníka')}
                    onChange={e =>
                      this.setState({
                        notifyCustomer: e.target.checked,
                      })
                    }
                  />
                </FlexBox>
              )
            }
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showEditBillingAddress}
          small
          customWidth={300}
          onClose={() => this.setState({ showEditBillingAddress: false })}
          statusName="showEditBillingAddress"
          onEscClose={() => this.setState({ showEditBillingAddress: false })}
          message={__('Fakturačná adresa')}
        >
          <AddressEdit
            data={data.billing_address}
            saveLoading={editBillingAddressLoading}
            onDismiss={() => this.setState({ showEditBillingAddress: false })}
            onConfirm={d => this.saveBillingAddress(d)}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showEditDeliveryAddress}
          small
          customWidth={300}
          onClose={() => this.setState({ showEditDeliveryAddress: false })}
          statusName="showEditDeliveryAddress"
          onEscClose={() => this.setState({ showEditDeliveryAddress: false })}
          message={__('Dodacia adresa')}
        >
          <AddressEdit
            data={data.delivery_address}
            saveLoading={editDeliveryAddressLoading}
            onDismiss={() => this.setState({ showEditDeliveryAddress: false })}
            onConfirm={d => this.saveDeliveryAddress(d)}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showEditCompany}
          small
          customWidth={300}
          onClose={() => this.setState({ showEditCompany: false })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showEditCompany: false })}
          message={__('Spoločnosť')}
        >
          <CompanyEdit
            data={data.company_data}
            saveLoading={editCompanyLoading}
            onDismiss={() => this.setState({ showEditCompany: false })}
            onConfirm={d => this.saveCompanyInfo(d)}
            firm={this.props.firm}
          />
        </PopUpWrapper>

        <PopUpWrapper
          display={showEditDeliveryDate}
          small
          customWidth={500}
          onClose={() => this.setState({ showEditDeliveryDate: false })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showEditDeliveryDate: false })}
          message={__('Zmena dátumu dodania')}
        >
          <ConfirmDialog
            onDismiss={() => this.changeDeliveryDate()}
            onConfirm={() => this.setState({ showEditDeliveryDate: false })}
            error={editDeliveryDateError}
            dismissLoading={editDeliveryDateLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Zmeniť')}
            additionalContent={
              <ChangeDeliveryDataWrapper>
                <InputWrapper>
                  <Label>{__('Dátum dodania')}</Label>
                  <DatePicker
                    placeholderText={__('Vyberte dátum dodania')}
                    selected={moment(data.estimate_delivery_date)}
                    minDate={moment(new Date())}
                    onChange={e => {
                      this.handleEstimateDeliveryDate(e);
                    }}
                  />
                </InputWrapper>
                <LabeledCheckbox
                  checked={sendOrderDateNotify}
                  labelText={__('Notifikovať zákazníka')}
                  onChange={e =>
                    this.setState({
                      sendOrderDateNotify: e.target.checked,
                    })
                  }
                />
              </ChangeDeliveryDataWrapper>
            }
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }

  render() {
    const {
      data,
      loading,
      error,
      success,
      submitPaymentLaoding,
      internalNoteSaving,
    } = this.state;

    const disabledEditButtons = false;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    console.log(data);

    return (
      <React.Fragment>
        {this.renderConfirmDialogs()}
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          {data.eom_state ? (
            <React.Fragment>
              <HeaderLabel>
                <div className="text">{__('Zmena stavu objednávky')}</div>
              </HeaderLabel>

              <div className="buttons-wrapper">
                {getOrderEomStateLabel(data.eom_state, true)}

                {this.renderEomButtonsByState(data.eom_state)}
              </div>
              <HistoryWrapper>
                <table>
                  <tbody>
                    {data.eom_state_history &&
                      data.eom_state_history.map(stateChange => {
                        return (
                          <tr>
                            <td className="label">
                              {`${transledEomOrderState(
                                stateChange.to_state,
                              )} `}
                            </td>
                            <td className="value">
                              {`${(stateChange.changed_by &&
                                stateChange.changed_by.display_name) ||
                                ''}`}
                            </td>
                            <td className="value">{`${formatDate(
                              moment(stateChange.changed_date),
                              FORMAT_HUMANE_DATETIME,
                            )}`}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </HistoryWrapper>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="buttons-wrapper">
                {this.renderButtonsByState(data.order_state)}
              </div>

              <HeaderLabel>
                <div className="text">{__('Stav objednávky')}</div>
              </HeaderLabel>

              <div className="buttons-wrapper">
                {getOrderStateLabel(data.order_state)}
              </div>
            </React.Fragment>
          )}
          <HeaderLabel>
            <div className="text">{__('Objednávka a informácie o účte')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.baseInfo &&
              data.baseInfo.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Adresy')}</div>
          </HeaderLabel>
          <AddressesInfo>
            {data.billingAddress &&
              this.renderGroup(data.billingAddress, disabledEditButtons)}
            {data.deliveryAddress &&
              this.renderGroup(data.deliveryAddress, disabledEditButtons)}
            {data.companyInfo &&
              data.companyInfo.values &&
              this.renderGroup(data.companyInfo, disabledEditButtons)}
          </AddressesInfo>
          <HeaderLabel>
            <div className="text">{__('Dodanie a platba')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.paymentInfo && (
              <div>
                {data.paymentInfo.map(i =>
                  this.renderRow(i, disabledEditButtons),
                )}
                {data.payment_state !== 'paid' && (
                  <React.Fragment>
                    <div className="row">
                      <Button
                        onClick={() => this.submitPayment()}
                        small
                        primary
                        loading={submitPaymentLaoding}
                        style={{ marginTop: rem(12) }}
                      >
                        {__('Potvrdiť prijatie platby')}
                      </Button>
                    </div>
                    {/* <div className="row">
                      <div
                        className="button"
                        style={{ marginTop: rem(12), marginLeft: 0 }}
                      >
                        {__('Nahrať faktúru')}
                      </div>
                    </div> */}
                  </React.Fragment>
                )}
              </div>
            )}
            {data.deliveryInfo && (
              <div>
                {data.deliveryInfo.map(i =>
                  this.renderRow(i, disabledEditButtons),
                )}
              </div>
            )}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Použité kupóny')}</div>
          </HeaderLabel>
          <InfoWrapper>
            <div className="vouchers-wrapper">
              {data.vouchers
                ? data.vouchers.map(v => {
                    return (
                      <div className="voucher-wrapper">
                        <b>{v.code}</b>
                        {` - ${
                          v.type === 'free_delivery'
                            ? __('Doprava zdarma')
                            : this.formatDiscount(v.discount, v.type)
                        } `}
                      </div>
                    );
                  })
                : __('Bez kupónu')}
            </div>
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Poznámka k objednávke')}</div>
          </HeaderLabel>
          <InfoWrapper>
            <div className="description-wrapper">
              {data.description || __('Bez poznámky')}
            </div>
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Interná poznámka k objednávke')}</div>
          </HeaderLabel>
          <InfoWrapper>
            <div className="description-wrapper">
              <Textarea
                style={{
                  width: rem(800),
                  height: rem(80),
                }}
                value={data.internal_note || ''}
                placeholder={`${__('Pridajte nový komentár k otázke')}`}
                onChange={e => this.handleInternalNote(e.target.value)}
              />
              <Button
                onClick={() => this.saveInternalNote()}
                small
                primary
                loading={internalNoteSaving}
              >
                {__('Uložiť internú poznámku')}
              </Button>
            </div>
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Doklady')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.documentInfo &&
              data.documentInfo.map(i =>
                this.renderRow(i, disabledEditButtons),
              )}
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Položky')}</div>
          </HeaderLabel>
          <ProductsTable
            small
            products={data.products || []}
            showPager={false}
          />

          <DescriptionWrapper>
            <Label>{__('Popis k objednávke')}</Label>
            {data.summary || __('Bez popisu')}
          </DescriptionWrapper>
          <HeaderLabel>
            <div className="text">{__('Sumár')}</div>
          </HeaderLabel>
          <SummaryWrapper>
            <SubWrapper>
              <Label>{__('Zápisy k objednávke')}</Label>
              <OrderComments
                onSuccces={() =>
                  this.setState({
                    success: __('Komentár bol pridaný k objednávke'),
                  })
                }
                onError={() =>
                  this.setState({
                    error: __('Komentár sa nepodarilo pridať k objednávke'),
                  })
                }
                id={getIdFromProps(this.props)}
                firm={this.props.firm}
                orderState={data.order_state}
              />
            </SubWrapper>

            <SubWrapper>
              <Label>{__('Sumár')}</Label>
              <SmallHeaderLabel>
                <div className="text">{__('Celková cena bez DPH')}</div>
                <div className="value">{formatPrice(data.total_price)}</div>
              </SmallHeaderLabel>
              {data.price_for_delivery && (
                <ValueRow>
                  <div className="text">{__('Cena za dopravu')}</div>
                  <div className="value">
                    {formatPrice(data.price_for_delivery)}
                  </div>
                </ValueRow>
              )}
              {data.price_for_payment && (
                <ValueRow>
                  <div className="text">{__('Cena za spôsob platby')}</div>
                  <div className="value">
                    {formatPrice(data.price_for_payment)}
                  </div>
                </ValueRow>
              )}
              <ValueRow>
                <div className="text">{__('DPH')}</div>
                <div className="value">
                  {formatPrice({
                    amount:
                      data.total_price_vat.amount - data.total_price.amount,
                    currency: data.total_price.currency,
                  })}
                </div>
              </ValueRow>
              <SmallHeaderLabel>
                <div className="text">{__('Celková cena')}</div>
                <div className="value">{formatPrice(data.total_price_vat)}</div>
              </SmallHeaderLabel>
            </SubWrapper>
          </SummaryWrapper>

          {data.delivery_type && data.delivery_type.name === 'DAP' && (
            <React.Fragment>
              {/* <Courier123Wrapper> // zakomentovane kedze su momentalne out
                <Courier123Packages
                  orderId={getIdFromProps(this.props)}
                  firm={this.props.firm}
                  // history={this.props.history
                  hideHistory
                  showPager={false}
                  showSearch={false}
                  controlBarColor="#f9f1f7"
                  textColor="#A448A3"
                  small
                  packageCreateCallback={() => this.onPackageCreate()}
                  disabled={
                    data.eom_state !== 'preparing' &&
                    data.eom_state !== 'in_delivery'
                  }
                />
              </Courier123Wrapper> */}
              <Courier123Wrapper>
                <PacketaPackages
                  orderId={getIdFromProps(this.props)}
                  firm={this.props.firm}
                  // history={this.props.history
                  hideHistory
                  showPager={false}
                  showSearch={false}
                  controlBarColor="#f9f1f7"
                  textColor="#A448A3"
                  small
                  packageCreateCallback={() => this.onPackageCreate()}
                  disabled={
                    data.eom_state !== 'preparing' &&
                    data.eom_state !== 'in_delivery'
                  }
                />
              </Courier123Wrapper>
            </React.Fragment>
          )}

          <HeaderLabel>
            <div className="text">{__('Fotky balíkov')}</div>
          </HeaderLabel>

          <DropZoneWrapper>
            <AddPhotosButton
              onDrop={(accepted, rejected) =>
                this.onAddAttachments(accepted, rejected)
              }
            >
              {__('Pretiahnite alebo vyberte súbor')}
            </AddPhotosButton>
            {data.files && data.files.length ? (
              <FilesWrapper>
                {data.files.map(a => (
                  <FileWrapper key={a.fullPath}>
                    <ImagesWrapper>
                      <ImagePreview src={`${baseURL}/${a.fullPath}`} />

                      <div className="big">
                        <ImageBigPreview src={`${baseURL}/${a.fullPath}`} />
                      </div>
                    </ImagesWrapper>
                    <NameAndDateWrapper>
                      <FileViewDownload
                        customWidth={350}
                        name={a.name}
                        type={a.mimeType}
                        fullPath={a.fullPath}
                        history={this.props.history}
                        downloadFunction={async () => {
                          return API2.getEomOrderFileDownloadId(
                            this.props.firm,
                            data._id,
                            encodeURIComponent(a.fullPath),
                          );
                        }}
                        error={e => {
                          this.setState({ error: e });
                        }}
                      />
                      <div className="date">
                        {formatDate(moment(a.dateTime), FORMAT_HUMANE_DATETIME)}
                      </div>
                    </NameAndDateWrapper>
                    <Delete
                      onClick={() => this.onFileDelete(data._id, a.fullPath)}
                      className="delete"
                    >
                      {__('Odstrániť')}
                    </Delete>
                  </FileWrapper>
                ))}
              </FilesWrapper>
            ) : (
              <div />
            )}
          </DropZoneWrapper>

          <Courier123Wrapper>
            <EmailLogs
              orderId={getIdFromProps(this.props)}
              firm={this.props.firm}
              // history={this.props.history
              hideHistory
              showPager={false}
              showSearch={false}
              controlBarColor="#f9f1f7"
              textColor="#A448A3"
              small
            />
          </Courier123Wrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

OrderDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrderDetail);
