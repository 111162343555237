import { __e as __ } from '../Utils';

export default [
  {
    name: __('Číslo objednávky'),
    clickable: true,
    sorterValue: 'order_id',
    otherProps: {
      maxWidth: 150,
    },
  },
  {
    name: __('Vytvoril'),
    clickable: false,
  },
  {
    name: __('Text'),
    clickable: false,
    otherProps: {
      minWidth: 300,
    },
  },
  {
    name: __('Notifikovaný emailom'),
    clickable: false,
    otherProps: {
      maxWidth: 130,
    },
  },
  {
    name: __('Správa v eshope'),
    clickable: false,
    otherProps: {
      maxWidth: 130,
    },
  },
  {
    name: __('Dátum a čas'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: {
      maxWidth: 130,
    },
  },
];
