import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Label from 'oxyrion-ui/lib/Label/Label';
import Input from 'oxyrion-ui/lib/Input';
import Button from 'oxyrion-ui/lib/Button';
import { formatPrice, __ } from '../../Utils';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  margin: ${rem(24)};
  width: ${rem(400)};
`;

const TotalPrice = styled.div`
  font-size: ${rem(24)};
  font-weight: bold;
  text-align: start;
`;

class ProductEdit extends Component {
  state = {
    data: {},
  };

  componentWillMount() {
    this.setState({
      data: Object.assign({}, this.props.data),
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      data: Object.assign({}, nextProps.data),
    });
  }

  handleChange(field, value) {
    const { data } = this.state;

    if (field === 'price') {
      data.price.amount = Number(value);
    } else if (field === 'quantity') {
      data.quantity = Number(value);
    } else if (field === 'discount_percent') {
      const v = Number(value) > 100 ? 100 : Number(value);
      data.discount_percent = v;

      const actualPrice = this.props.data.price.amount;

      if (v === 0) {
        data.price.amount = actualPrice;
      } else {
        data.price.amount -= actualPrice * (v / 100);
        data.discount_price.amount = actualPrice - data.price.amount;
      }
    } else {
      data[field] = value;
    }

    this.setState({ data });
  }

  render() {
    const { onDismiss, onConfirm } = this.props;
    const { data } = this.state;

    return (
      <Wrapper>
        <InputWrapper>
          <Label>{__('Jednotková cena bez DPH')}</Label>
          <Input
            onChange={e => this.handleChange('price', e.target.value)}
            placeholder={__('Jednotková cena bez DPH')}
            value={data.price.amount}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Počet kusov')}</Label>
          <Input
            onChange={e => this.handleChange('quantity', e.target.value)}
            placeholder={__('Počet kusov')}
            value={data.quantity}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Zľava %')}</Label>
          <Input
            onChange={e =>
              this.handleChange('discount_percent', e.target.value)
            }
            type="number"
            min="0"
            max="100"
            placeholder={__('Zľava %')}
            value={data.discount_percent}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Zľava')}</Label>
          <Input
            onChange={e =>
              this.handleChange('discount_price.amount', e.target.value)
            }
            disabled
            placeholder={__('Zľava')}
            value={formatPrice(data.discount_price)}
          />
        </InputWrapper>

        <InputWrapper>
          <Label>{__('Výsledná cena')}</Label>
          <TotalPrice>
            {formatPrice({
              amount: Number(data.quantity) * data.price.amount,
              currency: data.price.currency,
            })}
          </TotalPrice>
        </InputWrapper>

        <Buttons>
          <Button danger onClick={() => onDismiss()}>
            {__('Zrušiť')}
          </Button>
          <Button disabled onClick={() => onConfirm(data)} primary>
            {__('Uložiť (pripravujeme)')}
          </Button>
        </Buttons>
      </Wrapper>
    );
  }
}

ProductEdit.propTypes = {
  data: PropTypes.shape({}),
  onDismiss: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

ProductEdit.defaultProps = {
  data: {},
};

export default ProductEdit;
