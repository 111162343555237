import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import Questions from '../../Containers/ProductsQuestions';
import QuestionDetail from '../../Containers/ProductQuestionDetail';

function QuestionsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={QuestionDetail} />
      <Route path={`${match.url}`} component={Questions} />
    </Switch>
  );
}

QuestionsCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default QuestionsCard;
