import { translateClaimType } from '../Utils';

export default [
  {
    name: translateClaimType('return'),
    value: 'return',
    key: 1,
  },
  {
    name: translateClaimType('claim'),
    value: 'claim',
    key: 2,
  },
];
