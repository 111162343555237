import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import BillsList from '../../Containers/BillsList';
import BillDetail from '../../Containers/BillDetail';

function BillsCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={BillDetail} />
      <Route path={`${match.url}`} component={BillsList} />
    </Switch>
  );
}

BillsCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default BillsCard;
