import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { Label } from 'oxyrion-ui/lib';

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(10)} ${props => rem(props.sidePadding)};
  max-width: ${props => (props.maxWidth ? rem(props.maxWidth) : 'auto')};
  background: #f7f7f7;
  border-radius: ${rem(4)};
`;

const ContentWrapper = styled.div`
  margin-bottom: ${rem(10)};
`;

class GreyBox extends Component {
  render() {
    const { children, title, sidePadding, maxWidth } = this.props;

    return (
      <Wrapper maxWidth={maxWidth} sidePadding={sidePadding}>
        <ContentWrapper>
          <Label>{title}</Label>
          {children}
        </ContentWrapper>
      </Wrapper>
    );
  }
}

GreyBox.propTypes = {
  children: PropTypes.element,
  sidePadding: PropTypes.number,
  title: PropTypes.string,
  maxWidth: PropTypes.number,
};

GreyBox.defaultProps = {
  children: <div />,
  sidePadding: 10,
  title: '',
  maxWidth: false,
};

export default GreyBox;
