import React from 'react';
import moment from 'moment';
import BaseTable from '../BaseTable';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  renderLink,
  __,
  getApprovalStatus,
} from '../../Utils';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/productsReviews';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';

class Reviews extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Otázky k produktom');
    this.accessors = [
      'id',
      'customer_name',
      'rating',
      'text',
      'approval',
      'images_count',
      'created_date',
    ];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearch: '',
      showFilter: false,
      filters: [
        {
          name: 'productId',
          label: __('ID produktu'),
          value: '',
          type: 'search-select',
          onSearch: q => this.searchProducts(q, this.props.firm),
        },
        {
          name: 'contactId',
          label: __('ID zakaznika'),
          value: '',
          type: 'search-select',
          onSearch: q => this.searchCustomers(q, this.props.firm),
        },
        {
          name: 'fromDate',
          label: __('Dátum od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum do'),
          value: '',
          type: 'date',
        },
        {
          name: 'rating',
          label: __('Hodnotenie'),
          value: '',
          type: 'input',
        },
      ],
    };
  }
  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearch, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value);
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearch;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      const newData = await API2.getEshopProductsReviewsAction(
        this.props.firm,
        params,
      );
      const content = this.normalizeColumns(newData.items);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        products: newData.items.map(p => p.product),
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  searchProducts = async (query, firm = 'SK') => {
    try {
      const filter = {
        limit: 20,
        productId: query,
      };

      return API2.getEshopProductsReviewsAction(firm, filter).then(res => {
        return {
          options: res.items.map(item => ({
            value: item.product._id,
            label: `${item.product._id} - (${item.product.name})`,
            raw: item.product,
          })),
        };
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  searchCustomers = async (query, firm = 'SK') => {
    try {
      const filter = {
        limit: 20,
        contactId: query,
      };

      return API2.getEshopProductsReviewsAction(firm, filter).then(res => {
        return {
          options: res.items.map(item => ({
            value: item.contact._id,
            label: `${item.contact._id} - (${item.contact.email})`,
            raw: item.contact,
          })),
        };
      });
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        id: _shapeData(
          renderLink(`./reviews/${item._id}`, `${item.product.name} `),
        ),
        customer_name: _shapeData(item.customer_name),
        rating: _shapeData(`${item.rating} / 5`),
        created_date: _shapeData(
          formatDate(moment(item.created_date), FORMAT_HUMANE_DATETIME),
        ),
        approval: _shapeData(getApprovalStatus(item.approval)),
        images_count: _shapeData(item.images.length),
        text: _shapeData(item.text),
      });
      return acumulator;
    }, []);
    return result;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Recenzie k produktom')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    return super.render();
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Reviews);
