import { Button, Input, Label, Message, Select } from 'oxyrion-ui/lib';
import React, { Component } from 'react';
import {
  __,
  isNotNullEmailValidation,
  isPhoneValidation,
  validateFormItems,
} from '../../Utils';
import styled from 'styled-components';
import { rem } from 'polished';
import { AnimatedFormMessageWrapper } from '../../Components/ReusableComponents';
import API2 from '../../API2';

class RegisterCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: [],
      selectedTier: null,
      customerType: null,
      cashRegister: 'POK08',
      cashRegistersOptions: [],
      loading: false,
      finstatData: null,
      finstatLoading: false,
      errors: [],
    };
  }

  componentDidMount() {
    this.fetchData();
    this.setState({
      formData: this.setData(),
    });
  }

  async getFinstatData(ico) {
    try {
      this.setState({
        finstatLoading: true,
      });

      const finstatRes = await API2.getCustomerByRegistrationNoAction(
        this.props.firm,
        ico,
      );
      if (finstatRes) {
        this.handleChange('company', 1, finstatRes.name);

        this.setState({
          finstatData: finstatRes,
          finstatLoading: false,
        });
      }
    } catch (e) {
      console.log(e);
      switch (e.response.status) {
        case 404:
          this.setState({
            errors: [__('Zákazník so zadaným IČO sa nenašiel')],
            finstatLoading: false,
          });
          break;
        case 403:
          this.setState({
            errors: [__('Na zvoleného zákazníka nemáte potrebné práva')],
            finstatLoading: false,
          });
          break;
        default:
          throw e;
      }
    }
  }

  handleChange(field, index, value) {
    const { formData } = this.state;

    if (value === '-') {
      value = null;
    }

    if (field === 'customer_type') {
      let updatedFormData = this.setData();

      if (value === 'company') {
        const companyFields = [
          {
            name: 'ico',
            value: '',
            error: false,
            placeholder: __('IČO'),
            label: __('IČO *'),
            required: true,
          },
          {
            name: 'company',
            value: '',
            error: false,
            placeholder: __('Spoločnosť'),
            label: __('Názov spoločnosti'),
            required: false,
            disabled: true,
          },
        ];

        updatedFormData = [...companyFields, ...updatedFormData];
      } else {
        updatedFormData = formData.filter(
          item => item.name !== 'ico' && item.name !== 'company',
        );
        this.setState({
          finstatData: null,
        });
      }

      this.setState({
        customerType: value,
        formData: updatedFormData,
      });
    } else if (field === 'selected_tier') {
      this.setState({
        selectedTier: value,
      });
    } else if (field === 'cash_register') {
      this.setState({
        cashRegister: value,
      });
    } else {
      formData[index].value = value;

      this.setState({
        formData,
      });
    }
  }

  async fetchData() {
    const cashRegistersOptions = await API2.getCashRegistersListAction({
      companyId: this.props.firm,
      onlyProduction: true,
    });

    this.setState({
      cashRegistersOptions: cashRegistersOptions.items,
    });
  }

  setData() {
    const data = [
      {
        name: 'name',
        value: '',
        error: false,
        placeholder: __('Meno'),
        label: __('Meno *'),
        required: true,
      },
      {
        name: 'surname',
        value: '',
        error: false,
        placeholder: __('Priezvisko'),
        label: __('Priezvisko *'),
        required: true,
      },
      {
        name: 'email',
        value: '',
        error: false,
        placeholder: __('Email'),
        label: __('Email *'),
        required: true,
        validation: [isNotNullEmailValidation],
      },
      {
        name: 'phone',
        value: '',
        error: false,
        placeholder: __('+42...'),
        label: __('Telefónne číslo (+42...) *'),
        required: true,
        validation: [isPhoneValidation],
      },
    ];
    return data;
  }

  checkData() {
    const { formData } = this.state;
    const [formDataErrors, checkedFormData] = validateFormItems(formData);

    this.setState({
      formData: checkedFormData,
      errors: formDataErrors,
    });

    return formDataErrors.length;
  }

  async registerUser() {
    const { formData, selectedTier, customerType, cashRegister } = this.state;
    const { successCallback } = this.props;

    if (!this.checkData()) {
      this.setState({
        loading: true,
      });
      try {
        const result = await API2.postRegisterCustomer(this.props.firm, {
          body: {
            tierLevel: selectedTier,
            customerType: customerType,
            name: formData.find(f => f.name === 'name').value,
            surname: formData.find(f => f.name === 'surname').value,
            email: formData.find(f => f.name === 'email').value,
            phone: formData.find(f => f.name === 'phone').value,
            ico: formData.find(f => f.name === 'ico')
              ? formData.find(f => f.name === 'ico').value
              : undefined,
            cashRegisterId: cashRegister,
          },
        });

        successCallback(result);
        this.setState({
          formData: this.setData(),
          loading: false,
        });
      } catch (e) {
        console.log(e);
        switch (e.response.status) {
          case 409:
            this.setState({
              errors: [__('Zadaný email už existuje.')],
              loading: false,
            });
            break;

          default:
            this.setState({
              errors: [__('Nastala neočakávaná chyba.')],
              loading: false,
            });
        }
      }
    }
  }

  renderForm() {
    const { formData, customerType, finstatLoading, finstatData } = this.state;

    if (customerType) {
      return formData.map((data, index) => (
        <FormItem key={data.name}>
          <Label>{data.label}</Label>
          <StyledInput
            value={data.value}
            placeholder={data.placeholder}
            error={data.error}
            onChange={e => this.handleChange(data.name, index, e.target.value)}
            onBlur={
              data.name === 'ico' &&
              data.value !== '' &&
              (e => this.getFinstatData(e.target.value))
            }
            loading={data.name === 'ico' && finstatLoading}
            disabled={
              data.disabled ||
              (data.name !== 'ico' &&
                customerType === 'company' &&
                !finstatData)
            }
          />
        </FormItem>
      ));
    }
  }

  render() {
    const {
      errors,
      loading,
      selectedTier,
      customerType,
      cashRegistersOptions,
    } = this.state;

    return (
      <React.Fragment>
        <AnimatedFormMessageWrapper display={errors.length}>
          {errors.map((e, index) => (
            <Message key={index} error message={e} />
          ))}
        </AnimatedFormMessageWrapper>
        <Wrapper>
          <Form
            onSubmit={e => {
              e.preventDefault();
            }}
          >
            <FormItem>
              <Label>{__('Pokladňa *')}</Label>
              <Select
                size="s"
                onChange={e => {
                  this.handleChange('cash_register', null, e.target.value);
                }}
              >
                {cashRegistersOptions.map(option => (
                  <option key={option.name} value={option._id}>
                    {option.name}
                  </option>
                ))}
              </Select>
            </FormItem>
            <FormItem>
              <Label>{__('Úroveň maliara *')}</Label>
              <Select
                size="s"
                onChange={e => {
                  this.handleChange('selected_tier', null, e.target.value);
                }}
              >
                <option value="-" selected>
                  {__('Zvoľte úroveň zákazníka')}
                </option>
                <option value="amateur">{__('Amatér')}</option>
                <option value="professional">{__('Profesionál')}</option>
              </Select>
            </FormItem>

            <FormItem>
              <Label>{__('Typ zákazníka *')}</Label>
              <Select
                size="s"
                onChange={e => {
                  this.handleChange('customer_type', null, e.target.value);
                }}
              >
                <option value="-" selected>
                  {__('Zvoľte typ zákazníka')}
                </option>
                <option value="person">{__('Súkromná osoba')}</option>
                <option value="company">{__('Firma')}</option>
              </Select>
            </FormItem>
            {this.renderForm()}
            <ButtonWrapper>
              <Button
                onClick={() => this.registerUser()}
                primary
                loading={loading}
                disabled={!customerType || !selectedTier}
              >
                {__('Registrovať')}
              </Button>
            </ButtonWrapper>
          </Form>
        </Wrapper>
      </React.Fragment>
    );
  }
}

const Wrapper = styled.div`
  margin: ${rem(24)} auto;
  width: 90%;
  text-align: left;
`;
const Form = styled.form``;

const FormItem = styled.div`
  width: 100%;
`;

const ButtonWrapper = styled.div`
  margin-top: ${rem(20)};
`;

const StyledInput = styled(Input)`
  margin-right: ${rem(20)};
`;

export default RegisterCustomer;
