import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Select from 'oxyrion-ui/lib/Select';
import Message from 'oxyrion-ui/lib/Message';
import Label from 'oxyrion-ui/lib/Label/Label';
import Textarea from 'oxyrion-ui/lib/Textarea';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import FileViewDownload from '../../Components/FileViewDownload';
import {
  formatDate,
  getIdFromProps,
  translateClaimSolutions,
  getStateLabel,
  __,
  translateClaimResult,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import Input from 'oxyrion-ui/lib/Input';
import API2 from '../../API2';
import CustomEndpoints from '../../CustomEndpoints';

const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    margin: ${rem(4)};
  }
`;
const CustomerNote = styled.div`
  display: flex;
  flex-direction: column;
`;
const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value {
    color: black;
    font-weight: bold;
  }
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const SelectsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  .select-wrapper {
    width: 48%;
  }
`;
const FileInputWrapper = styled.div`
  width: 100%;
  height: ${rem(100)};
  display: flex;
  justify-content: center;
  align-items: center;
`;
const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .block {
    margin-top: ${rem(12)};
    margin-left: ${rem(12)};
    display: flex;
    flex-direction: column;
    .label {
      margin-bottom: ${rem(12)};
    }
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }
  .state-btn-wrapper {
    margin-left: ${rem(12)};
  }
  .value {
  }
  .files-wrapper {
    display: flex;
    flex-direction: row;
  }
  .documentation-files {
    display: flex;
    flex-direction: column;
    margin-right: ${rem(48)};
  }
  .documentation-images {
    display: flex;
    flex-direction: row;
  }
  .documentation-label {
    font-weight: bold;
    margin-bottom: ${rem(12)};
  }
  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.m`
 flex-direction: row;
`};
`;
const DocumentationWrapper = styled.div`
  width: 100%;
  .upload-files-info {
    margin-left: ${rem(8)};
    text-align: left;
    font-size: ${rem(14)};
  }
`;
const FilesToUploadList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${rem(8)};
  font-size: ${rem(14)};
`;

const SubWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.m`
  width: 50%;
`};
  padding: ${rem(12)};
  .notes-wrapper {
    display: flex;
    flex-direction: column;
    width: auto;
  }
  .note-info {
    font-size: ${rem(12)};
    padding: ${rem(8)};
    font-weight: 500;
    border-right: 1px solid lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .note-row {
    display: flex;
    flex-direction: row;
    padding-top: ${rem(8)};
    padding-bottom: ${rem(8)};
    border-bottom: 1px solid lightgray;
  }
  .note-content {
    border: none;
  }
  .label {
    font-weight: bold;
    margin-bottom: ${rem(12)};
    font-size: ${rem(12)};
  }
`;

const AcceptedQuantityWrapper = styled.div`
  margin-bottom: ${rem(8)};
`;

class WarrantyClaimDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      newNote: '',
      noteLoading: false,
      loading: true,
      toState: null,
      uploadFiles: [],
      changeStateLoading: false,
      noteForCustomer: '',
      finishedClaim: {
        result: 'accepted',
        result_solution: 'restitution',
        result_description: '',
      },
      acceptedQuantity: ''
    };
  }
  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const result = await API2.getEshopWarrantyClaimAction(
        this.props.firm,
        getIdFromProps(this.props),
      );
      const { baseInfo, customerInfo, claimInfo } = this.renderDataBlocks(
        result,
      );
      this.setState({
        loading: false,
        acceptedQuantity: result.return_quantity,
        data: Object.assign(result, {
          baseInfo,
          customerInfo,
          claimInfo,
        }),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo načítať'),
        loading: false,
      });
    }
  }
  async addResultSolutionFile(acceptedFiles) {
    try {
      if (!acceptedFiles) {
        return;
      }
      this.setState({
        uploadFiles: Array.from(acceptedFiles),
      });
    } catch (e) {
      console.log(e);
    }
  }
  handleCustomerNoteChange(text) {
    this.setState({ noteForCustomer: text });
  }
  handleNewNoteChange(text) {
    this.setState({ newNote: text });
  }
  async saveNote() {
    const { newNote, data } = this.state;
    try {
      this.setState({ noteLoading: true });
      const { notes } = await API2.postEshopWarrantyClaimNoteAction(
        this.props.firm,
        data._id,
        {
          body: {
            content: newNote,
          },
        },
      );
      data.notes = notes;
      this.setState({
        noteLoading: false,
        newNote: '',
        data,
      });
    } catch (e) {
      console.log(e);
    }
  }
  changeToState(toState) {
    this.setState({ toState });
  }
  normalizeDataAfterChangingState(result) {
    const { baseInfo, customerInfo, claimInfo } = this.renderDataBlocks(result);
    this.setState({
      data: Object.assign(result, {
        baseInfo,
        customerInfo,
        claimInfo,
      }),
      toState: null,
    });
  }
  changeFinishedClaim(attribute, value) {
    const { finishedClaim } = this.state;
    if (attribute === 'result') {
      finishedClaim.result = value;
      if (value === 'rejected') {
        finishedClaim.result_solution = null;
      }
      if (value === 'accepted') {
        finishedClaim.result_solution = 'restitution';
      }
    }
    if (attribute === 'result_solution') {
      finishedClaim.result_solution = value;
    }
    if (attribute === 'result_description') {
      finishedClaim.result_description = value;
    }
    this.setState({ finishedClaim });
  }

  async changeState() {
    const {
      toState,
      data,
      noteForCustomer,
      finishedClaim,
      uploadFiles,
      acceptedQuantity
    } = this.state;
    this.setState({ changeStateLoading: true });
    try {
      if (toState === 'waiting_for_merchandise') {
        const result = await API2.putEshopWarrantyClaimStateAction(
          this.props.firm,
          data._id,
          {
            body: {
              to_state: toState,
              accepted_quantity: Number(acceptedQuantity)
            },
          },
        );
        this.normalizeDataAfterChangingState(result);
      }
      if (toState === 'in_process') {
        const result = await API2.putEshopWarrantyClaimStateAction(
          this.props.firm,
          data._id,
          {
            body: {
              to_state: toState,
              note: noteForCustomer,
            },
          },
        );
        this.normalizeDataAfterChangingState(result);
      }
      if (toState === 'finished') {
        const fileCreatePromises = uploadFiles.map(u =>
          CustomEndpoints.postEshopWarrantyClaimFileAction(
            this.props.firm,
            this.state.data._id,
            {
              attachment: u,
            },
          ),
        );

        await Promise.all(fileCreatePromises);

        const result = await API2.putEshopWarrantyClaimStateAction(
          this.props.firm,
          data._id,
          {
            body: {
              to_state: toState,
              result: finishedClaim.result,
              result_solution:
                data.type === 'claim' ? finishedClaim.result_solution : null,
              result_description: finishedClaim.result_description,
            },
          },
        );
        this.normalizeDataAfterChangingState(result);
        this.setState({ uploadFiles: null });
      }
    } catch (e) {
      console.log(e);
    }
    this.setState({ changeStateLoading: false });
  }
  backToOrders() {
    this.props.history.push('../warranty-claims');
  }

  async stornoAction() {
    const { data } = this.state;

    try {
      this.setState({
        stornoLoading: true,
      });

      const result = await API2.cancelWarrantyClaimAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.setState({
        data: Object.assign({}, data, { state: result.state }),
        stornoLoading: false,
        stornoPopUp: false
      });
    } catch (e) {
      this.setState({
        error: __('Storno reklamácie sa nepodarilo vykonať.'),
        stornoLoading: false,
        stornoPopUp: false
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return <ControllBar history={history} name={data.claim_number} >
      {(data.state !== 'cancelled') && (
        <ControlBarButton
          small
          primary
          warning
          showIcon={false}
          onClick={() =>
            this.setState({ stornoPopUp: true })
          }
        >
          {__('Stornovať')}
        </ControlBarButton>)}
    </ControllBar>;
  }

  renderButtonsByState(state) {
    // const { data } = this.state;

    const backButton = (
      <div className="button-wrapper">
        <Button onClick={() => this.backToOrders()} primary>
          {__('Späť')}
        </Button>
      </div>
    );

    return (
      <React.Fragment>
        {backButton}
        {/* <div className="right">{rightButtons}</div> */}
      </React.Fragment>
    );
  }

  renderDataBlocks(result) {
    const baseInfo = [
      {
        label: __('Dátum zadania'),
        value: formatDate(moment(result.created_date)),
      },
      {
        label: __('Preferovaný spôsob vybavenia'),
        value: result.preferred_solution
          ? translateClaimSolutions(result.preferred_solution.value)
          : '-',
      },
      {
        label: __('Stav'),
        value: getStateLabel(result.state),
      },
      {
        label: __('Objednávka'),
        value: (
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/orders/${result.order_id}`}
          >
            {result.order_id}
          </StyledA>
        ),
      },
      {
        label: __('Produkt'),
        value: result.product.item_name
      },
      {
        label: __('Zakúpený počet kusov'),
        value: result.product.quantity
      }
    ];
    const customerInfo = [
      {
        label: __('Meno zákazníka'),
        value: (
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm
              }/contacts/${result.customer._id}`}
          >
            {result.customer.name}
          </StyledA>
        ),
      },
      {
        label: __('Email'),
        value: result.customer_email,
      },
      {
        label: __('Tel. číslo'),
        value: result.customer_phone_number,
      },
    ];
    const claimInfo = [
      {
        label: __('Popis vady'),
        value: result.reason_description || "-",
      },
      {
        label: __('Počet kusov v požiadavke'),
        value: result.return_quantity || __('Neuvedený')
      },
      {
        label: __('IBAN pre vrátenie peňazí'),
        value: result.return_money_iban || __('Neuvedený')
      },
    ];
    return { baseInfo, customerInfo, claimInfo };
  }

  renderRow(i, disabledEditButtons) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && !disabledEditButtons && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }
  renderBlock(block) {
    return (
      <div className="block">
        <div className="label">{block.label}:</div>
        <div className="value">{block.value}</div>
      </div>
    );
  }
  renderGroup(group, disabledEditButtons) {
    return (
      <div className="group">
        <div className="row">
          <div className="label">{group.label}</div>
          {group.button && !disabledEditButtons && (
            <div className="button" onClick={() => group.button.onClick()}>
              {group.button.title}
            </div>
          )}
        </div>
        {group.values.map(v => (
          <div className="row">
            <div className="value">{v}</div>
          </div>
        ))}
      </div>
    );
  }

  renderConfirmDialogs() {
    const {
      changeStateLoading,
      noteForCustomer,
      finishedClaim,
      data,
      uploadFiles,
      toState,
      stornoPopUp,
      stornoLoading
    } = this.state;

    return (
      <React.Fragment>
        <PopUpWrapper
          display={stornoPopUp}
          small
          onClose={() => this.setState({ stornoPopUp: false })}
          onEscClose={() => this.setState({ stornoPopUp: false })}
          message={__('Storno reklamácie')}
        >
          <ConfirmDialog
            onDismiss={() => this.setState({ stornoPopUp: false })}
            onConfirm={() => this.stornoAction()}
            loading={stornoLoading}
            dismissMessage={__('Zrušiť')}
            confirmMessage={__('Stornovať')}
            message={__(
              'Naozaj chcete stornovať túto reklamáciu?',
            )}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={toState === 'waiting_for_merchandise'}
          small
          onClose={() => this.setState({ toState: null })}
          statusName="toState"
          onEscClose={() => this.setState({ toState: null })}
          message={__('Zaevidovať požiadavku')}
        >
          <ConfirmDialog
            onDismiss={() => this.changeState()}
            onConfirm={() => this.setState({ toState: null })}
            dismissLoading={changeStateLoading}
            dismissMessage={__('Evidovať')}
            confirmMessage={__('Zrušiť')}
            message={__(
              'Požiadavka bude zaevidovaná. Zákazník bude notifikovaný.',
            )}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={toState === 'in_process'}
          small
          onClose={() => this.setState({ toState: null })}
          statusName="toState"
          onEscClose={() => this.setState({ toState: null })}
          message={__('Potvrdiť prijatie tovaru')}
        >
          <ConfirmDialog
            onDismiss={() => this.changeState()}
            onConfirm={() => this.setState({ toState: null })}
            dismissLoading={changeStateLoading}
            dismissMessage={__('Potvrdiť')}
            confirmMessage={__('Zrušiť')}
            message={__(
              'Naozaj chcete potvrdiť prijatie tovaru od zákazníka? Zákazník bude notifikovaný o zmene stavu.',
            )}
            additionalContent={
              <CustomerNote>
                <Label>{__('Poznámka k prijatému tovaru (nepovinné)')}</Label>
                <Textarea
                  style={{
                    width: '95%',
                    height: rem(120),
                    marginBottom: rem(12),
                  }}
                  value={noteForCustomer || ''}
                  placeholder={`${__(
                    'Zadajte poznámku, ktorú chcete adresovať zákazníkovi.',
                  )}`}
                  onChange={e => this.handleCustomerNoteChange(e.target.value)}
                />
              </CustomerNote>
            }
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={toState === 'finished'}
          small
          onClose={() => this.setState({ toState: null })}
          statusName="toState"
          onEscClose={() => this.setState({ toState: null })}
          message={__('Uzavrieť požiadavku')}
        >
          <ConfirmDialog
            onDismiss={() => this.changeState()}
            onConfirm={() => this.setState({ toState: null })}
            dismissLoading={changeStateLoading}
            dismissMessage={__('Odoslať')}
            confirmMessage={__('Zahodiť')}
            additionalContent={
              <div>
                <SelectsWrapper>
                  <div className="select-wrapper">
                    <Label>{__('Výsledok požiadavky')}</Label>
                    <Select
                      size="m"
                      placeholder={__('Výsledok požiadavky')}
                      onChange={e => {
                        this.changeFinishedClaim('result', e.target.value);
                      }}
                    >
                      <option
                        key="accepted"
                        value="accepted"
                        selected={finishedClaim.reason === 'accepted'}
                      >
                        {__(translateClaimResult('accepted'))}
                      </option>
                      <option
                        key="rejected"
                        value="rejected"
                        selected={finishedClaim.reason === 'rejected'}
                      >
                        {__(translateClaimResult('rejected'))}
                      </option>
                    </Select>
                  </div>
                  {data.type === 'claim' &&
                    finishedClaim.result !== 'rejected' && (
                      <div className="select-wrapper">
                        <Label>{__('Spôsob ukončenia')}</Label>
                        <Select
                          size="l"
                          placeholder={__('Spôsob ukončenia')}
                          onChange={e => {
                            this.changeFinishedClaim(
                              'result_solution',
                              e.target.value,
                            );
                          }}
                        >
                          <option
                            key="restitution"
                            value="restitution"
                            selected={finishedClaim.reason === 'restitution'}
                          >
                            {__(translateClaimSolutions('restitution'))}
                          </option>
                          <option
                            key="new_merchandise"
                            value="new_merchandise"
                            selected={
                              finishedClaim.reason === 'new_merchandise'
                            }
                          >
                            {__(translateClaimSolutions('new_merchandise'))}
                          </option>
                          <option
                            key="fix_merchandise"
                            value="fix_merchandise"
                            selected={
                              finishedClaim.reason === 'fix_merchandise'
                            }
                          >
                            {__(translateClaimSolutions('fix_merchandise'))}
                          </option>
                        </Select>
                      </div>
                    )}
                </SelectsWrapper>
                <CustomerNote>
                  <Label>{__('Odôvodnenie')}</Label>
                  <Textarea
                    style={{
                      width: '95%',
                      height: rem(120),
                      marginBottom: rem(12),
                    }}
                    value={finishedClaim.result_description || ''}
                    placeholder={`${__(
                      'Zadajte poznámku, ktorú chcete adresovať zákazníkovi.',
                    )}`}
                    onChange={e =>
                      this.changeFinishedClaim(
                        'result_description',
                        e.target.value,
                      )
                    }
                  />
                </CustomerNote>
                <DocumentationWrapper>
                  <Label>{__('Nahrať dokumentáciu')}</Label>
                  <FileInputWrapper>
                    <input
                      type="file"
                      multiple
                      title="Nahrať súbor"
                      placeholder="aaa"
                      onChange={e => this.addResultSolutionFile(e.target.files)}
                    />
                  </FileInputWrapper>
                  {uploadFiles && (
                    <div>
                      <Label>{__('Súbory na nahratie')}</Label>
                      <FilesToUploadList>
                        {uploadFiles.map(f => (
                          <span>{`- ${f.name}`}</span>
                        ))}
                      </FilesToUploadList>
                    </div>
                  )}
                  <p className="upload-files-info">
                    {__(
                      'Nahraté súbory obdrží zákazník v prílohe emailu (notifikácie). Notifikácia bude odoslaná po potvrdení tohto formulára.',
                    )}
                  </p>
                </DocumentationWrapper>
              </div>
            }
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }

  render() {
    const { data, loading, noteLoading, newNote, error, success, acceptedQuantity } = this.state;
    const disabledEditButtons = false;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderConfirmDialogs()}
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <div className="buttons-wrapper">
            {this.renderButtonsByState(data.order_state)}
          </div>
          <HeaderLabel>
            <div className="text">{__('Základné informácie')}</div>
          </HeaderLabel>
          <InfoWrapper>
            <div>
              {data.baseInfo &&
                data.baseInfo.map(i => this.renderRow(i, disabledEditButtons))}
            </div>
            <div>
              {data.customerInfo &&
                data.customerInfo.map(i =>
                  this.renderRow(i, disabledEditButtons),
                )}
            </div>
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Znenie požiadavky')}</div>
          </HeaderLabel>
          <InfoWrapper>
            <div>
              {data.claimInfo && data.claimInfo.map(i => this.renderBlock(i))}
            </div>
            {data.customer_files && data.customer_files.length > 0 && (
              <div className="files-wrapper">
                <div className="documentation-files">
                  <div className="documentation-label">
                    {__('Dokumentácia')}
                  </div>
                  {data.customer_files &&
                    data.customer_files.map(document => (
                      <FileViewDownload
                        name={document.name}
                        type={document.mimeType}
                        fullPath={document.fullPath}
                        color="#A448A3"
                        customWidth={280}
                        history={this.props.history}
                        downloadFunction={async () => {
                          return API2.getEshopWarrantyClaimFileDownloadIdAction(
                            this.props.firm,
                            data._id,
                            encodeURIComponent(document.fullPath),
                          );
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Riešenie')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.state === 'new' && (
              <div>
                <div>
                  {this.renderRow(
                    {
                      label: __('Stav'),
                      value: getStateLabel(data.state),
                    },
                    false,
                  )}
                </div>
                <div>
                  {this.renderBlock(
                    {
                      label: __('Akceptovaný počet kusov'),
                      value: (
                        <AcceptedQuantityWrapper>
                          <Input
                            onChange={e => this.setState({ acceptedQuantity: e.target.value })}
                            placeholder={__('Počet kusov')}
                            value={acceptedQuantity}
                            type="number"
                            min="0"
                            max={data.product.quantity}
                            disabled={false}
                          />
                        </AcceptedQuantityWrapper>
                      ),
                    },
                    false,
                  )}
                </div>
                <div className="state-btn-wrapper">
                  <Button
                    small
                    primary
                    onClick={() =>
                      this.changeToState('waiting_for_merchandise')
                    }
                  >
                    {__('Evidovať požiadavku')}
                  </Button>
                </div>
              </div>
            )}
            {data.state === 'waiting_for_merchandise' && (
              <div>
                <div>
                  {this.renderRow(
                    {
                      label: __('Stav'),
                      value: getStateLabel(data.state),
                    },
                    false,
                  )}
                </div>
                <div>
                  {this.renderBlock(
                    {
                      label: __('Akceptovaný počet kusov'),
                      value: (
                        <AcceptedQuantityWrapper>
                          <Input
                            onChange={e => this.setState({ acceptedQuantity: e.target.value })}
                            placeholder={__('Počet kusov')}
                            value={acceptedQuantity}
                            type="number"
                            min="0"
                            max={data.product.quantity}
                            disabled={true}
                          />
                        </AcceptedQuantityWrapper>
                      ),
                    },
                    false,
                  )}
                </div>
                <div className="state-btn-wrapper">
                  <Button
                    small
                    primary
                    onClick={() => this.changeToState('in_process')}
                  >
                    {__('Potvrdiť prijatie tovaru')}
                  </Button>
                </div>
              </div>
            )}
            {data.state === 'in_process' && (
              <div>
                <div>
                  {this.renderRow(
                    {
                      label: __('Stav'),
                      value: getStateLabel(data.state),
                    },
                    false,
                  )}
                </div>
                <div>
                  {this.renderBlock(
                    {
                      label: __('Akceptovaný počet kusov'),
                      value: (
                        <AcceptedQuantityWrapper>
                          <Input
                            onChange={e => this.setState({ acceptedQuantity: e.target.value })}
                            placeholder={__('Počet kusov')}
                            value={acceptedQuantity}
                            type="number"
                            min="0"
                            max={data.product.quantity}
                            disabled={true}
                          />
                        </AcceptedQuantityWrapper>
                      ),
                    },
                    false,
                  )}
                </div>
                <div className="state-btn-wrapper">
                  <Button
                    small
                    primary
                    onClick={() => this.changeToState('finished')}
                  >
                    {__('Vyriešiť požiadavku')}
                  </Button>
                </div>
              </div>
            )}
            {data.state === 'finished' && (
              <div>
                <div>
                  {this.renderRow(
                    {
                      label: __('Výsledok požiadavky'),
                      value: (data.result && data.result.label) || '-',
                    },
                    false,
                  )}
                  {this.renderRow(
                    {
                      label: __('Spôsob vybavenia'),
                      value:
                        (data.result_solution && data.result_solution.label) ||
                        '-',
                    },
                    false,
                  )}
                  {this.renderBlock({
                    label: __('Odôvodnenie'),
                    value: data.result_description,
                  })}
                </div>
              </div>
            )}
            {data.state === 'finished' &&
              data.result_files &&
              data.result_files.length > 0 && (
                <div className="files-wrapper">
                  <div className="documentation-files">
                    <div className="documentation-label">
                      {__('Dokumentácia')}
                    </div>
                    {data.result_files &&
                      data.result_files.map(document => (
                        <FileViewDownload
                          name={document.name}
                          type={document.mimeType}
                          fullPath={document.fullPath}
                          color="#A448A3"
                          customWidth={280}
                          history={this.props.history}
                          downloadFunction={async () => {
                            return API2.getEshopWarrantyClaimFileDownloadIdAction(
                              this.props.firm,
                              data._id,
                              encodeURIComponent(document.fullPath),
                            );
                          }}
                        />
                      ))}
                  </div>
                </div>
              )}
            {data.state === 'cancelled' && (
              <div>
                <div>
                  {this.renderRow(
                    {
                      label: __('Stav'),
                      value: getStateLabel(data.state),
                    },
                    false,
                  )}
                </div>
              </div>
            )}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Poznámky')}</div>
          </HeaderLabel>
          <SummaryWrapper>
            <SubWrapper>
              <div className="label">{__('Nová poznámka k požiadavke')}</div>
              <Textarea
                style={{
                  width: rem(400),
                  height: rem(120),
                  marginBottom: rem(12),
                }}
                value={newNote || ''}
                placeholder={`${__('Zadajte poznámku...')}`}
                onChange={e => this.handleNewNoteChange(e.target.value)}
              />
              <Button
                small
                primary
                loading={noteLoading}
                disabled={noteLoading}
                onClick={() => this.saveNote()}
              >
                {__('Uložiť poznámku')}
              </Button>
            </SubWrapper>
            <SubWrapper>
              <div className="label">{__('Poznámky')}</div>
              <div className="notes-wrapper">
                {data.notes &&
                  data.notes.length > 0 &&
                  data.notes.map(note => (
                    <div className="note-row" key={note}>
                      <div className="note-info">
                        {formatDate(moment(note.created_date))}
                      </div>
                      <div className="note-info">{note.author.name}</div>
                      <div className="note-info note-content">
                        {note.content}
                      </div>
                    </div>
                  ))}
              </div>
            </SubWrapper>
          </SummaryWrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}
WarrantyClaimDetail.propTypes = {
  history: PropTypes.shape({}),
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(WarrantyClaimDetail);
