import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import CashRegisterOrderDetail from '../../Containers/CashRegisterOrderDetail';
import CashRegisterOrdersList from '../../Containers/CashRegisterOrdersList';

function CashRegistersOrdersCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={CashRegisterOrderDetail} />
      <Route path={`${match.url}`} component={CashRegisterOrdersList} />
    </Switch>
  );
}

CashRegistersOrdersCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default CashRegistersOrdersCard;
