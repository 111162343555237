import { __e as __ } from '../Utils';

export default [
  {
    name: __('Denná uzávierka'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Pokladňa'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Dátum uzávierky'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Status'),
    clickable: false,
  },
];
