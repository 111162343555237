import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'oxyrion-ui/lib/Button';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  formatPrice,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  __,
  renderBillState,
  paymentsTypes,
  getCurrencyByFirm,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import ProductsTable from '../ProductsTable';
import API2 from '../../API2';
import { Textarea } from 'oxyrion-ui/lib';
import PopUpWrapper from '../../Components/PopUpWrapper';
import FinPortalDetail from '../../Components/FinPortalDetail';

const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(46)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }

  button{
    padding: ${rem(10)} ${rem(20)};
    margin-right: ${rem(12)};
    background-color: ${props => props.theme.color.primary};
    color: white;
    cursor: pointer;
    border: none;
    border-radius: ${rem(12)};
  }

`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const InfoLabel = styled.div`
  width: 200px;
  font-weight: bold;
  margin-bottom: ${rem(12)};
  margin-left: ${rem(12)};
`;

const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }
  .vouchers-wrapper {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-left: ${rem(12)};
  }

  .description-wrapper {
    margin-left: 12px;
    font-size: 14px;
  }
  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

class BillDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      sendOrderDateNotify: false,
      notifyCustomer: true,
      orderEmailConflictData: null,
      showFinDataPopUp: false,
      showFinLoading: false,
      searchBillId: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getKasaBillAction(getIdFromProps(this.props));

      const baseInfo = [
        {
          label: __('Dátum vytvorenia dokladu'),
          value: formatDate(moment(result.createdDate), FORMAT_HUMANE_DATETIME),
        },
        {
          label: __('Meno zákazníka'),
          value: (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${result.contact._id}`}
            >
              {result.contact.name} {result.contact.surname}
            </StyledA>
          ),
        },
        {
          label: __('Email'),
          value: result.contact.email,
        },
        {
          label: __('Predajna'),
          value: result.cr.name,
        },
        {
          label: __('Pokladna'),
          value: result.cr._id,
        },
        {
          label: __('Tel. číslo'),
          value: result.contact.phone,
        },
      ];

      let nav_status = 'ERROR';

      if (result.nav_error) {
        nav_status = 'ERROR';
      } else if (!result.nav_error && !result.nav_bill) {
        nav_status = 'NAV_VOID';
      } else if (result.nav_bill) {
        nav_status = 'OK';
      }

      const paymentInfo = [];

      result.data.payments.forEach(p => {
        paymentInfo.push({
          label: paymentsTypes(p.code),
          value: '',
        });
        paymentInfo.push({
          label: __('Suma'),
          value: formatPrice({
            amount: p.amount,
            currency: getCurrencyByFirm(this.props.firm),
          }),
        });
      });

      let nav_data = [];
      if (result.nav_bill) {
        nav_data = [
          {
            label: __('Číslo v navision'),
            value: result.nav_bill.No,
          },
          {
            label: __('Fiškalna pokladňa'),
            value: result.nav_bill.Fiscal_Cash_Desk_No,
          },
          {
            label: __('Číslo zákazníka'),
            value: result.nav_bill.Sell_to_Customer_No,
          },
          {
            label: __('Názov zákazníka'),
            value: result.nav_bill.Sell_to_Customer_Name,
          },
          {
            label: __('Sklad'),
            value: result.nav_bill.Location_Code,
          },
          {
            label: __('Číslo predajcu'),
            value: result.nav_bill.Salesperson_Code,
          },
        ];
      }

      this.setState({
        loading: false,
        submitPaymentLaoding: false,
        internalNoteSaving: false,
        data: Object.assign(result, {
          baseInfo,
          nav_status,
          paymentInfo,
          nav_data,
        }),
      });
    } catch (e) {
      this.setState({
        error: __('Predajný doklad sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  async sendToNavision() {
    try {
      this.setState({
        showSendNavisionLoading: true,
      });

      await API2.sendKasaBillToNavAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.fetchData();

      this.setState({
        showSendNavisionLoading: false,
        success: __('Doklad bol úspešne odoslaný do navision'),
      });
    } catch (e) {
      this.setState({
        error: __('Doklad sa nepodarilo odoslať do navision'),
        showSendNavisionLoading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return <ControllBar history={history} name={data._id} />;
  }

  renderRow(i, disabledEditButtons) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && !disabledEditButtons && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      data,
      loading,
      error,
      success,
      showSendNavisionLoading,
      showFinDataPopUp,
      searchBillId,
    } = this.state;

    const disabledEditButtons = false;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <React.Fragment>
            <HeaderLabel>
              <div className="text">{__('Stav predajného dokladu')}</div>
              <button
                onClick={() =>
                  this.setState({
                    showFinDataPopUp: true,
                    searchBillId: data.data.description,
                  })
                }
              >
                {__('Zobraziť doklad finančnej správy')}
              </button>
            </HeaderLabel>

            <div className="buttons-wrapper">
              {renderBillState(data.nav_status)}

              {data.nav_status !== 'OK' && (
                <div className="right">
                  <div className="button-wrapper">
                    <Button
                      loading={showSendNavisionLoading}
                      onClick={() => this.sendToNavision()}
                      primary
                    >
                      {__('Odoslať do navision')}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </React.Fragment>

          <HeaderLabel>
            <div className="text">{__('Informácie o účte')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.baseInfo &&
              data.baseInfo.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Spôsob platby')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.paymentInfo && (
              <div>
                {data.paymentInfo.map(i =>
                  this.renderRow(i, disabledEditButtons),
                )}
              </div>
            )}
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Navision data')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.nav_data &&
              data.nav_data.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>

          <HeaderLabel>
            <div className="text">{__('Položky')}</div>
          </HeaderLabel>
          <ProductsTable
            small
            products={data.products || []}
            showPager={false}
          />

          <HeaderLabel>
            <div className="text">{__('Kontrolné dáta')}</div>
          </HeaderLabel>

          {data.data_nav && (
            <div>
              <InfoLabel>Data NAV:</InfoLabel>
              <Textarea
                style={{
                  height: rem(200),
                  width: rem(1200),
                }}
                value={JSON.stringify(data.data_nav)}
                disabled
              />
            </div>
          )}

          {data.storno && (
            <div>
              <InfoLabel>Storno:</InfoLabel>
              <Textarea
                style={{
                  height: rem(200),
                  width: rem(1200),
                }}
                value={JSON.stringify(data.storno)}
                disabled
              />
            </div>
          )}

          {data.nav_error && (
            <div>
              <InfoLabel>Nav error:</InfoLabel>
              <Textarea
                style={{
                  height: rem(200),
                  width: rem(1200),
                }}
                value={JSON.stringify(data.nav_error)}
                disabled
              />
            </div>
          )}

          <PopUpWrapper
            display={showFinDataPopUp}
            onClose={() => this.setState({ showFinDataPopUp: false })}
            onEscClose={() => this.setState({ showFinDataPopUp: false })}
            message={__('Informácie o doklade finančnej správy')}
          >
            <FinPortalDetail
              billId={searchBillId}
              //onLoading={loading => this.setState({ showFinLoading: loading })}
              onError={e => this.setState({ showFinDataPopUp: false })}
            />
          </PopUpWrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

BillDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(BillDetail);
