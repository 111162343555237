import React from 'react';
import BaseTable from '../BaseTable';
import { __ } from '../../Utils';
import { searchProducts, searchStocks } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/stockProducts';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import API2 from '../../API2';
import { StyledA } from '../../Components/ReusableComponents';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';

class WarehouseList extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: '-Name' });
    this.headers = tableHeaders;
    this.segmentName = __('Sklady');
    this.accessors = ['name', 'quantity', 'ean', 'brand'];
    this.showBulkOperations = false;
    this.fixedHeader = true;
    this.showPagerForNavisionServices = true;
    this.state = {
      ...this.state,
      showFilter: false,
      showPdfExport: false,
      pdfExportLoading: false,
      stockId: 'WH001',
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'stockId',
          label: __('Sklady'),
          value: {
            value: 'WH001',
            label: 'Veľkosklad',
          },
          type: 'search-select',
          onSearch: q => searchStocks(this.props.firm),
        },
        {
          name: 'productId',
          label: __('Produkt'),
          value: '',
          type: 'search-select',
          onSearch: q => searchProducts(q, this.props.firm),
        },
        {
          name: 'ean',
          label: __('EAN'),
          value: '',
          type: 'input',
        },
        {
          name: 'quantity',
          label: __('Počet kusov'),
          value: '',
          type: 'number',
        },
      ],
    };
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, filters } = this.state;

      const filterToSend = {};
      let _stockId;

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.name === 'stockId') {
            _stockId = f.value.value;
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else if (f.type === 'number') {
            filterToSend[f.name] = Number(f.value);
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      const products = await API2.getProductsByStock(
        this.props.firm,
        _stockId,
        params,
      );

      const content = this.normalizeColumns(products.items);

      this.setState({
        content,
        stockId: _stockId,
        loading: false,
        limit: products.limit,
        offset: products.offset,
        nextOffset: products.limit + products.offset,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      acumulator.push({
        _id: item._id,
        name: _shapeData(
          <StyledA
            target="_blank"
            rel="noopener noreferrer"
            href={`${process.env.REACT_APP_CRM_BASE_URL}/products/${item._id}`}
          >
            {item.Name}
          </StyledA>,
        ),
        quantity: _shapeData(item.quantity),
        ean: _shapeData(item.Ean),
        brand: _shapeData(item.Brand_Code),
      });
      return acumulator;
    }, []);
    return result;
  }

  async downloadPDF() {
    const { stockId } = this.state;

    try {
      this.setState({
        pdfExportLoading: true,
      });

      const result = await API2.exportStockProductsToPDFAction(
        this.props.firm,
        stockId,
      );

      window.location.href = result.url;

      this.setState({
        pdfExportLoading: false,
        showDownloadPopup: false,
      });
    } catch (e) {
      console.log(e);
      this.setState({
        error: __('Pri vytváraní pdf sa vyskytla chyba'),
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Skladové zásoby')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
        <ControlBarButton
          onClick={() => this.setState({ showPdfExport: true })}
          icon={'plus'}
          name="export_pdf"
          small
          primary
          style={{ 'margin-right': '5px ' }}
        >
          {__('Export do PDF')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const { showPdfExport, pdfExportLoading } = this.state;

    return (
      <React.Fragment>
        {super.render()}
        <PopUpWrapper
          customWidth={440}
          display={showPdfExport}
          onClose={() => this.setState({ showPdfExport: false })}
          onEscClose={() => this.setState({ showPdfExport: false })}
          message={__('Exportovať skladové zásoby pre zvolený sklad?')}
          scroll={false}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showPdfExport: false })}
            onDismiss={() => this.downloadPDF()}
            dismissLoading={pdfExportLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Exportovať do PDF')}
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(WarehouseList);
