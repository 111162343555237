import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Button from 'oxyrion-ui/lib/Button';
import { __ } from '../../Utils';
import background from './123Kurierplaceholder.jpg';

const Wrapper = styled.div`
  padding: ${rem(8)};
  width: ${rem(424)};

  .paper-row {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
  }

  .paper {
    border: 1px solid black;
  }

  .button-wrapper {
    padding-top: 16px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
`;

const Part = styled.div`
  /* background: ${props => (props.selected ? '#737373' : 'white')}; */
  background-image: url(${props => (props.selected ? background : 'null')});
  background-size: 212px 300px;
  width: 50%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #eee; */
  cursor: pointer;
  :hover {
      background: ${props => !props.selected && '#eee'};
    }

`;

class SelectPaperPart extends Component {
  state = {
    part: null,
  };

  handleSelect(number) {
    this.setState({
      part: this.state.number === number ? null : number,
    });
  }

  render() {
    const { onSubmit } = this.props;
    const { part } = this.state;

    return (
      <Wrapper>
        <div className="paper">
          <div className="paper-row">
            <Part selected={part === 1} onClick={() => this.handleSelect(1)}>
              {part !== 1 ? 1 : ''}
            </Part>
            <Part selected={part === 2} onClick={() => this.handleSelect(2)}>
              {part !== 2 ? 2 : ''}
            </Part>
          </div>

          <div className="paper-row">
            <Part selected={part === 3} onClick={() => this.handleSelect(3)}>
              {part !== 3 ? 3 : ''}
            </Part>
            <Part selected={part === 4} onClick={() => this.handleSelect(4)}>
              {part !== 4 ? 4 : ''}
            </Part>
          </div>
        </div>
        <div className="button-wrapper">
          <Button
            disabled={!part}
            primary
            small
            onClick={() => {
              onSubmit(part);
              this.setState({
                part: null,
              });
            }}
          >
            {__('Potvrdiť')}
          </Button>
        </div>
      </Wrapper>
    );
  }
}

SelectPaperPart.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

SelectPaperPart.defaultProps = {};

export default SelectPaperPart;
