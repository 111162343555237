import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  formatPrice,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  __,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import API2 from '../../API2';
import ProductsTable from '../ProductsTable';
import FileViewDownload from '../../Components/FileViewDownload';
const MainWrapper = styled.div`
  padding-bottom: ${rem(200)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value {
    color: black;
    font-weight: bold;
  }
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
`;

const SmallHeaderLabel = styled(HeaderLabel)`
  font-size: ${rem(14)};
  justify-content: space-between;
`;

const ValueRow = styled(SmallHeaderLabel)`
  height: ${rem(30)};
  background: none;
  color: black;
`;

const InfoWrapper = styled.div`
  font-size: ${rem(12)};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(33%, 33%));
  grid-gap: 0rem;
  width: 100%;
  margin-bottom: ${rem(12)};

  .row {
    display: flex;
    height: ${rem(24)};
    align-items: center;
    margin-left: ${rem(12)};
  }

  .label {
    font-weight: bold;
    margin-right: ${rem(16)};
  }

  .value {
  }

  .button {
    color: ${props => props.theme.color.primary};
    cursor: pointer;
    text-decoration: underline;
    margin-left: ${rem(8)};
  }
`;

const SummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.m`
 flex-direction: row;
`};
`;

const SubWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.media.m`
  width: 50%;
`};
`;

class CashRegisterOrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      sendOrderDateNotify: false,
      notifyCustomer: true,
      orderEmailConflictData: null,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getCashRegisterOrderDetailAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      const shop = await API2.getShopAction(
        this.props.firm,
        result.cash_register.shopId,
      );

      const baseInfo = [
        {
          label: __('Dátum objednania'),
          value: formatDate(moment(result.order_date), FORMAT_HUMANE_DATETIME),
        },
        {
          label: __('Meno zákazníka'),
          value: (
            <StyledA
              target="_blank"
              rel="noopener noreferrer"
              href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${result.customer._id}`}
            >
              {result.customer.display_name}
            </StyledA>
          ),
        },
        {
          label: __('Email'),
          value: result.customer.email || '',
        },
        {
          label: __('Vytvorená cez'),
          value: result.created_from,
        },
        {
          label: __('Tel. číslo'),
          value: result.customer.phone || '',
        },
        {
          label: __('Dátum dodania'),
          value: formatDate(moment(result.estimate_delivery_date)),
        },
      ];

      const shopInfo = [
        {
          label: __('Názov pokladne'),
          value: result.cash_register.name || '',
        },
        {
          label: __('Názov predajne'),
          value: shop.name || '',
        },
        {
          label: __('Adresa predajne'),
          value: shop.address || '',
        },
      ];

      const documentInfo = [
        {
          label: __('Faktúra'),
          value: (
            <FileViewDownload
              name={result.invoice_id}
              type={'application/pdf'}
              history={this.props.history}
              downloadFunction={async () => {
                return API2.getSalesInvoicePdfAction(
                  this.props.firm,
                  result.invoice_id,
                );
              }}
              error={e => {
                this.setState({ error: e });
              }}
            />
          ),
        },
        {
          label: __('Dodací list'),
          value: (
            <FileViewDownload
              name={result.shipment_id}
              type={'application/pdf'}
              history={this.props.history}
              downloadFunction={async () => {
                return API2.getSalesShipmentPdfAction(
                  this.props.firm,
                  result.shipment_id,
                );
              }}
              error={e => {
                this.setState({ error: e });
              }}
            />
          ),
        },
      ];

      this.setState({
        loading: false,
        submitPaymentLaoding: false,
        internalNoteSaving: false,
        data: Object.assign(result, {
          baseInfo,
          shopInfo,
          documentInfo,
        }),
      });
    } catch (e) {
      this.setState({
        error: __('Objednávku sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    const { data } = this.state;

    return <ControllBar history={history} name={data._id} />;
  }

  renderRow(i, disabledEditButtons) {
    return (
      <div className="row">
        <div className="label">{i.label}:</div>
        <div className="value">{i.value}</div>
        {i.button && !disabledEditButtons && (
          <div className="button" onClick={() => i.button.onClick()}>
            {i.button.title}
          </div>
        )}
      </div>
    );
  }

  render() {
    const { data, loading, error, success } = this.state;

    const disabledEditButtons = false;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <HeaderLabel>
            <div className="text">{__('Objednávka a informácie o účte')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.baseInfo &&
              data.baseInfo.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Informácie o pokladni a predajni')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.shopInfo &&
              data.shopInfo.map(i => this.renderRow(i, disabledEditButtons))}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Doklady')}</div>
          </HeaderLabel>
          <InfoWrapper>
            {data.documentInfo &&
              data.documentInfo.map(i =>
                this.renderRow(i, disabledEditButtons),
              )}
          </InfoWrapper>
          <HeaderLabel>
            <div className="text">{__('Položky')}</div>
          </HeaderLabel>
          <ProductsTable
            small
            products={data.products || []}
            showPager={false}
          />
          <HeaderLabel>
            <div className="text">{__('Sumár')}</div>
          </HeaderLabel>
          <SummaryWrapper>
            <SubWrapper>
              <SmallHeaderLabel>
                <div className="text">{__('Celková cena bez DPH')}</div>
                <div className="value">{formatPrice(data.total_price)}</div>
              </SmallHeaderLabel>
              <ValueRow>
                <div className="text">{__('DPH')}</div>
                <div className="value">
                  {formatPrice({
                    amount:
                      data.total_price_vat.amount - data.total_price.amount,
                    currency: data.total_price.currency,
                  })}
                </div>
              </ValueRow>
              <SmallHeaderLabel>
                <div className="text">{__('Celková cena')}</div>
                <div className="value">{formatPrice(data.total_price_vat)}</div>
              </SmallHeaderLabel>
            </SubWrapper>
          </SummaryWrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

CashRegisterOrderDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CashRegisterOrderDetail);
