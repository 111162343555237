import { __e as __ } from '../Utils';

export default [
  {
    name: __('Číslo faktúry'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Pokladňa'),
    clickable: false,
    otherProps: {
      minWidth: 50,
    },
  },
  {
    name: __('Suma s DPH'),
    clickable: false,
    otherProps: {
      minWidth: 100,
    },
  },
  {
    name: __('Dátum'),
    clickable: true,
    sorterValue: 'created_date',
    otherProps: {
      minWidth: 100,
    },
  },
];
