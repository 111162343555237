import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { rem } from 'polished';
import { Icon } from 'oxyrion-ui/lib';
import { AnimatedFormMessageWrapper } from '../ReusableComponents';
import { __, formatDate, FORMAT_HUMANE_DATETIME } from '../../Utils';
import { connect } from '../../Store';

const MainWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${rem(5)};
`;

const Wrapper = styled.div`
  width: 95%;
  padding: ${rem(10)};
  background: #ff232338;
  color: black;
  border: 2px #a22b2b solid;
  border-radius: 4px;
`;

const ErrorLabel = styled.div`
  margin-right: ${rem(5)};
  color: #a22b2b;
`;

const Row = styled.div`
  display: flex;
  margin-top: ${rem(5)};
  margin-bottom: ${rem(5)};
  display: flex;
  align-items: center;
`;

const ShowMore = styled.div`
  color: white;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  background: #c50c0c69;
`;

const Text = styled.div`
  font-size: ${rem(15)};
`;

const IconWrapper = styled.div`
  padding: ${rem(4)};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: ${rem(15)};
`;

const ErrorDetailWrapper = styled.div`
  padding: ${rem(10)};
`;

const NewIssueButton = styled.div`
  background: red;
  padding: 8px;
  border-radius: 2px;
  color: white;
  cursor: pointer;
  margin-top: ${rem(10)};
`;

// const IconWrapperClose = styled(IconWrapper)``;

const defualtMessages = [
  {
    code: 404,
    message: __('Entita sa nenašla'),
  },
  {
    code: 409,
    message: __('Konfilkt'),
  },
  {
    code: 500,
    message: __('Chyba servra'),
  },
  {
    code: 403,
    message: __('Chybajú potrebné práva'),
  },
];

const gitlabAccesses = [
  'lucia.lamosova@dejmark.com',
  'dusan.pavlov@dejmark.com',
  'martin.marek@dejmark.com',
  'michal.hricisin@dejmark.com',
];

class ExpandedError extends React.Component {
  state = {
    showMore: false,
  };

  onCreateIssueClicked() {
    const { error, messagesForUser } = this.props;

    const baseURL = 'https://gitlab.bart.sk/oxyrion/crm.ui/issues/new?';
    const titleURL = '&issue[title]=';
    const descURL = '&issue[description]=';
    let title = '';
    const desc = __(
      'Stručný opis chyby (ideálne screenshot chybovej hlášky): ',
    );

    if (error.response) {
      title = `${__('Chybová hláška v EOM aplikácií')} - ${
        error.response.status
      } - ${this.getErrorMessage(
        error.response.status,
        error.response.statusText,
        messagesForUser,
      )}`;
    } else {
      title = `${__('Chybová hláška v EOM aplikácií')} - ${error.toString()}`;
    }

    const win = window.open(
      `${baseURL}${titleURL}${title}${descURL}${desc}`,
      '_blank',
    );
    win.focus();
  }

  getErrorMessage(code, name, messages) {
    let error = messages.find(m => m.code === code);

    if (!error) {
      error = defualtMessages.find(d => d.code === code);
    }

    if (error && error.name) {
      return error.find(e => e.name === name)
        ? error.find(e => e.name === name).message
        : __('Neznáma chyba');
    }

    return error && error.message ? error.message : __('Neznáma chyba');
  }
  render() {
    const { error, messagesForUser, customCodeId, errorTitle } = this.props;
    const { showMore } = this.state;
    return (
      <MainWrapper>
        {error.response ? (
          <Wrapper>
            {/* <IconWrapperClose>
              <Icon name="close" color="#a22b2b" size="l" />
            </IconWrapperClose> */}
            <Row>
              <IconWrapper>
                <Icon name="trash" color="#a22b2b" size="l" />
              </IconWrapper>
              <ErrorLabel>{__('Vyskytla sa chyba:')}</ErrorLabel>
              <Text>{`${errorTitle} - ${this.getErrorMessage(
                error.response.status,
                error.response.statusText,
                messagesForUser,
              )}`}</Text>
            </Row>
            <Line />
            <Row>
              <AnimatedFormMessageWrapper display={showMore}>
                <ErrorDetailWrapper>
                  <Row>
                    <ErrorLabel>{__('Kód:')}</ErrorLabel>
                    <Text>
                      {error.response && error.response.status
                        ? error.response.status
                        : ' - '}
                    </Text>
                  </Row>
                  <Row>
                    <ErrorLabel>{__('Interný kód chyby:')}</ErrorLabel>
                    <Text>{customCodeId}</Text>
                  </Row>

                  <Row>
                    <ErrorLabel>{__('Technický popis chyby:')}</ErrorLabel>
                    <Text>
                      {error.details && error.details.description
                        ? error.details.description
                        : ' - '}
                    </Text>
                  </Row>
                  <Row>
                    <ErrorLabel>{__('Aktuálna url:')}</ErrorLabel>
                    <Text> {window.location.href}</Text>
                  </Row>
                  <Row>
                    <ErrorLabel>{__('API URL:')}</ErrorLabel>
                    <Text>
                      {error.response && error.response.url
                        ? error.response.url
                        : ' - '}
                    </Text>
                  </Row>

                  <Row>
                    <ErrorLabel>{__('Používateľ: ')}</ErrorLabel>
                    <Text>{this.props.user.display_name}</Text>
                  </Row>

                  <Row>
                    <ErrorLabel>{__('Dátum: ')}</ErrorLabel>
                    <Text>{formatDate(moment(), FORMAT_HUMANE_DATETIME)}</Text>
                  </Row>

                  {gitlabAccesses.indexOf(this.props.user.email) > -1 && (
                    <Row>
                      <NewIssueButton
                        onClick={() => this.onCreateIssueClicked()}
                      >
                        {__('Vytvoriť gitlab ISSUE')}
                      </NewIssueButton>
                    </Row>
                  )}
                </ErrorDetailWrapper>
              </AnimatedFormMessageWrapper>
            </Row>
            <Row>
              <ShowMore
                onClick={() => {
                  this.setState({
                    showMore: !showMore,
                  });
                }}
              >{`${showMore ? 'Schovať' : 'Zobraziť'} ${__(
                'detail chyby',
              )}`}</ShowMore>
            </Row>
          </Wrapper>
        ) : (
          <Wrapper>
            <Row>
              <IconWrapper>
                <Icon name="trash" color="#a22b2b" size="l" />
              </IconWrapper>
              <ErrorLabel>{__('Vyskytla sa chyba:')}</ErrorLabel>
              <Text>{__('Chyba aplikácie')}</Text>
            </Row>
            <Line />

            <Row>
              <AnimatedFormMessageWrapper display={showMore}>
                <ErrorDetailWrapper>
                  <Row>
                    <ErrorLabel>{__('Popis:')}</ErrorLabel>
                    <Text>{error.toString()}</Text>
                  </Row>
                  <Row>
                    <ErrorLabel>{__('Interný kód chyby:')}</ErrorLabel>
                    <Text>{customCodeId}</Text>
                  </Row>
                  <Row>
                    <ErrorLabel>{__('Aktuálna url:')}</ErrorLabel>
                    <Text> {window.location.href}</Text>
                  </Row>

                  {gitlabAccesses.indexOf(this.props.user.email) > -1 && (
                    <Row>
                      <NewIssueButton
                        onClick={() => this.onCreateIssueClicked()}
                      >
                        {__('Vytvoriť gitlab ISSUE')}
                      </NewIssueButton>
                    </Row>
                  )}
                </ErrorDetailWrapper>
              </AnimatedFormMessageWrapper>
            </Row>
            <Row>
              <ShowMore
                onClick={() => {
                  this.setState({
                    showMore: !showMore,
                  });
                }}
              >{`${showMore ? 'Schovať' : 'Zobraziť'} ${__(
                'detail chyby',
              )}`}</ShowMore>
            </Row>
          </Wrapper>
        )}
      </MainWrapper>
    );
  }
}

ExpandedError.propTypes = {
  error: PropTypes.shape({
    response: PropTypes.shape({}),
    statusCode: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
  }).isRequired,
  messagesForUser: PropTypes.arrayOf({
    code: PropTypes.string,
    name: PropTypes.string,
    message: PropTypes.string,
  }),
  customCodeId: PropTypes.string,
  errorTitle: PropTypes.string,
};

ExpandedError.defaultProps = {
  messagesForUser: [],
  customCodeId: ' - ',
  errorTitle: 'Nastala chyba',
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(ExpandedError);
