import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import Loader from 'oxyrion-ui/lib/Loader';
import moment from 'moment';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import { formatDate, FORMAT_HUMANE_DATETIME, __ } from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import BarChart from '../../Components/BarChart';
import API2 from '../../API2';
import StatsTable from '../StatsTable';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import BaseTable from '../BaseTable';
import LineComposedChartComponent from '../../Components/LineComposedChart';

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ChartsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  ${({ theme }) => theme.media.m`
    flex-direction: row;
  `};
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: ${rem(1760)};
  width: 100%;
  padding: ${rem(16)};
  padding-top: ${rem(40)};
`;
const Space = styled.div`
  width: ${rem(24)};
  height: ${rem(24)};
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

class EshopSales extends BaseTable {
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      //dayStats: [],
      monthStats: [],
      weeksStats: [],
      productsStats: [],
      daysStats: [],
      ordersLoading: false,
      onlyFinished: true,
      showFilter: false,
      date: this.props.year,
      isWeatherOn: true,
      filters: [
        {
          name: 'fromDate',
          label: __('Dátum od'),
          value: '',
          type: 'date',
        },
        {
          name: 'toDate',
          label: __('Dátum do'),
          value: '',
          type: 'date',
        },
        {
          name: 'brand',
          label: __('Značka produktu'),
          value: '',
          type: 'search-select',
          onSearch: q => this.fetchBrand(q),
        },
      ],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  applyFilter() {
    this.fetchData();
  }

  async fetchData() {
    try {
      const { filters } = this.state;
      const filterToSend = {};

      this.setState({
        ordersLoading: true,
      });

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      if (!filterToSend.fromDate && !filterToSend.toDate) {
        filterToSend.year = this.props.year;
      }

      const params = Object.assign({}, filterToSend);

      const [
        { months, weeks, days },
        { daysOfWeek },
        { products },
        { products: productsByPrice },
        weeklyWeather,
        monthlyWeather,
        dailyWeather,
      ] = await Promise.all([
        API2.getEshopOrdersStatsAction(this.props.firm, params),
        API2.getDailySalesDataAction(this.props.firm, params),
        API2.getTopProductsSalesEshopAction(
          this.props.firm,
          Object.assign(params, { sorter: 'COUNT' }),
        ),
        API2.getTopProductsSalesEshopAction(
          this.props.firm,
          Object.assign(params, { sorter: 'PRICE' }),
        ),
        API2.getWeatherByWeekAction(this.props.firm, {
          city: this.props.firm === 'SK' ? 'Martin' : 'Praha',
          year: this.props.year,
        }),
        API2.getWeatherByMonthAction(this.props.firm, {
          city: this.props.firm === 'SK' ? 'Martin' : 'Praha',
          year: this.props.year,
        }),
        API2.getWeatherByDayAction(this.props.firm, {
          city: this.props.firm === 'SK' ? 'Martin' : 'Praha',
          year: this.props.year,
        }),
      ]);

      // const daysOfCurrentWeek = getDaysOfCurrentWeek();
      // const daysWithData = daysOfCurrentWeek.map(d => {
      //   const dateInData = week.find(w => d.isSame(moment(w.name), 'day'));
      //   return {
      //     name: d.format('dd'),
      //     amount: dateInData ? dateInData.amount : 0,
      //   };
      // });

      this.setState({
        date:
          filterToSend.year ||
          `${formatDate(
            moment(filterToSend.fromDate),
            FORMAT_HUMANE_DATETIME,
          )} - ${formatDate(
            moment(filterToSend.toDate),
            FORMAT_HUMANE_DATETIME,
          )}`,
        //dayStats: daysWithData,
        monthStats: this.normalizeMonthlyStats(monthlyWeather, months),
        weeksStats: this.normalizeWeeklyStats(weeklyWeather, weeks),
        daysStats: this.normalizeDailyStats(dailyWeather, days),
        daysOfWeek: daysOfWeek.map(m =>
          Object.assign(m, { name: moment(m.name, 'E').format('ddd') }),
        ),
        ordersLoading: false,
        productsStats: products.map(ps =>
          Object.assign(ps, { count: ps.eshop_sold_count }),
        ),
        productsByPrice: productsByPrice.map(ps =>
          Object.assign(ps, { count: ps.eshop_sold_count }),
        ),
      });
    } catch (e) {
      console.log(e);
    }
  }

  normalizeMonthlyStats(weather, stats) {
    const groupItems = stats.map(s => {
      const getWeather = weather.find(w => w.month === s.name);
      const res = Object.assign(s, {
        ...getWeather,
        name: moment(s.name, 'MM').format('MMM'),
      });

      return res;
    });

    return groupItems;
  }

  normalizeWeeklyStats(weather, stats) {
    const groupItems = stats.map(s => {
      const formatName = moment(s.raw, 'YYYY-ww').format('ww');
      const getWeather = weather.find(
        w =>
          moment(`${w.year}-${w.week}`, 'YYYY-ww').format('ww') === formatName,
      );
      const res = Object.assign(s, {
        ...getWeather,
        name: formatName,
      });

      return res;
    });

    return groupItems;
  }

  normalizeDailyStats(weather, stats) {
    const groupItems = stats.map(s => {
      const formatName = moment(s.name, 'YYYY-MM-DD').format('DD/MM');
      const getWeather = weather.find(
        w => moment(w.date, 'YYYY-MM-DD').format('DD/MM') === formatName,
      );
      const res = Object.assign(s, {
        ...getWeather,
        name: formatName,
      });

      return res;
    });

    return groupItems;
  }

  async fetchBrand(query) {
    try {
      return API2.getBrandsAction(this.props.firm, { q: query }).then(res => {
        return {
          options: res.brands.map(item => ({
            value: item,
            label: item,
            raw: item,
          })),
        };
      });
    } catch (e) {
      return [];
    }
  }

  currentWeekString() {
    const currentDate = moment();

    const weekStart = currentDate.clone().startOf('isoWeek');
    const weekEnd = currentDate.clone().endOf('isoWeek');

    return `${weekStart.format('Od MMM')} - ${weekEnd.format('Do MMM')}`;
  }

  renderControlBar() {
    const { history } = this.props;
    const { showFilter } = this.state;

    return (
      <ControllBar
        history={history}
        name={__('Štatistiky predaja - Eshop')}
        // onChange={val => this.loadSearchRrsult(val)}
      >
        <ControlBarButton
          onClick={() =>
            this.setState({
              showFilter: !showFilter,
            })
          }
          icon={showFilter ? 'arrow-right' : 'arrow-down'}
          name="show_filter"
        >
          {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  render() {
    const {
      ordersLoading,
      monthStats,
      //dayStats,
      daysOfWeek,
      productsStats,
      showFilter,
      weeksStats,
      date,
      daysStats,
      productsByPrice,
    } = this.state;

    if (ordersLoading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}
        <AnimatedFormMessageWrapper display={showFilter}>
          {this.renderFilters()}
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <Wrapper>
            <ChartsWrapper>
              <LineComposedChartComponent
                data={monthStats}
                title={__('Predaje za mesiac v roku')}
                subTitle={date}
                appLang={this.props.appLang}
                responsive={false}
              />

              {/* <Space />
               <LineChart
                data={dayStats}
                title={__('Predaje počas týždna')}
                subTitle={this.currentWeekString()}
                appLang={this.props.appLang}
                responsive={false}
              /> */}
            </ChartsWrapper>
            <Space />
            <ChartsWrapper>
              <LineComposedChartComponent
                data={weeksStats}
                title={__('Predaje za týžden v roku')}
                subTitle={date}
                appLang={this.props.appLang}
                responsive={false}
              />
            </ChartsWrapper>

            <Space />
            <ChartsWrapper>
              <LineComposedChartComponent
                data={daysStats}
                title={__('Predaje za dni v roku')}
                subTitle={date}
                appLang={this.props.appLang}
                responsive={false}
              />
            </ChartsWrapper>

            <Space />
            <ChartsWrapper>
              <BarChart
                data={daysOfWeek}
                title={__('Predaje za deň v týždni v roku')}
                subTitle={date}
                appLang={this.props.appLang}
                responsive={false}
              />
            </ChartsWrapper>
            <Space />
            <HeaderLabel>
              <div className="text">
                {`${__('TOP 10 najpredavanejších produktov')} ${
                  date !== this.props.year ? `(${date})` : ''
                }`}
              </div>
            </HeaderLabel>
            <StatsTable
              small
              data={productsStats}
              showPager={false}
              showPricesColumn={true}
            />
            <Space />
            <HeaderLabel>
              <div className="text">
                {`${__('TOP 10 najpredavanejších produktov podľa ceny')} ${
                  date !== this.props.year ? `(${date})` : ''
                }`}
              </div>
            </HeaderLabel>
            <StatsTable
              small
              data={productsByPrice}
              showPager={false}
              showPricesColumn={true}
            />
          </Wrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

EshopSales.propTypes = {
  history: PropTypes.shape({}).isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(EshopSales);
