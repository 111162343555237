import { rem } from 'polished';
import React, { PureComponent } from 'react';
import ReactTable from 'react-table';
import styled from 'styled-components';
import {
  formatPrice,
  __,
  renderLink,
  getPaymentStateLabel,
  getOrderEomStateLabel,
  getOrderStateLabel,
} from '../../Utils';
import { Centerer } from '../ReusableComponents';

const Wrapper = styled.div`
  .ReactTable {
    border: none;
    border-radius: 8px;
    height: ${rem(300)}
  }

  .ReactTable.-striped .rt-tr.-odd {
    background: #f8f1f8;
  }

  .ReactTable .rt-thead {
    overflow-y: scroll;
  }
`;

const NoData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: ${rem(120)};
  color: #6f6f6f;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 1px;
  font-size: 14px;
  font-weight: bold;
  color: #434245;
  margin-top: ${rem(6)};
  margin-bottom: ${rem(8)};
`;

const StyledTd = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2px;
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 14px;
  color: ${({ isComp }) => (isComp ? '#ee6500' : '#434245')};
  font-weight: ${({ isComp }) => (isComp ? '700' : '500')};
`;

const StyledA = styled.a`
  color: ${props => props.theme.color.primary};
  font-weight: bold;
  position: relative;
`;

export default class DailyOrdersTable extends PureComponent {
  useCustomheader(columns) {
    return columns.map(i => ({
      Header: () => <div>{i.name}</div>,
      ...i,
    }));
  }

  createColumns() {
    return [
      {
        accessor: '_id',
        Header: () => <StyledHeader>{__('Číslo objednávky')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              {renderLink(`./orders/${props.value}`, props.value, true, 16)}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'customer',
        Header: () => <StyledHeader>{__('Zákazník')}</StyledHeader>,
        Cell: props => {
          return (
            <StyledTd>
              {props.value._id ? (
                <StyledA
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`${process.env.REACT_APP_CRM_BASE_URL}/${this.props.firm}/contacts/${props.value._id}`}
                >
                  {props.value.email}
                </StyledA>
              ) : (
                `${props.value.email} - ${__('Odstranený')}`
              )}
            </StyledTd>
          );
        },
      },
      {
        accessor: 'payment_state',
        Header: () => <StyledHeader>{__('Stav platby')}</StyledHeader>,
        Cell: props => {
          return <Centerer>{getPaymentStateLabel(props.value)}</Centerer>;
        },
      },
      {
        accessor: 'eom_state',
        Header: () => <StyledHeader>{__('Stav objednávky')}</StyledHeader>,
        Cell: props => {
          return (
            <Centerer>
              {getOrderEomStateLabel(props.value) ||
                getOrderStateLabel(props.original.order_state)}
            </Centerer>
          );
        },
      },
      {
        accessor: 'total_price',
        Header: () => <StyledHeader>{__('Cena bez DPH')}</StyledHeader>,
        Cell: props => {
          return <StyledTd>{formatPrice(props.value)}</StyledTd>;
        },
      },
    ];
  }

  render() {
    const { data, loading } = this.props;

    return (
      <Wrapper>
        <ReactTable
          {...this.props}
          resizable={false}
          pageSize={data.length}
          columns={this.useCustomheader(this.createColumns())}
          data={data}
          showPagination={false}
          loading={loading}
          NoDataComponent={() => (
            <NoData>{__('Nie su žiadné nové objednávky')}</NoData>
          )}
          className="-striped"
        />
      </Wrapper>
    );
  }
}
