import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { __ } from '../../Utils';
import DatePicker from '../DatePicker';

const StyledDatePicker = styled(DatePicker)`
  max-width: ${rem(200)};
`;

const StyledTimePicker = styled(DatePicker)`
  max-width: ${rem(200)};
`;

const Wrapper = styled.div`
  display: flex;
  max-width: ${rem(480)};
`;

class DateAndTimerPicker extends React.Component {
  render() {
    return (
      <Wrapper>
        <StyledDatePicker
          placeholderText={__(this.props.datePlaceHolder)}
          dateFormat="dd DD.MM.YYYY"
          error={this.props.error}
          disabled={this.props.disabled}
          selected={this.props.value}
          onChange={e => {
            this.props.onChange(e);
          }}
        />

        {this.props.showTimeSelect && (
          <StyledTimePicker
            placeholderText={__(this.props.timePlaceHolder)}
            disabled={this.props.disabled}
            error={this.props.error}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={30}
            dateFormat="HH:mm"
            timeFormat="HH:mm"
            timeCaption="Time"
            selected={this.props.value}
            onChange={e => {
              this.props.onChange(e);
            }}
          />
        )}
      </Wrapper>
    );
  }
}

DateAndTimerPicker.propTypes = {
  timePlaceHolder: PropTypes.string,
  datePlaceHolder: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  showTimeSelect: PropTypes.bool,
};

DateAndTimerPicker.defaultProps = {
  timePlaceHolder: '',
  datePlaceHolder: '',
  error: false,
  disabled: false,
  value: undefined,
  showTimeSelect: true,
};

export default DateAndTimerPicker;
