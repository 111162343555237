import React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {
  Input,
  Button,
  Select,
  Label,
  Icon,
  // Checkbox,
  Message,
  Loader,
} from 'oxyrion-ui/lib';
import LabeledCheckbox from 'oxyrion-ui/lib/LabeledCheckbox';
import { Table, Tbody, Thead, Tr, Th, Td } from 'oxyrion-ui/lib/Table';
import { rem } from 'polished';
import PropTypes from 'prop-types';
import {
  __,
  formatPriceForApi,
  formatDate,
  FORMAT_HUMANE_DATETIME,
  translateCampaignStatus,
  getCurrencyByFirm,
} from '../../Utils';
import { connect } from '../../Store';
import { FlexBoxStart } from '../../Components/ReusableComponents';
import SearchableSelect from '../../Components/SearchableSelect';
import DatePicker from '../../Components/DatePicker';
import campaignLabels from '../../ContentConfig/campaingsLabels';
import GreyBox from '../../Components/GreyBox';
import API2 from '../../API2';

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 29% 0;
`;

const CustomSelect = styled(Select)`
  width: 100%;
  margin: ${rem(10)} 0;
  padding: 0;
`;

const IconWrapper = styled.div`
  cursor: pointer;
  width: ${rem(20)};
  margin-right: ${rem(10)};
`;

// const Wrapper = styled.div``;

const Content = styled.div`
  padding: ${rem(10)};
  width: calc(100% - 20px);
  padding-bottom: ${rem(200)};
`;

const SectionWrapper = styled.div`
  margin-top: ${rem(20)};
  margin-bottom: ${rem(20)};
  max-width: ${rem(800)};
`;

const CampaignMWarraper = styled.div`
  max-width: ${rem(535)};
`;

const InsideContentWrapper = styled.div`
  display: flex;
`;

const StyledDatePicker = styled(DatePicker)`
  max-width: 90%;
`;

const StyledLabel = styled(Label)`
  min-width: ${rem(80)};
`;

const StyledCustomFlexBoxStart = styled.div`
  justify-content: start;
  display: flex;
`;

const ValueInputWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledInput = styled(Input)`
  max-width: ${rem(100)};
  margin-right: ${rem(10)};
`;

const Percent = styled.div`
  margin-left: ${rem(20)};
  font-size: ${rem(15)};
`;

const DeleteLine = styled(Td)`
  :last-of-type {
    width: 1% !important;
  }
`;

const DatePickerWrapper = styled.div`
  max-width: ${rem(150)};
  margin-right: ${rem(10)};
`;

const StyledButton = styled(Button)`
  float: left;
  margin-top: ${rem(10)};
`;

const DownloadButton = styled(Button)`
  margin-top: ${rem(20)};
  margin-left: ${rem(10)};
`;

const TextSmall = styled.div`
  color: #676565;
  font-size: 14px;
  margin-left: 5px;
`;

const StyledLink = styled.a`
  top: ${rem(-3)};
  position: relative;
  cursor: pointer;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 2.5rem;
  outline: 0;
  border: none;
  background: #1d70b8;
  color: #fff;
  vertical-align: baseline;
  padding: 0 2.0625rem;
  text-transform: none;
  text-shadow: none;
  font-weight: 500;
  line-height: 1em;
  font-style: normal;
  font-size: 0.875rem;
  -webkit-text-decoration: none;
  text-decoration: none;
  border-radius: 0.25rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Roboto', sans-serif;
  -webkit-transition: opacity 0.1s ease, background-color 0.1s ease,
    color 0.1s ease, box-shadow 0.1s ease, background 0.1s ease;
  transition: opacity 0.1s ease, background-color 0.1s ease, color 0.1s ease,
    box-shadow 0.1s ease, background 0.1s ease;
  box-shadow: 0 0.1875rem 0.75rem #1d70b8;
  min-height: 1.875rem;
`;

const Space = styled.div`
  width: ${rem(16)};
  height: ${rem(16)};
`;

const Hint = styled.div`
  font-size: 11px;
  color: #929292;
`;

const SmallNumberInput = styled(Input)`
  width: 60px;
`;

class CampaignDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      showConfirmDialog: false,
      customerTypes: [],
      loading: true,
      customers: [],
      status: __('Na schválenie'),
      campaignName: {
        name: '',
        error: false,
      },
      validFrom: {
        error: false,
        value: moment(),
      },
      publishedFrom: { error: false, value: moment() },
      validTo: {
        value: undefined,
      },
      campaignM: {
        usage: 'one-time',
        codeGenerated: false,
        codeError: false,
        code: '',
        discountType: 'flat',
        discountWithVat: true,
        discount: 0,
        discounts: [
          {
            valueFrom: '',
            discountPercent: '',
          },
        ],
      },
      alt1: {
        all: {
          value: 0,
        },
        items: [
          {
            product: {},
            brand: {},
            replace: false,
            value: 0,
          },
        ],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
            freeProductPrice: 0,
            freeProductDiscount: 0,
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
            productPrice: 0,
            productDiscount: 0,
          },
        ],
      },
      tempCustomerType: '',
      tempCustomerSubType: '',
      tempCustomer: undefined,
      selectedCampaign: {
        type: '',
      },
      newsletter: '',
      showDownloadPopup: false,
      pdfLoader: false,
      selectedType: 'customer',
      marketingList: null,
      refreshTable: false,
    };
  }

  componentDidMount() {
    const { campaignId } = this.props;

    if (campaignId && campaignId !== 'new') {
      this.fetchCampaignData(campaignId);
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchProduct(id) {
    const { firm } = this.props;
    try {
      const product = await API2.getProductAction(firm, id);

      return {
        product,
        label: product.Description,
        value: product._id,
        code: {
          label: product.No,
          value: product._id,
        },
      };
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie kampane nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Produkt kampane sa nenašiel'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }

      return {};
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  async fetchCampaignData(id) {
    const { firm } = this.props;
    const { selectedCampaign, alt1, alt2, alt3 } = this.state;

    try {
      const campaign = await API2.getCampaignAction(firm, id);

      selectedCampaign.type = campaign.campaign_alternative;

      let customers;
      let items;
      let campaignM;
      let customerTypes = [];
      const validFrom = {
        error: false,
        value: undefined,
      };
      const validTo = {
        value: undefined,
      };

      const publishedFrom = {
        error: false,
        value: undefined,
      };

      const campaignName = {
        name: campaign.name,
      };

      let publishedTo;

      if (campaign.customer_ids) {
        customers = await Promise.all(
          campaign.customer_ids.map(customerId =>
            API2.getCustomerAction(firm, customerId),
          ),
        );
      }

      if (campaign.customer_types) {
        customerTypes = campaign.customer_types.map(type => {
          return {
            customerType: type.type,
            customerSubType: type.subtype,
          };
        });
      }

      if (campaign.data && campaign.data.products) {
        items = await Promise.all(
          campaign.data.products.map(async product => {
            return {
              product: product.product_id
                ? await this.fetchProduct(product.product_id || product.value)
                : {},
              brand: product.brand_id
                ? {
                    value: product.brand_id,
                    label: product.brand_id,
                    brand: product.brand_id,
                  }
                : {},
              freeProduct:
                product.product &&
                (await this.fetchProduct(product.product.product_id)),
              freeProductPrice:
                product.product && product.product.discount_price.amount,
              freeProductDiscount:
                product.product && product.product.discount_percent,
              replace:
                product.replace_general_discount &&
                product.replace_general_discount,
              value: product.discount_percent && product.discount_percent,
              minValue: product.purchase_value && product.purchase_value.amount,
              productPrice:
                product.discount_price && product.discount_price.amount,
              productDiscount:
                product.discount_percent && product.discount_percent,
            };
          }),
        );
      }

      customers = customers.map(customer => {
        return {
          customer,
          label: customer.name,
          value: customer._id,
        };
      });

      if (campaign.campaign_alternative === 'D') {
        alt1.all.value = campaign.discount;
      } else if (
        campaign.campaign_alternative === 'F' ||
        campaign.campaign_alternative === 'K'
      ) {
        alt1.items = items;
      } else if (
        campaign.campaign_alternative === 'L' ||
        campaign.campaign_alternative === 'H' ||
        campaign.campaign_alternative === 'G'
      ) {
        alt2.items = items;
      } else if (
        campaign.campaign_alternative === 'I' ||
        campaign.campaign_alternative === 'J'
      ) {
        alt3.items = items;
        alt3.minValue = campaign.data.purchase_value.amount;
      } else if (campaign.campaign_alternative === 'M') {
        campaignM = campaign.data;
      }

      if (campaign.valid_from) {
        validFrom.value = moment(campaign.valid_from);
      }

      if (campaign.valid_to) {
        validTo.value = moment(campaign.valid_to);
      }

      if (campaign.publish_from) {
        publishedFrom.value = moment(campaign.publish_from);
      }

      if (campaign.publish_to) {
        publishedTo = moment(campaign.publish_to);
      }

      this.setState({
        selectedCampaign,
        customerTypes,
        customers,
        alt1,
        alt2,
        campaignM,
        alt3,
        validFrom,
        validTo,
        publishedFrom,
        publishedTo,
        campaignName,
        newsletter: campaign.newsletter,
        marketingList: campaign.marketingList,
        selectedType: campaign.marketingList ? 'marketingList' : 'customer',
      });
    } catch (e) {
      switch (e.response.status) {
        case 403:
          this.setState({
            error: __('Na zobrazenie kampane nemáte potrebné práva.'),
          });
          break;
        case 404:
          this.setState({
            error: __('Kampan sa nenašla'),
          });
          break;
        default:
          this.setState({
            error: __('Pri načítavaní dát sa vyskytla chyba'),
          });
          break;
      }
    } finally {
      this.setState({
        loading: false,
      });
    }
  }

  togglePopUp() {
    this.props.closePopup();
    this.setState({
      success: false,
      showConfirmDialog: false,
      customerTypes: [],
      loading: false,
      customers: [],
      campaignName: {
        name: '',
        error: false,
      },
      validFrom: {
        error: false,
        value: moment(),
      },
      publishedFrom: { error: false, value: moment() },
      validTo: {
        value: undefined,
      },
      alt1: {
        all: {
          value: 0,
        },
        items: [
          {
            product: {},
            brand: {},
            replace: false,
            value: 0,
          },
        ],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
            freeProductPrice: 0,
            freeProductDiscount: 0,
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
            productPrice: 0,
            productDiscount: 0,
          },
        ],
      },
      tempCustomerType: '',
      tempCustomerSubType: '',
      tempCustomer: undefined,
      selectedCampaign: {
        type: '',
      },
    });
  }

  handleTypeChange(newSelectedType) {
    this.setState({
      selectedType: newSelectedType,
    });
  }

  handleCampaignMChange(field, value) {
    const { campaignM } = this.state;

    campaignM[field] = value;

    if (field === 'codeGenerated' && value === true) {
      campaignM.code = '';
    }

    if (field === 'discountType' && value === 'progressive') {
      campaignM.discounts = [
        {
          discountPercent: '',
          valueFrom: '',
        },
      ];
    }
    this.setState({ campaignM });
  }

  handleProgresiveDiscountChange(field, value, index) {
    const { campaignM } = this.state;

    campaignM.discounts[index][field] = value;

    if (index > 0) {
      if (
        Number(campaignM.discounts[index].valueFrom) <=
        Number(campaignM.discounts[index - 1].valueFrom)
      ) {
        campaignM.discounts[index].error = true;
      } else {
        campaignM.discounts[index].error = false;
      }
    } else {
      campaignM.discounts[index].error = false;
    }

    if (
      campaignM.discounts[index].valueFrom > 0 &&
      campaignM.discounts[index].discountPercent > 0 &&
      !campaignM.discounts[index + 1] &&
      !campaignM.discounts[index].error
    ) {
      campaignM.discounts.push({
        valueFrom: '',
        discountPercent: '',
      });
    }

    this.setState({
      campaignM,
    });
  }

  handleChange(field, index, value) {
    const {
      customerTypes,
      customers,
      alt1,
      alt2,
      alt3,
      campaignName,
      selectedCampaign,
      selectedCampaignTemp,
      validFrom,
      validTo,
      publishedFrom,
      tempCustomer,
    } = this.state;
    let {
      publishedTo,
      tempCustomerType,
      tempCustomerSubType,
      newsletter,
      marketingList,
      refreshTable,
    } = this.state;
    if (field === 'customerTypes') {
      tempCustomerType = value.target.value;
    } else if (field === 'campaignName') {
      campaignName.name = value.target.value;
    } else if (field === 'customerSubTypes') {
      tempCustomerSubType = value.target.value;

      customerTypes.push({
        customerType: tempCustomerType,
        customerSubType: tempCustomerSubType,
      });

      tempCustomerType = '';
      tempCustomerSubType = '';
    } else if (field === 'customer') {
      // tempCustomer = value;
      customers.push(value);
    } else if (field === 'validFrom') {
      validFrom.value = value;
    } else if (field === 'validTo') {
      validTo.value = value;
    } else if (field === 'publishedFrom') {
      publishedFrom.value = value;
    } else if (field === 'publishedTo') {
      publishedTo = value;
    } else if (field === 'selectedCampaign') {
      selectedCampaign.type = value.target.value;
    } else if (field === 'newsletter') {
      newsletter = value;
    } else if (field === 'alt1.all.value') {
      alt1.all.value = value.target.value;
    } else if (field === 'alt1.all.replace') {
      alt1.all.replace = !alt1.all.replace;
    } else if (field === 'alt1.product') {
      alt1.items[index].product = value;
    } else if (field === 'alt1.product.value') {
      alt1.items[index].value = value.target.value;
    } else if (field === 'alt1.brand') {
      alt1.items[index].brand = value;
    } else if (field === 'alt1.product.replace') {
      alt1.items[index].replace = !alt1.items[index].replace;
    } else if (field === 'alt2.product') {
      alt2.items[index].product = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt2.items[index].product.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt2.freeProduct') {
      alt2.items[index].freeProduct = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt2.items[index].freeProduct.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt2.freeProduct.price') {
      alt2.items[index].freeProductPrice = value.target.value;
    } else if (field === 'alt2.freeProduct.discount') {
      alt2.items[index].freeProductDiscount = value.target.value;
    } else if (field === 'alt3.product') {
      alt3.items[index].product = {
        ...value,
        label: value ? value.product.Description : '',
        value: value ? value.product._id : '',
      };
      alt3.items[index].product.code = {
        label: value ? value.product.No : '',
        value: value ? value.value._id : '',
      };
    } else if (field === 'alt3.product.price') {
      alt3.items[index].productPrice = value.target.value;
    } else if (field === 'alt3.product.discount') {
      alt3.items[index].productDiscount = value.target.value;
    } else if (field === 'alt3.minValue') {
      alt3.minValue = value.target.value;
    } else if (field === 'marketingList') {
      marketingList = value;
      refreshTable = !refreshTable;
    }

    this.setState({
      customers,
      customerTypes,
      validFrom,
      validTo,
      publishedTo,
      publishedFrom,
      alt1,
      tempCustomer,
      tempCustomerType,
      tempCustomerSubType,
      selectedCampaign,
      campaignName,
      selectedCampaignTemp,
      newsletter,
      marketingList,
      refreshTable,
    });
  }

  fetchCustomers(query) {
    try {
      return API2.getCustomersAction(this.props.firm, { q: query }).then(
        res => {
          return res.items.map(item => ({
            value: item._id,
            label: item.name,
            customer: item,
          }));
        },
      );
    } catch (e) {
      return [];
    }
  }

  fetchProducts(query) {
    try {
      return API2.getProductsAction(this.props.firm, {
        q: query,
        limit: 10,
      }).then(res => {
        return res.products.map(item => ({
          value: item._id,
          label: `${item.Description} - ${item._id}`,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  fetchProductsByEAN(query) {
    try {
      return API2.getProductsAction(this.props.firm, { q: query }).then(res => {
        return res.products.map(item => ({
          value: item.No,
          label: item._id,
          product: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  async fetchBrand(query) {
    try {
      return API2.getBrandsAction(this.props.firm, { q: query }).then(res => {
        return res.brands.map(item => ({
          value: item,
          label: item,
          brand: item,
        }));
      });
    } catch (e) {
      return [];
    }
  }

  addCustomerGroup() {
    const { customerTypes } = this.state;
    const { tempCustomerType, tempCustomerSubType } = this.state;

    customerTypes.push({
      customerType: tempCustomerType,
      customerSubType: tempCustomerSubType,
    });

    this.setState({
      customerTypes,
      tempCustomerType: '',
      tempCustomerSubType: '',
    });
  }

  addCustomer() {
    const { customers } = this.state;
    let { tempCustomer } = this.state;
    if (tempCustomer.value) {
      customers.push(tempCustomer);
      tempCustomer = undefined;
      this.setState({
        customers,
        tempCustomer,
      });
    }
  }

  addProduct(alt) {
    if (alt === 1) {
      const { alt1 } = this.state;
      alt1.items.push({
        product: {},
        brand: {},
        replace: false,
        value: 0,
      });
      this.setState({
        alt1,
      });
    } else if (alt === 2) {
      const { alt2 } = this.state;
      alt2.items.push({
        product: {},
        freeProduct: {},
        freeProductPrice: 0,
        freeProductDiscount: 0,
      });
      this.setState({
        alt2,
      });
    } else if (alt === 3) {
      const { alt3 } = this.state;
      alt3.items.push({
        product: {},
      });
      this.setState({
        alt3,
      });
    }
  }

  deleteProduct(alt, index) {
    if (alt === 1) {
      const { alt1 } = this.state;
      alt1.items.splice(index, 1);
      this.setState({
        alt1,
      });
    } else if (alt === 2) {
      const { alt2 } = this.state;
      alt2.items.splice(index, 1);
      this.setState({
        alt2,
      });
    } else if (alt === 3) {
      const { alt3 } = this.state;
      alt3.items.splice(index, 1);
      this.setState({
        alt3,
      });
    }
  }

  deleteCustomer(index) {
    const { customers } = this.state;
    customers.splice(index, 1);
    this.setState({
      customers,
    });
  }

  deleteCustomerType(index) {
    const { customerTypes } = this.state;
    customerTypes.splice(index, 1);
    this.setState({
      customerTypes,
    });
  }

  toggleChangeType() {
    this.setState({
      showConfirmDialog: !this.state.showConfirmDialog,
    });
  }

  changeCampaingType() {
    const { selectedCampaign, selectedCampaignTemp } = this.state;

    selectedCampaign.type = selectedCampaignTemp;

    this.setState({
      selectedCampaign,
      showConfirmDialog: false,
      alt1: {
        all: {
          value: 4,
          replace: false,
        },
        products: [{}],
      },
      alt2: {
        items: [
          {
            product: {},
            freeProduct: {},
          },
        ],
      },
      alt3: {
        minValue: 0,
        items: [
          {
            product: {},
          },
        ],
      },
    });
  }

  isMutlipleByAlt(alt) {
    return ['alt1.product', 'alt3.product'].includes(alt);
  }

  checkFieldsValidation() {
    const { campaignName, validFrom, publishedFrom } = this.state;
    let isValid = true;

    if (campaignName.name.length <= 0) {
      campaignName.error = true;
      isValid = false;
    } else {
      campaignName.error = false;
    }

    if (!validFrom.value) {
      validFrom.error = true;
      isValid = false;
    } else {
      validFrom.error = false;
    }

    if (!publishedFrom.value) {
      publishedFrom.error = true;
      isValid = false;
    } else {
      publishedFrom.error = false;
    }

    if (!isValid) {
      this.setState({
        campaignName,
        publishedFrom,
        validFrom,
        error: __('Nevyplnená hodnota'),
      });
    } else {
      this.setState({
        campaignName,
        publishedFrom,
        validFrom,
        error: undefined,
      });
    }

    return isValid;
  }

  actualTypeCheck() {
    const { alt1, alt2, alt3, selectedCampaign, campaignM } = this.state;

    let isValid = true;

    switch (selectedCampaign.type) {
      case 'D':
        if (alt1.all.value < 0) {
          isValid = false;
        }
        break;
      case 'F':
        alt1.items.forEach(item => {
          if ((!item.brand.brand && !item.product.product) || item.value <= 0) {
            isValid = false;
          }
        });

        break;
      case 'K':
        alt1.items.forEach(item => {
          if ((!item.brand.brand && !item.product.product) || item.value <= 0) {
            isValid = false;
          }
        });
        break;
      case 'G':
        alt2.items.forEach(item => {
          if (
            !item.product.product ||
            !item.freeProduct.product ||
            item.freeProductPrice <= 0
          ) {
            isValid = false;
          }
        });
        break;
      case 'H':
        alt2.items.forEach(item => {
          if (
            !item.product.product ||
            !item.freeProduct.product ||
            item.freeProductDiscount <= 0
          ) {
            isValid = false;
          }
        });
        break;
      case 'L':
        alt2.items.forEach(item => {
          if (!item.product.product || !item.freeProduct.product) {
            isValid = false;
          }
        });
        break;
      case 'I':
        alt3.items.forEach(item => {
          if (
            !item.product.product ||
            alt3.minValue <= 0 ||
            (item.productPrice <= 0 && item.productDiscount <= 0)
          ) {
            isValid = false;
          }
        });
        break;
      case 'J':
        alt3.items.forEach(item => {
          if (!item.product.product || alt3.minValue <= 0) {
            isValid = false;
          }
        });
        break;
      case 'M':
        if (!campaignM.codeGenerated && campaignM.code.length <= 0) {
          isValid = false;
          break;
        }

        if (campaignM.discountType === 'flat') {
          if (Number(campaignM.discount) <= 0) {
            isValid = false;
            break;
          }
        } else if (
          campaignM.discounts.length === 0 &&
          (campaignM.discounts[0].valueFrom <= 0 ||
            campaignM.discounts[0].discountPercent)
        ) {
          isValid = false;
        }
        break;
      default:
    }

    if (!isValid) {
      this.setState({
        error: `${__('Hodnoty pre typ kampane:')} "${
          campaignLabels.find(c => c.type === selectedCampaign.type).name
        }" ${__('sú vyplnené nesprávne.')}`,
      });
    }

    return isValid;
  }

  async downloadPDF(newsletterId) {
    try {
      this.setState({
        pdfLoader: true,
      });

      const result = await API2.getNewsletterPdfAction(
        this.props.firm,
        newsletterId,
      );
      window.location.href = result.url;

      this.setState({
        pdfLoader: false,
        showDownloadPopup: false,
      });
    } catch (e) {
      console.log(e);
    }
  }

  prepareData() {
    const {
      campaignName,
      validFrom,
      validTo,
      publishedFrom,
      publishedTo,
      selectedCampaign,
      customers,
      customerTypes,
      newsletter,
      alt1,
      alt2,
      alt3,
      marketingList,
      campaignM,
    } = this.state;

    const { name } = campaignName;
    const valid_from = moment(validFrom.value).toISOString();
    const valid_to = validTo.value && moment(validTo.value).toISOString();
    const publish_from = moment(publishedFrom.value).toISOString();
    const publish_to = publishedTo && moment(publishedTo).toISOString();
    const campaign_alternative = selectedCampaign.type;
    const customer_ids = customers.map(customer => {
      return customer.customer._id;
    });

    const type = 'campaign';

    let discount = 0;

    const data = {};

    const newsletterId = newsletter && newsletter.value;
    const marketingListId = marketingList && marketingList.value;

    const customer_types = customerTypes.map(t => {
      return {
        type: t.customerType,
        subtype: t.customerSubType,
      };
    });

    switch (selectedCampaign.type) {
      case 'D':
        discount = Number(alt1.all.value);
        break;
      case 'F':
        data.products = alt1.items.map(item => {
          return {
            brand_id: item.brand.brand ? item.brand.brand : '',
            product_id: item.product.product ? item.product.product._id : '',
            discount_percent: Number(item.value),
            replace_generel_discount: item.replace,
          };
        });
        break;
      case 'K':
        data.products = alt1.items.map(item => {
          return {
            brand_id: item.brand.brand ? item.brand.brand : '',
            product_id: item.product.product ? item.product.product._id : '',
            discount_percent: Number(item.value),
            replace_generel_discount: item.replace,
          };
        });
        break;
      case 'G':
        data.products = alt2.items.map(item => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
              discount_price:
                item.freeProductPrice &&
                formatPriceForApi({
                  amount: item.freeProductPrice,
                  currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
                }),
            },
          };
        });
        break;
      case 'H':
        data.products = alt2.items.map(item => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
              discount_percent:
                item.freeProductDiscount && Number(item.freeProductDiscount),
            },
          };
        });
        break;
      case 'L':
        data.products = alt2.items.map(item => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            product: {
              product_id: item.freeProduct.product
                ? item.freeProduct.product._id
                : '',
            },
          };
        });
        break;
      case 'I':
        data.purchase_value = formatPriceForApi({
          amount: alt3.minValue,
          currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
        });
        data.products = alt3.items.map(item => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
            discount_price:
              item.productPrice > 0
                ? {
                    amount: Number(item.productPrice),
                    currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
                  }
                : undefined,
            discount_percent:
              item.productDiscount && Number(item.productDiscount),
          };
        });
        break;
      case 'J':
        data.purchase_value = formatPriceForApi({
          amount: alt3.minValue,
          currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
        });
        data.products = alt3.items.map(item => {
          return {
            product_id: item.product.product ? item.product.product._id : '',
          };
        });
        break;
      case 'M':
        if (campaignM.discountType === 'flat') {
          Object.assign(data, {
            usage: campaignM.usage,
            code: !campaignM.codeGenerated ? campaignM.code : undefined,
            codeGenerated: campaignM.codeGenerated,
            discount: Number(campaignM.discount),
            discountType: campaignM.discountType,
          });
        } else {
          Object.assign(data, {
            usage: campaignM.usage,
            code: !campaignM.codeGenerated ? campaignM.code : undefined,
            codeGenerated: campaignM.codeGenerated,
            discounts: campaignM.discounts,
            discountWithVat: campaignM.discountWithVat,
            discountType: campaignM.discountType,
          });
        }

        break;
      default:
    }

    const finalData = {
      name,
      valid_from,
      valid_to,
      publish_from,
      publish_to,
      type,
      campaign_alternative,
      customer_ids,
      customer_types,
      discount,
      data,
      newsletterId,
      marketingListId,
    };

    return finalData;
  }

  render() {
    const {
      campaignName,
      validFrom,
      validTo,
      publishedFrom,
      publishedTo,
      selectedCampaign,
      alt1,
      alt2,
      alt3,
      marketingList,
      error,
      loading,
      success,
      successMessage,
      newsletter,
      campaignM,
    } = this.state;
    const { approved_by, approved_date, readOnly, status } = this.props;

    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <Content>
        {success && <Message message={successMessage} />}
        {error && <Message error message={error} />}

        <InsideContentWrapper>
          <SectionWrapper>
            <Label>{__('Názov kampane* ')}</Label>

            <Input
              type="text"
              placeholder={__('Názov')}
              value={campaignName.name}
              error={campaignName.error}
              disabled
              onChange={e => {
                this.handleChange('campaignName', 0, e);
              }}
            />

            <GreyBox title={__('Platnosť')}>
              <StyledCustomFlexBoxStart>
                <DatePickerWrapper>
                  <StyledDatePicker
                    placeholderText={__('Od')}
                    dateFormat="dd DD.MM.YYYY"
                    selected={validFrom.value}
                    error={validFrom.error}
                    onChange={e => {
                      this.handleChange('validFrom', 0, e);
                    }}
                  />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <StyledDatePicker
                    style={{ width: rem(50) }}
                    dateFormat="dd DD.MM.YYYY"
                    placeholderText={__('Do')}
                    selected={validTo.value}
                    onChange={e => {
                      this.handleChange('validTo', 0, e);
                    }}
                  />
                </DatePickerWrapper>
              </StyledCustomFlexBoxStart>
            </GreyBox>
            <GreyBox title={__('Zverejnenie')}>
              <StyledCustomFlexBoxStart>
                <DatePickerWrapper>
                  <StyledDatePicker
                    placeholderText={__('Od')}
                    dateFormat="dd DD.MM.YYYY"
                    selected={publishedFrom.value}
                    error={publishedFrom.error}
                    onChange={e => {
                      this.handleChange('publishedFrom', 0, e);
                    }}
                  />
                </DatePickerWrapper>
                <DatePickerWrapper>
                  <StyledDatePicker
                    style={{ width: rem(50) }}
                    dateFormat="dd DD.MM.YYYY"
                    placeholderText={__('Do')}
                    selected={publishedTo}
                    onChange={e => {
                      this.handleChange('publishedTo', 0, e);
                    }}
                  />
                </DatePickerWrapper>
              </StyledCustomFlexBoxStart>
            </GreyBox>

            <GreyBox title={__('Status')}>
              {!this.props.copy && (
                <StyledCustomFlexBoxStart style={{ marginTop: rem(10) }}>
                  <DatePickerWrapper>
                    <Input
                      type="text"
                      value={translateCampaignStatus(
                        status,
                        validTo.value || '',
                      )}
                      disabled
                      onChange={() => {}}
                    />
                  </DatePickerWrapper>
                </StyledCustomFlexBoxStart>
              )}
              <StyledCustomFlexBoxStart style={{ marginTop: rem(10) }}>
                {approved_by && approved_by.display_name && (
                  <TextSmall>{`${__('Schválená používateľom')} ${
                    approved_by.display_name
                  } dňa ${formatDate(
                    moment(approved_date),
                    FORMAT_HUMANE_DATETIME,
                  )}.`}</TextSmall>
                )}
              </StyledCustomFlexBoxStart>
            </GreyBox>
            <Space />
            <GreyBox title={__('Newsletter')}>
              {newsletter && newsletter.value ? (
                <React.Fragment>
                  <StyledLink
                    target="_blank"
                    href={`/${this.props.firm}/newsletter/${newsletter.value}`}
                  >
                    {__('Otvoriť newsletter')}
                  </StyledLink>
                  <DownloadButton
                    primary
                    small
                    icon="download"
                    onClick={() => {
                      this.setState({
                        showDownloadPopup: true,
                      });
                    }}
                  >
                    {__('Stiahnuť PDF')}
                  </DownloadButton>
                </React.Fragment>
              ) : (
                <TextSmall>{__('Nemá priradený newsletter')}</TextSmall>
              )}
            </GreyBox>
            <Space />
            <GreyBox title={__('Typ kampane')}>
              <CustomSelect
                name="test"
                size="s"
                disabled
                onChange={e => this.handleChange('selectedCampaign', 0, e)}
              >
                {!selectedCampaign.type !== '' && (
                  <option key="" value="">
                    {__('Vyberte typ kampane')}
                  </option>
                )}
                {campaignLabels.map(type => {
                  return (
                    <option
                      key={type.type}
                      value={type.type}
                      disabled={type.onlyMarketingList && !marketingList}
                      selected={type.type === selectedCampaign.type}
                    >
                      {`${type.type}. ${type.name} ${
                        type.onlyMarketingList && !marketingList
                          ? `- ${__('iba pre marketingový zoznam')} `
                          : ''
                      }`}
                    </option>
                  );
                })}
              </CustomSelect>
            </GreyBox>
          </SectionWrapper>
          <Space /> <Space />
          <Space />
        </InsideContentWrapper>

        {/* -------------------------------------------------------------------------ALTERNATIVA 1--------------------------------------------------------------------- */}
        {selectedCampaign.type === campaignLabels[0].type && (
          <Table>
            <Thead>
              <Tr>
                <Th>{__('Výrobok')}</Th>
                <Th>{__('Zľava')}</Th>
                {/* <Th>{__('Nahrádza základnú zľavu')}</Th> */}
                <Th />
              </Tr>
            </Thead>

            <Tbody>
              <Tr>
                <Td style={{ minWidth: rem(200) }}>{__('Všetky')}</Td>
                <Td style={{ minWidth: rem(200) }}>
                  <ValueInputWrapper>
                    <StyledInput
                      type="number"
                      placeholder={__('Hodnota')}
                      value={alt1.all.value}
                      onChange={e => {
                        this.handleChange('alt1.all.value', 0, e);
                      }}
                    />
                    <Percent>%</Percent>
                  </ValueInputWrapper>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        )}

        {selectedCampaign.type === campaignLabels[1].type && (
          <div>
            {alt1.items.length > 0 && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Značka')}</Th>
                    <Th> {__('Výrobok')}</Th>
                    <Th>{__('Zľava')}</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {alt1.items.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td style={{ minWidth: rem(200) }}>
                          <SearchableSelect
                            value={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.label &&
                              item.brand
                            }
                            keyProps={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.value
                            }
                            loadOptions={query => this.fetchBrand(query)}
                            placeholder={__('Značka')}
                            name={item.brand}
                            disabled={
                              item.product === null
                                ? false
                                : item.product.product
                            }
                            handleOnChange={e =>
                              this.handleChange('alt1.brand', index, e)
                            }
                          />
                        </Td>
                        <Td
                          style={{ minWidth: rem(450) }}
                          onClick={() =>
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt1.product',
                              },
                            })
                          }
                        >
                          <SearchableSelect
                            value={
                              item.product !== null &&
                              item.product.label &&
                              item.product
                            }
                            loadOptions={() => {}}
                            placeholder={__('Názov')}
                            keyProps={
                              item.product !== null && item.product.value
                            }
                            disabled
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <ValueInputWrapper>
                            <StyledInput
                              type="number"
                              placeholder={__('Hodnota')}
                              value={item.value}
                              onChange={e => {
                                this.handleChange(
                                  'alt1.product.value',
                                  index,
                                  e,
                                );
                              }}
                            />
                            <Percent> %</Percent>
                          </ValueInputWrapper>
                        </Td>
                        <Td>
                          {!readOnly && (
                            <DeleteLine>
                              <IconWrapper
                                onClick={() => this.deleteProduct(1, index)}
                              >
                                <Icon name="close" color="black" />
                              </IconWrapper>
                            </DeleteLine>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[2].type && (
          <div>
            {alt1.items.length > 0 && (
              <Table>
                <Thead>
                  <Tr>
                    <Th>{__('Značka')}</Th>
                    <Th>{__('Výrobok')}</Th>
                    <Th>{__('Zľava')}</Th>
                    <Th />
                  </Tr>
                </Thead>
                <Tbody>
                  {alt1.items.map((item, index) => {
                    return (
                      <Tr key={index}>
                        <Td style={{ minWidth: rem(200) }}>
                          <SearchableSelect
                            value={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.label &&
                              item.brand
                            }
                            loadOptions={query => this.fetchBrand(query)}
                            placeholder={__('Značka')}
                            keyProps={
                              item.brand &&
                              item.brand !== null &&
                              item.brand.value
                            }
                            name={item.brand}
                            disabled
                            handleOnChange={e =>
                              this.handleChange('alt1.brand', index, e)
                            }
                          />
                        </Td>
                        <Td
                          style={{ minWidth: rem(450) }}
                          onClick={() =>
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt1.product',
                              },
                            })
                          }
                        >
                          <SearchableSelect
                            value={
                              item.product !== null &&
                              item.product.label &&
                              item.product
                            }
                            loadOptions={() => {}}
                            placeholder={__('Názov')}
                            keyProps={
                              item.product !== null && item.product.value
                            }
                            disabled
                          />
                        </Td>
                        <Td style={{ minWidth: rem(200) }}>
                          <ValueInputWrapper>
                            <StyledInput
                              type="number"
                              placeholder={__('Hodnota')}
                              value={item.value}
                              onChange={e => {
                                this.handleChange(
                                  'alt1.product.value',
                                  index,
                                  e,
                                );
                              }}
                            />
                            <Percent> %</Percent>
                          </ValueInputWrapper>
                        </Td>
                        <Td>
                          {!readOnly && (
                            <DeleteLine>
                              <IconWrapper
                                onClick={() => this.deleteProduct(1, index)}
                              >
                                <Icon name="close" color="black" />
                              </IconWrapper>
                            </DeleteLine>
                          )}
                        </Td>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            )}

            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(1)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {/* -------------------------------------------------------------------------ALTERNATIVA 2--------------------------------------------------------------------- */}

        {selectedCampaign.type === campaignLabels[5].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                        />
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[3].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th>{__('Cena')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          isClearable={false}
                          name={item.product}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          isClearable={false}
                          placeholder={__('Názov')}
                        />
                      </Td>
                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Cena')}
                            value={item.freeProductPrice}
                            onChange={e => {
                              this.handleChange(
                                'alt2.freeProduct.price',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent>
                            {this.props.firm === 'SK' ? '€' : 'CZK'}
                          </Percent>
                        </ValueInputWrapper>
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[4].type && (
          <div>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  {/* <Th>{__('Kód výrobku(benefit)')}</Th> */}
                  <Th>{__('Názov/Kód výrobku(benefit)')}</Th>
                  <Th>{__('Zľava')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt2.items.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                          name={item.product}
                          isClearable={false}
                        />
                      </Td>

                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt2.freeProduct',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          value={item.freeProduct.product && item.freeProduct}
                          loadOptions={() => {}}
                          name={item.freeProduct}
                          isClearable={false}
                          placeholder={__('Názov')}
                        />
                      </Td>
                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Zľava')}
                            value={item.freeProductDiscount}
                            onChange={e => {
                              this.handleChange(
                                'alt2.freeProduct.discount',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent>%</Percent>
                        </ValueInputWrapper>
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(2, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => this.addProduct(2)}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {/* -------------------------------------------------------------------------ALTERNATIVA 3--------------------------------------------------------------------- */}
        {selectedCampaign.type === campaignLabels[6].type && (
          <div>
            <StyledCustomFlexBoxStart>
              <StyledLabel>{__('Minimálna cena: ')}</StyledLabel>
              <ValueInputWrapper>
                <StyledInput
                  type="number"
                  placeholder={__('Hodnota')}
                  value={alt3.minValue}
                  onChange={e => {
                    this.handleChange('alt3.minValue', 0, e);
                  }}
                />
                <Percent>{this.props.firm === 'SK' ? '€' : 'CZK'}</Percent>
              </ValueInputWrapper>
            </StyledCustomFlexBoxStart>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  <Th>{__('Cena')}</Th>
                  <Th>{__('Zľava')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt3.items.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt3.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          isClearable={false}
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                        />
                      </Td>

                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Hodnota')}
                            value={item.productPrice}
                            disabled
                            onChange={e => {
                              this.handleChange('alt3.product.price', index, e);
                            }}
                          />
                          <Percent>
                            {this.props.firm === 'SK' ? '€' : 'CZK'}
                          </Percent>
                        </ValueInputWrapper>
                      </Td>

                      <Td>
                        <ValueInputWrapper>
                          <StyledInput
                            type="number"
                            placeholder={__('Hodnota')}
                            disabled
                            value={item.productDiscount}
                            onChange={e => {
                              this.handleChange(
                                'alt3.product.discount',
                                index,
                                e,
                              );
                            }}
                          />
                          <Percent> %</Percent>
                        </ValueInputWrapper>
                      </Td>
                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(3, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => {
                  if (!readOnly) {
                    this.setState({
                      showProductsSelector: {
                        alt: 'alt3.product',
                      },
                    });
                  }
                }}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[7].type && (
          <div>
            <StyledCustomFlexBoxStart>
              <StyledLabel>{__('Minimálna cena: ')}</StyledLabel>
              <ValueInputWrapper>
                <StyledInput
                  type="number"
                  placeholder={__('Hodnota')}
                  value={alt3.minValue}
                  onChange={e => {
                    this.handleChange('alt3.minValue', 0, e);
                  }}
                />
                <Percent>{this.props.firm === 'SK' ? '€' : 'CZK'}</Percent>
              </ValueInputWrapper>
            </StyledCustomFlexBoxStart>
            <Table>
              <Thead>
                <Tr>
                  {/* <Th>{__('Kód výrobku')}</Th> */}
                  <Th>{__('Názov/Kód výrobku')}</Th>
                  <Th />
                </Tr>
              </Thead>
              <Tbody>
                {alt3.items.map((item, index) => {
                  return (
                    <Tr key={index}>
                      <Td
                        onClick={() => {
                          if (!readOnly) {
                            this.setState({
                              showProductsSelector: {
                                alt: 'alt3.product',
                                index,
                              },
                            });
                          }
                        }}
                      >
                        <SearchableSelect
                          isClearable={false}
                          value={item.product.product && item.product}
                          loadOptions={() => {}}
                          placeholder={__('Názov')}
                        />
                      </Td>

                      {!readOnly && (
                        <DeleteLine>
                          <IconWrapper
                            onClick={() => this.deleteProduct(3, index)}
                          >
                            <Icon name="close" color="black" />
                          </IconWrapper>
                        </DeleteLine>
                      )}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
            {!readOnly && (
              <StyledButton
                primary
                small
                icon="plus"
                onClick={() => {
                  if (!readOnly) {
                    this.setState({
                      showProductsSelector: {
                        alt: 'alt3.product',
                      },
                    });
                  }
                }}
              >
                {__('Pridať')}
              </StyledButton>
            )}
          </div>
        )}

        {selectedCampaign.type === campaignLabels[8].type && (
          <CampaignMWarraper>
            <GreyBox title={__('Základné nastavenia')}>
              <Label>{__('Použitie')}</Label>
              <FlexBoxStart>
                <LabeledCheckbox
                  checked={campaignM.usage === 'one-time'}
                  labelText={__('Jednorázová')}
                  onChange={() =>
                    this.handleCampaignMChange('usage', 'one-time')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignM.usage === 'multiple-time'}
                  labelText={__('Viacnásobná')}
                  onChange={() =>
                    this.handleCampaignMChange('usage', 'multiple-time')
                  }
                />
              </FlexBoxStart>
              <Space />
              <Label>{__('Kód kupónu')}</Label>
              <LabeledCheckbox
                checked={campaignM.codeGenerated}
                error={campaignM.codeError}
                labelText={__('Vygenerovať systémom')}
                onChange={e =>
                  this.handleCampaignMChange('codeGenerated', e.target.checked)
                }
              />
              <Space style={{ height: rem(8) }} />
              <Input
                style={{
                  width: rem(150),
                }}
                type="text"
                placeholder={__('Zadajte kód kupónu')}
                value={campaignM.code}
                disabled
                onChange={e =>
                  this.handleCampaignMChange('code', e.target.value)
                }
              />
              {campaignM.codeGenerated && (
                <Hint>{__('Každý zákazník dostane jedinečný kupón')}</Hint>
              )}
            </GreyBox>
            <Space />
            <GreyBox title={__('Zľava')}>
              <Label>{__('Typ zľavy')}</Label>
              <FlexBoxStart>
                <LabeledCheckbox
                  labelText={__('Plošná')}
                  checked={campaignM.discountType === 'flat'}
                  onChange={() =>
                    this.handleCampaignMChange('discountType', 'flat')
                  }
                />
                <Space />
                <LabeledCheckbox
                  checked={campaignM.discountType === 'progressive'}
                  labelText={__('Progresívna')}
                  onChange={() =>
                    this.handleCampaignMChange('discountType', 'progressive')
                  }
                />
              </FlexBoxStart>
              <Space />
              {campaignM.discountType === 'flat' ? (
                <React.Fragment>
                  <Label>{__('Percentuálna zľava')}</Label>
                  <Input
                    style={{
                      width: rem(150),
                    }}
                    type="number"
                    placeholder={__('Výška % zľavy')}
                    value={campaignM.discount}
                    onChange={e =>
                      this.handleCampaignMChange('discount', e.target.value)
                    }
                  />
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Label>{__('Tabuľka zliav')}</Label>
                  <FlexBoxStart>
                    <LabeledCheckbox
                      labelText={__('S DPH')}
                      checked={campaignM.discountWithVat}
                      onChange={e =>
                        this.handleCampaignMChange(
                          'discountWithVat',
                          e.target.checked,
                        )
                      }
                    />
                    <Space />
                    <LabeledCheckbox
                      checked={!campaignM.discountWithVat}
                      labelText={__('Bez DPH')}
                      onChange={e =>
                        this.handleCampaignMChange(
                          'discountWithVat',
                          !e.target.checked,
                        )
                      }
                    />
                  </FlexBoxStart>
                  <Space />

                  {campaignM.discounts.map((d, index) => (
                    <FlexBoxStart>
                      <SmallNumberInput
                        type="number"
                        placeholder={`${__('Od')} (${getCurrencyByFirm(
                          this.props.firm,
                        )})`}
                        value={d.valueFrom}
                        error={d.error}
                        onChange={e =>
                          this.handleProgresiveDiscountChange(
                            'valueFrom',
                            e.target.value,
                            index,
                          )
                        }
                      />
                      <Space /> <Space />
                      <SmallNumberInput
                        type="number"
                        placeholder={__('%')}
                        value={d.discountPercent}
                        onChange={e =>
                          this.handleProgresiveDiscountChange(
                            'discountPercent',
                            e.target.value,
                            index,
                          )
                        }
                      />
                    </FlexBoxStart>
                  ))}
                </React.Fragment>
              )}
            </GreyBox>
          </CampaignMWarraper>
        )}
      </Content>
    );
  }
}

CampaignDetail.propTypes = {
  campaignId: PropTypes.number,
  firm: PropTypes.string,
  closePopup: PropTypes.func,
};

CampaignDetail.defaultProps = {
  campaignId: null,
  firm: 'SK',
  closePopup: () => {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(CampaignDetail);
