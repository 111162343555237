import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReviewList from '../../Containers/ProductsReviews';
import ReviewDetail from '../../Containers/ProductReviewDetail';

function ReviewCard(props) {
  const { match } = props;
  return (
    <Switch>
      <Route path={`${match.url}/:id`} component={ReviewDetail} />
      <Route path={`${match.url}`} component={ReviewList} />
    </Switch>
  );
}

ReviewCard.propTypes = {
  firm: PropTypes.object.isRequired, //eslint-disable-line
  match: PropTypes.object.isRequired, //eslint-disable-line
};

export default ReviewCard;
