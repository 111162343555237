import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import moment from 'moment';
import Loader from 'oxyrion-ui/lib/Loader';
import Message from 'oxyrion-ui/lib/Message';
import Label from 'oxyrion-ui/lib/Label/Label';
import Textarea from 'oxyrion-ui/lib/Textarea';
import { Button } from 'oxyrion-ui/lib';

import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import {
  formatDate,
  FORMAT_HUMANE_DATETIME,
  getIdFromProps,
  __,
} from '../../Utils';
import {
  AnimatedFormMessageWrapper,
  LoaderWrapper,
} from '../../Components/ReusableComponents';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import API2 from '../../API2';

const MainWrapper = styled.div`
  padding-bottom: ${rem(100)};
  padding-left: ${rem(10)};
  padding-right: ${rem(10)};

  .buttons-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-top: ${rem(16)};
    padding-bottom: ${rem(16)};
  }

  .right {
    display: flex;
  }

  .button-wrapper {
    display: flex;
    flex-direction: column;
    margin: ${rem(4)};
  }

  .inpit-wrapper {
    width: ${rem(290)};
    margin-bottom: ${rem(4)};
  }
`;

const CommentsWrapper = styled.div`
  padding-top: ${rem(30)};
`;

const HeaderLabel = styled.div`
  background: #f8f1f8;
  height: ${rem(40)};
  color: #a448a3;
  /* padding-left: ${rem(16)}; */
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${rem(12)};

  .text {
    margin-left: ${rem(12)};
  }

  .value{
    color: black;
    font-weight: bold;
  }
`;

const CommentRow = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${rem(5)};
  font-size: ${rem(14)};
  border-bottom: ${rem(1)} solid #eee;
  margin-bottom: ${rem(12)};

  .top-part {
    display: flex;
  }

  .bottom-part {
    margin-top: ${rem(8)};
    padding: 12px;
    background: #eee;
    font-weight: normal;
  }

  .edit {
    color: ${props => props.theme.color.primary};
    text-decoration: underline;
    cursor: pointer;
  }

  .edit-clear {
    color: ${props => props.theme.color.danger};
    text-decoration: underline;
    cursor: pointer;
  }

  .value {
    margin-right: ${rem(8)};
    padding-right: ${rem(8)};
    border-right: ${rem(1)} solid black;
    :last-of-type {
      border-right: none;
    }
  }
`;

const NewComment = styled.div`
  margin-bottom: ${rem(24)};
  .row {
    margin-bottom: ${rem(12)};
  }
`;

class OrderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loading: true,
      newComment: { text: '' },
      saveLoading: false,
      commentsToEdit: [],
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  async fetchData() {
    this.setState({
      loading: true,
    });

    try {
      const result = await API2.getProductQuestionAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.setState({
        loading: false,
        data: Object.assign(result, {}),
      });
    } catch (e) {
      this.setState({
        error: __('Otázku sa nepodarilo načítať'),
        loading: false,
      });
    }
  }

  async saveComment() {
    this.setState({ saveLoading: true });
    const { firm, user } = this.props;
    const { newComment } = this.state;

    if (newComment.text && newComment.text.length > 0) {
      try {
        await API2.postProductQuestionResponseAction(
          firm,
          getIdFromProps(this.props),
          {
            body: {
              response: newComment.text,
              employer_name: `${user.display_name}`,
            },
          },
        );

        this.setState({
          success: __('Komentár bol pridaný k otázke'),
          saveLoading: false,
          newComment: {},
        });

        this.fetchData();
      } catch (e) {
        this.setState({
          error: __('Komentár sa nepodarilo pridať k otazke'),
          saveLoading: false,
        });
      }
    } else {
      this.setState({
        error: __('Komentár nesmie byť prázdny'),
        saveLoading: false,
      });
    }
  }

  async saveCommentEdit(index) {
    this.setState({ editLoadingIndex: index });
    const { firm } = this.props;
    const { data, commentsToEdit } = this.state;

    const commentEdit = data.responses[index];

    if (commentEdit.response && commentEdit.response.length > 0) {
      try {
        await API2.putProductQuestionResponseAction(
          firm,
          commentEdit._id,
          getIdFromProps(this.props),
          {
            body: {
              response: commentEdit.response,
            },
          },
        );

        this.setState({
          editLoadingIndex: false,
          success: __('Komentár bol upravený'),
          commentsToEdit: commentsToEdit.filter(c => c !== index),
        });
      } catch (e) {
        this.setState({
          error: __('Komentár sa nepodarilo upraviť'),
          editLoadingIndex: false,
        });
      }
    } else {
      this.setState({
        error: __('Komentár nesmie byť prázdny'),
        editLoadingIndex: false,
      });
    }
  }

  renderControlBar() {
    const { history } = this.props;
    //const { data } = this.state;

    return (
      <ControllBar history={history} name={'Detail otázky'}>
        <ControlBarButton
          small
          primary
          danger
          onClick={() =>
            this.setState({
              showDeleteConfirm: true,
            })
          }
        >
          {__('Odstrániť otázku')}
        </ControlBarButton>
      </ControllBar>
    );
  }

  handleCommentChange(index, value) {
    const { data } = this.state;

    data.responses[index].response = value;

    this.setState({ data });
  }

  handleNewCommentChange(field, value) {
    const { newComment } = this.state;
    newComment[field] = value;

    this.setState({
      newComment,
    });
  }

  unsetToEdit(index) {
    let { commentsToEdit } = this.state;

    if (!commentsToEdit) {
      commentsToEdit = [];
    }

    commentsToEdit = commentsToEdit.filter(i => i !== index);

    this.setState({
      commentsToEdit,
    });
  }

  setToEdit(index) {
    let { commentsToEdit } = this.state;

    if (!commentsToEdit) {
      commentsToEdit = [];
    }

    commentsToEdit.push(index);

    this.setState({
      commentsToEdit,
    });
  }

  async deleteQuestion() {
    try {
      this.setState({
        deleteLoading: true,
      });

      await API2.deleteProductQuestionAction(
        this.props.firm,
        getIdFromProps(this.props),
      );

      this.props.history.push(`/${this.props.firm}/questions`);
    } catch (e) {
      this.setState({
        error: __('Otázku sa nepodarilo odstrániť'),
        deleteLoading: false,
      });
    }
  }

  render() {
    const {
      data,
      loading,
      error,
      success,
      newComment,
      saveLoading,
      showDeleteConfirm,
      deleteLoading,
      commentsToEdit,
      editLoadingIndex,
    } = this.state;
    if (loading) {
      return (
        <LoaderWrapper>
          <Loader size="xl" />
        </LoaderWrapper>
      );
    }

    return (
      <React.Fragment>
        {this.renderControlBar()}

        <PopUpWrapper
          display={showDeleteConfirm}
          showSendOrderToWarehouseDialog
          onClose={() => this.setState({ showDeleteConfirm: false })}
          small
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showDeleteConfirm: false })}
          message={__('Odstrániť otázku k produktu?')}
        >
          <ConfirmDialog
            onConfirm={() => this.deleteQuestion()}
            onDismiss={() => this.setState({ showDeleteConfirm: false })}
            loading={deleteLoading}
            message={__(
              'Naozaj chcete odstrániť túto otázku. Z eshopu bude odstánená natrvalo',
            )}
          />
        </PopUpWrapper>

        <AnimatedFormMessageWrapper display={error}>
          <Message error message={error} />
        </AnimatedFormMessageWrapper>

        <AnimatedFormMessageWrapper display={success}>
          <Message message={success} />
        </AnimatedFormMessageWrapper>
        <MainWrapper>
          <HeaderLabel>
            <div className="text">
              {__(`Otázka k produktu ${data.product.name}`)}
            </div>
          </HeaderLabel>

          <CommentRow>
            <div className="top-part">
              <div className="value">
                {formatDate(moment(data.created_date), FORMAT_HUMANE_DATETIME)}
              </div>
              <div className="value">{`${data.customer_name}`}</div>
              <div className="value">{`${data.customer_email}`}</div>
            </div>
            <div className="bottom-part">{data.question}</div>
          </CommentRow>

          <CommentsWrapper>
            <HeaderLabel>
              <div className="text">{__('Komentáre')}</div>
            </HeaderLabel>

            {!data.responses ||
              (data.responses.length === 0 && (
                <div className="no-comments">
                  {__('Doteraz neboli k otázke pridané žiadne komentáre')}
                </div>
              ))}
            {data.responses.map((comment, index) => (
              <CommentRow>
                <div className="top-part">
                  <div className="value">
                    {formatDate(
                      moment(comment.created_date),
                      FORMAT_HUMANE_DATETIME,
                    )}
                  </div>
                  <div className="value">{`${comment.employerName}`}</div>
                  <div className="value">{`Likes: ${comment.likes_count}`}</div>
                  <div className="value">{`Dislikes: ${comment.dislikes_count}`}</div>
                  {commentsToEdit.includes(index) ? (
                    <div
                      className="edit-clear"
                      onClick={() => this.unsetToEdit(index)}
                    >
                      {__(`Zrušiť úpravu`)}
                    </div>
                  ) : (
                    <div className="edit" onClick={() => this.setToEdit(index)}>
                      {__(`Upraviť odpoveď`)}
                    </div>
                  )}
                </div>
                <div className="bottom-part">
                  {commentsToEdit.includes(index) ? (
                    <NewComment>
                      <Textarea
                        style={{
                          width: '95%',
                        }}
                        value={comment.response}
                        onChange={e =>
                          this.handleCommentChange(index, e.target.value)
                        }
                      />

                      <Button
                        small
                        primary
                        loading={editLoadingIndex === index}
                        disabled={editLoadingIndex === index}
                        onClick={() => this.saveCommentEdit(index)}
                      >
                        {__('Upraviť odpoveď')}
                      </Button>
                    </NewComment>
                  ) : (
                    comment.response
                  )}
                </div>
              </CommentRow>
            ))}

            <NewComment>
              <Label>{__('Nový komentár')}</Label>
              <Textarea
                style={{
                  width: rem(400),
                  height: rem(120),
                  marginBottom: rem(12),
                }}
                value={newComment.text || ''}
                placeholder={`${__('Pridajte nový komentár k otázke')}`}
                onChange={e =>
                  this.handleNewCommentChange('text', e.target.value)
                }
              />

              <Button
                small
                primary
                loading={saveLoading}
                disabled={saveLoading}
                onClick={() => this.saveComment()}
              >
                {__('Uložiť odpoveď')}
              </Button>
            </NewComment>
          </CommentsWrapper>
        </MainWrapper>
      </React.Fragment>
    );
  }
}

OrderDetail.propTypes = {
  history: PropTypes.shape({}).isRequired,
  firm: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(OrderDetail);
