import API2 from '../../API2';

export const searchUltraLiteCatalogProducts = async (
  query,
  filters,
  firm = 'SK',
) => {
  try {
    const filter = {
      limit: 20,
      q: query,
    };

    if (filters) {
      filter.filters = filters;
    }

    return API2.getUltraLiteCatalogProductsAction(firm, filter).then(res => {
      return {
        options: res.products.map(item => ({
          value: item._id,
          label: item.name,
          type: item.type,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCreatedByTypes = async (query, firm) => {
  const values = [
    {
      value: 'customer',
      label: 'Zákazník',
    },
    {
      value: 'crm_user',
      label: 'Zamestnanec',
    },
  ];

  return { options: values };
};

export const searchContacts = async (query, firm = 'SK') => {
  try {
    const filter = {
      limit: 20,
      q: query,
      eshopUsers: true,
    };

    return API2.getContactsAction(firm, filter).then(res => {
      return {
        options: res.items.map(item => ({
          value: item._id,
          label: `${item.name} - ${item.surname}`,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchPaymentTypes = async (query, firm = 'SK') => {
  try {
    return API2.getServiceFeesAction(firm, {
      type: 'payment',
    }).then(res => {
      return {
        options: res.items.map(item => ({
          value: item.name,
          label: item.label,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchShopOrigin = async (firm = 'SK') => {
  try {
    return API2.getShopsAction(firm).then(res => {
      return {
        options: res.map(item => ({
          value: item._id,
          label: item.shopId,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchDeliveryTypes = async (query, firm = 'SK') => {
  try {
    return API2.getServiceFeesAction(firm, {
      type: 'delivery',
    }).then(res => {
      return {
        options: res.items.map(item => ({
          value: item.name,
          label: item.label,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchShops = async (firm = 'SK') => {
  try {
    return API2.getShopsAction({ companyId: firm }).then(res => {
      return {
        options: res.items.map(item => ({
          value: item._id,
          label: `${item.shopId} - ${item.name}`,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchStocks = async (firm = 'SK') => {
  try {
    return API2.getStocksAction(firm).then(res => {
      return {
        options: res.map(item => ({
          value: item._id,
          label: `${item.name}`,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchCashRegisters = async (firm = 'SK') => {
  try {
    return API2.getCashRegistersListAction({
      companyId: firm,
      limit: 10,
      onlyProduction: true,
    }).then(res => {
      return {
        options: res.items.map(item => ({
          value: item._id,
          label: `${item._id} - ${item.name}`,
          raw: item,
        })),
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};

export const searchProducts = async (
  query,
  firm = 'SK',
  additionalFilters = {},
  additionalData = undefined,
) => {
  try {
    const filter = {
      limit: 10,
      q: query,
      ...additionalFilters,
    };

    return API2.getProductsAction(firm, filter).then(res => {
      return {
        options: res.products.map(item => ({
          value: item.No,
          label: `${item.Description} (${item.No})`,
        })),
        additionalData,
      };
    });
  } catch (e) {
    console.log(e);
    return [];
  }
};
