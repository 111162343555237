import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { Input, Label } from 'oxyrion-ui/lib';

import { rem } from 'polished';
import {
  formatDate,
  renderLink,
  __,
  FORMAT_HUMANE_DATETIME,
  phoneNumberValidation,
  formatPrice,
  translatePacketaStatus,
} from '../../Utils';
import BaseTable from '../BaseTable';
import { connect } from '../../Store';
import ControllBar from '../../Components/ControllBar';
import tableHeaders from '../../ContentConfig/packetaHeaders';
import curier123States from '../../ContentConfig/curier123States';
import ControlBarButton from '../../Components/ControllBar/ControlBarButton';
import PopUpWrapper from '../../Components/PopUpWrapper';
import ConfirmDialog from '../../Components/ConfirmDialog';
import SelectPaperPart from '../../Components/SelectPaperPart';
import { searchContacts } from '../../Utils/ApiSearchFunctions';
import SearchableSelect from '../../Components/SearchableSelect';
import DateAndTimerPicker from '../../Components/DateAndTimePicker';
import API2 from '../../API2';
import { extractPacketaErrorXML } from '../../Utils';

const PickupAdditionalContentWrapper = styled.div`
  .select-wrapper {
    margin-bottom: ${rem(12)};
  }

  .input-wrapper {
    margin-bottom: ${rem(12)};
    width: 95%;
  }
`;

const InputError = styled.div`
  color: red;
  display: flex;
  font-size: ${rem(10)};
  margin-top: ${rem(4)};
`;

const ControlBarButtonWrapper = styled.div`
  display: flex;
`;

class PacketaPackages extends BaseTable {
  constructor(props) {
    super({ ...props, sorter: 'created_date' });
    this.headers = tableHeaders;
    this.segmentName = __('Packeta - balíky');
    this.accessors = [
      'package_id',
      'state',
      'order',
      'recipient',
      'created_date',
      'pickup',
      'storno',
    ];
    this.showBulkOperations = true;
    this.fixedHeader = true;
    this.setSorter('created_date');
    this.state = {
      ...this.state,
      lastSearchValue: '',
      showFilter: !this.props.orderId && true,
      invoiceNumber: '',
      deliveryNoteNumber: '',
      showSelectPaperPart: false,
      sendCourierEmailLoading: false,
      courierEmailConflictData: null,
      dailyExportDate: moment(),
      activeMessage: 'Všetky',
      filters: [
        {
          name: 'state',
          label: __('Stav'),
          values: curier123States,
          type: 'select',
        },
        {
          name: 'customerId',
          label: __('Zákazník'),
          value: '',
          type: 'search-select',
          onSearch: q => searchContacts(q, this.props.firm),
        },
        {
          name: 'orderDateFrom',
          label: __('Dátum objednania od'),
          value:
            !this.props.orderId &&
            moment()
              .subtract(5, 'days')
              .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
          type: 'date',
        },
        {
          name: 'orderDateTo',
          label: __('Dátum objednania do'),
          value: '',
          type: 'date',
        },
      ],
      // selectedShop: {
      //   value: 4588,
      //   label: "Kuriér 123 TEST",
      //   _id: "TEST"
      // },
    };
  }

  async componentDidMount() {
    super.componentDidMount();
    try {
      const defaultShop = await API2.getShopAction(
        this.props.firm,
        this.props.firm === 'SK'
          ? '625566b25909c46cece1a5b3'
          : '626146b7e4626224e7b9bcb1',
      );

      this.setState({
        selectedShop: {
          value: defaultShop.courier123SenderId,
          label: defaultShop.shopId,
          _id: defaultShop._id,
        },
      });
    } catch (e) {
      console.log(e);
    }
  }

  async fetchData(parameters = {}) {
    try {
      this.setState({ loading: true });
      const { sorter, selectedCustomer, lastSearchValue, filters } = this.state;

      const filterToSend = {};

      filters
        .filter(f => f.value && f.value !== '-')
        .forEach(f => {
          if (f.type === 'date') {
            filterToSend[f.name] = new Date(f.value).toISOString();
          } else if (f.type === 'search-select') {
            filterToSend[f.name] = f.value.value;
          } else {
            filterToSend[f.name] = f.value;
          }
        });

      const params = Object.assign({}, filterToSend, parameters, { sorter });

      if (!params.q) {
        params.q = lastSearchValue;
      }

      if (selectedCustomer) {
        params.customerId = selectedCustomer.value;
      }

      let newData = [];

      if (this.props.orderId) {
        newData = await API2.getPacketaOrderPackagesAction(
          this.props.firm,
          this.props.orderId,
          params,
        );
      } else {
        newData = await API2.getPacketaPackagesAction(this.props.firm, params);
      }
      const content = this.normalizeColumns(newData.packages);

      this.setState({
        content,
        loading: false,
        limit: newData.limit,
        offset: newData.offset,
        total: newData.total,
        nextOffset: newData.next_offset,
        packageToCancel: null,
        packgeCancelLoading: false,
        packageToPickup: null,
        packagePickupLoading: false,
        createCurierOrderLoading: false,
        error: false,
        activateError: false,
        showCreateDialog: false,
        requestPickupErrorData: null,
        packageDetailData: null,
      });
    } catch (e) {
      this.setState({
        error: __('Pri načítavaní dát sa vyskytla chyba'),
      });
    }
  }
  async loadSearchResult(value) {
    this.setState({ lastSearchValue: value.toLowerCase() });
    this.fetchData();
  }

  async requestOrderPickup() {
    const { packageToPickup } = this.state;

    this.setState({
      packagePickupLoading: true,
    });

    try {
      await API2.postPacketaRequestPickupAction(
        this.props.firm,
        packageToPickup.order_id,
        packageToPickup.package_id,
      );

      this.fetchData();
    } catch (e) {
      this.setState({
        packagePickupLoading: false,
        error: __('Balík sa nepodarilo stornovať'),
      });
    }
  }

  async cancelOrder() {
    const { packageToCancel } = this.state;

    this.setState({
      packgeCancelLoading: true,
    });

    try {
      await API2.deletePacketaPackageAction(
        this.props.firm,
        packageToCancel.order_id,
        packageToCancel.package_id,
      );

      this.fetchData();
    } catch (e) {
      this.setState({
        packageToCancel: false,
        packgeCancelLoading: false,
        error: __('Balík sa nepodarilo stornovať'),
      });
    }
  }

  applyFilter() {
    this.fetchData();
  }

  async sendCourierEmail(forced = false) {
    try {
      this.setState({
        sendCourierEmailLoading: true,
      });

      await API2.sendEshopOrderCourierEmailAction(
        this.props.firm,
        this.props.orderId,
        {
          forced,
          courierType: 'PACKETA',
        },
      );

      this.setState({
        sendCourierEmailLoading: false,
        courierEmailConflictData: null,
        success: __('Email bol úspešne odoslaný'),
      });
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({
          sendCourierEmailLoading: false,
          courierEmailConflictData: e.details.payload,
        });
      } else {
        this.setState({
          error: __('Notifikačný email sa nepodarilo odoslať'),
          sendCourierEmailLoading: false,
        });
      }
    }
  }

  async getDailyExportPDF() {
    try {
      this.setState({
        dailyExportLoading: true,
      });

      const response = await API2.getPacketaDailySummaryAction(
        this.props.firm,
        {
          dateOfDay: this.state.dailyExportDate,
        },
      );

      this.setState({
        dailyExportLoading: false,
      });

      window.open(response.url, '_blank');
    } catch (e) {
      if (e.response.status === 404) {
        this.setState({
          dailyExportLoading: false,
          showDailyExportPopUp: false,
          error: __('Nenašli sa žiadne zásielky'),
        });
      } else {
        this.setState({
          dailyExportLoading: false,
          showDailyExportPopUp: false,
          error: __('Notifikačný vygenerovať PDF'),
        });
      }
    }
  }

  async createCurierOrder(forced = false) {
    if (!forced) {
      this.setState({
        showCreateDialog: true,
      });

      return;
    }

    const {
      requestPickupErrorData,
      invoiceNumber,
      deliveryNoteNumber,
      packagesWeight,
    } = this.state;

    this.setState({
      createCurierOrderLoading: true,
    });

    const additionalData = {};

    if (
      requestPickupErrorData &&
      requestPickupErrorData.package_weight_is_not_valid
    ) {
      if (packagesWeight && packagesWeight > 0) {
        additionalData.weight = Number(packagesWeight);
      } else {
        this.setState({
          packagesWeightError: true,
          createCurierOrderLoading: false,
        });
        return;
      }
    }

    if (requestPickupErrorData && requestPickupErrorData.invoice_not_found) {
      if (invoiceNumber && invoiceNumber.length > 0) {
        additionalData.invoice_number = invoiceNumber;
      } else {
        this.setState({
          invoiceNumberError: true,
          createCurierOrderLoading: false,
        });
        return;
      }
    }

    if (
      requestPickupErrorData &&
      requestPickupErrorData.delivery_note_not_found
    ) {
      if (deliveryNoteNumber && deliveryNoteNumber.length > 0) {
        additionalData.delivery_note_number = deliveryNoteNumber;
      } else {
        this.setState({
          deliveryNoteNumberError: true,
          createCurierOrderLoading: false,
        });
        return;
      }
    }

    if (
      requestPickupErrorData &&
      requestPickupErrorData.phone_number_is_not_valid
    ) {
      if (
        phoneNumberValidation(
          this.props.firm,
          requestPickupErrorData.invalid_number,
        )
      ) {
        additionalData.phone_number = requestPickupErrorData.invalid_number;
      } else {
        this.setState({
          phoneNumberError: true,
          createCurierOrderLoading: false,
        });
        return;
      }
    }

    try {
      const result = await API2.postPacketaPackageAction(
        this.props.firm,
        this.props.orderId,
        {
          body: additionalData,
        },
      );

      this.setState(
        {
          packageDetailData: result.package_data,
          requestPickupErrorData: null,
          showCreateDialog: false,
          createCurierOrderLoading: false,
        },
        () => {
          this.fetchData();
        },
      );
    } catch (e) {
      if (e.response.status === 409) {
        this.setState({
          createCurierOrderLoading: false,
          requestPickupErrorData: e.details.payload,
          showCreateDialog: false,
        });
      } else if (e.response.status === 404) {
        this.setState({
          activateError: __(
            'Faktúra a dodací list ešte neboli vystavené pre túto objednávku',
          ),
          createCurierOrderLoading: false,
          requestPickupErrorData: null,
          showCreateDialog: false,
        });
      } else {
        this.setState({
          error:
            e.details && e.details.description
              ? extractPacketaErrorXML(e.details.description)
              : __('Zásielku sa nepodarilo vytvoriť'),
          createCurierOrderLoading: false,
          requestPickupErrorData: null,
          showCreateDialog: false,
        });
      }
    }
  }

  async confirmPackageCreated() {
    await this.fetchData();
    if (this.props.packageCreateCallback) {
      this.props.packageCreateCallback();
    }
  }

  async printTicket() {
    const { checkedRows } = this.state;

    this.setState({
      ticketsLoading: true,
      showSelectPaperPart: false,
    });

    try {
      const params = {};

      params.packagesIds = checkedRows;

      const r = await API2.getPacketaLabelsAction(this.props.firm, params);

      window.location.href = r.url;

      this.setState({
        ticketsLoading: false,
        checkedRows: [],
      });
    } catch (e) {
      this.setState({
        error: __('Štítky sa nepodarilo vytlačiť'),
        ticketsLoading: false,
      });
    }
  }

  normalizedHeaders() {
    const normalizedHeaders = this.headers.map(header => ({
      value: header.name,
      clickable: header.clickable,
      sorterValue: header.sorterValue,
      width: header.width ? header.width : 0,
      handleOnClick: header.clickable
        ? (sorter, ascending) => this.sort(sorter, ascending)
        : null,
    }));
    return normalizedHeaders;
  }

  normalizeColumns(data) {
    const { firm } = this.props;
    const _shapeData = (value, handleOnClick = null, placeholder = '-') => {
      return {
        value: value || placeholder,
        handleOnClick: handleOnClick || null,
      };
    };
    const result = data.reduce((acumulator, item) => {
      // const fullState = curier123States.find(
      //   c => item.last_state && c.value === item.last_state.id,
      // );

      acumulator.push({
        id: item.package_id,
        // package_id: _shapeData(
        //   renderLinkOnNewTab(
        //     `https://123kurier.jpsoftware.sk/atol/order/edit/${
        //       item.package_id
        //     }`,
        //     item.package_id,
        //   ),
        // ),
        package_id: _shapeData(item.package_id),
        state: _shapeData(
          translatePacketaStatus(Number(item.packet_status.statusCode), firm),
        ),
        order: _shapeData(renderLink(`orders/${item.order_id}`, item.order_id)),
        recipient: _shapeData(
          `${item.recipient.name}, ${item.recipient.address}, ${item.recipient.city}`,
        ),
        created_date: _shapeData(
          formatDate(moment(item.order_date), FORMAT_HUMANE_DATETIME),
        ),
        // pickup: _shapeData(
        //   !fullState && (
        //     <PickupButton
        //       onClick={() =>
        //         this.setState({
        //           packageToPickup: item,
        //         })
        //       }
        //     >
        //       {__('Objednať zvoz')}
        //     </PickupButton>
        //   ),
        // ),
        // storno: _shapeData(
        //   (!fullState || !fullState.end_state) && (
        //     <StornoButton
        //       onClick={() =>
        //         this.setState({
        //           packageToCancel: item,
        //         })
        //       }
        //     >
        //       {__('Stornovať')}
        //     </StornoButton>
        //   ),
        // ),
      });
      return acumulator;
    }, []);
    return result;
  }

  async fetchShops() {
    try {
      return API2.getShopsAction(this.props.firm).then(res => {
        return {
          options: res.items.map(item => ({
            value: item.courier123SenderId,
            label: item.shopId,
            _id: item._id,
          })),
        };
      });
    } catch (e) {
      return [];
    }
  }

  renderControlBar() {
    const {
      history,
      showSearch,
      controlBarColor,
      textColor,
      hideHistory,
    } = this.props;

    const {
      showFilter,
      lastSearchValue,
      checkedRows,
      ticketsLoading,
      createCurierOrderLoading,
      sendCourierEmailLoading,
      content,
    } = this.state;

    return (
      <ControllBar
        history={history}
        showLocation={!hideHistory}
        justifyContent="space-between"
        name={__('Packeta - balíky')}
        defaultValue={lastSearchValue}
        onChange={val => this.loadSearchResult(val)}
        showSearch={showSearch}
        backgroundColor={controlBarColor}
        textColor={textColor}
        style={{ zIndex: 1000 }}
      >
        {checkedRows && checkedRows.length > 0 && (
          <ControlBarButton
            onClick={() => this.printTicket()}
            showIcon={false}
            loading={ticketsLoading}
            name="show_filter"
          >
            {__('Tlačiť štítky')}
          </ControlBarButton>
        )}
        {!this.props.orderId && (
          <ControlBarButton
            onClick={() => this.setState({ showDailyExportPopUp: true })}
            showIcon={false}
            textColor={textColor}
            name="show_filter"
            small
            primary
            style={{ 'margin-right': '5px ' }}
          >
            {__('Denný export')}
          </ControlBarButton>
        )}
        {!this.props.orderId && (
          <ControlBarButton
            icon={showFilter ? 'arrow-right' : 'arrow-down'}
            onClick={() =>
              this.setState({
                showFilter: !showFilter,
              })
            }
            name="show_filter"
          >
            {showFilter ? __('Schovať filtre') : __('Zobraziť filtre')}
          </ControlBarButton>
        )}
        <ControlBarButtonWrapper>
          {this.props.orderId && content && content.length > 0 && (
            <ControlBarButton
              onClick={() => this.sendCourierEmail(false)}
              showIcon={false}
              loading={sendCourierEmailLoading}
              textColor={textColor}
              name="show_filter"
              primary
              style={{ 'margin-right': '5px ' }}
            >
              {__('Zaslať notifikáciu o odoslaní balíka')}
            </ControlBarButton>
          )}
          {this.props.orderId && !this.props.disabled && (
            <ControlBarButton
              onClick={() => this.createCurierOrder()}
              showIcon={false}
              loading={createCurierOrderLoading}
              textColor={textColor}
              name="show_filter"
              primary
            >
              {__('Vytvoriť zásielku pre Packeta')}
            </ControlBarButton>
          )}
        </ControlBarButtonWrapper>
      </ControllBar>
    );
  }

  editCustomNumber(e) {
    const { requestPickupErrorData } = this.state;
    requestPickupErrorData.invalid_number = e.target.value;

    this.setState({
      requestPickupErrorData,
    });
  }

  render() {
    const {
      packageToCancel,
      packageToPickup,
      packagePickupLoading,
      packgeCancelLoading,
      createCurierOrderLoading,
      showCreateDialog,
      requestPickupErrorData,
      invoiceNumber,
      deliveryNoteNumber,
      invoiceNumberError,
      deliveryNoteNumberError,
      showSelectPaperPart,
      packagesWeight,
      phoneNumberError,
      packagesWeightError,
      selectedShop,
      courierEmailConflictData,
      sendCourierEmailLoading,
      showDailyExportPopUp,
      dailyExportDate,
      dailyExportLoading,
      packageDetailData,
    } = this.state;

    return (
      <React.Fragment>
        {super.render()}
        <PopUpWrapper
          display={showSelectPaperPart}
          customWidth={440}
          onClose={() => this.setState({ showSelectPaperPart: null })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showSelectPaperPart: null })}
          message={__('Vyberte pozíciu na tlač')}
        >
          <SelectPaperPart onSubmit={position => this.printTicket(position)} />
        </PopUpWrapper>

        <PopUpWrapper
          display={packageDetailData}
          customWidth={440}
          onClose={() => {}}
          statusName="showDeleteActivity"
          onEscClose={() => {}}
          message={__('Balík bol vytvorený s týmito dátami')}
        >
          {packageDetailData && (
            <ConfirmDialog
              onConfirm={() => this.confirmPackageCreated()}
              onDismiss={() => this.confirmPackageCreated()}
              confirmMessage={__('Rozumiem')}
              dismissMessage={__('Rozumiem')}
              message={`Balík bol vytvorený. Bude odoslaný na adresu ${
                packageDetailData.recipient.street
              }, ${packageDetailData.recipient.zip}, ${
                packageDetailData.recipient.city
              }. Hodnota dobierky je ${formatPrice({
                amount: packageDetailData.cashOnDelivery,
                currency: this.props.firm === 'SK' ? 'EUR' : 'CZK',
              })}`}
            />
          )}
        </PopUpWrapper>

        <PopUpWrapper
          display={requestPickupErrorData}
          small
          onClose={() => this.setState({ requestPickupErrorData: null })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ requestPickupErrorData: null })}
          message={__('Vytvorenie balíka')}
        >
          {requestPickupErrorData && (
            <ConfirmDialog
              onConfirm={() => this.setState({ requestPickupErrorData: null })}
              onDismiss={() => this.createCurierOrder(true)}
              dismissLoading={createCurierOrderLoading}
              confirmMessage={__('Zrušiť')}
              dismissMessage={__('Pokračovať')}
              message={__(
                `Pre vytvorenie zásielky zvoľte predajňu a zadajte čísla dokumentov a počet balíkov`,
              )}
              additionalContent={
                <PickupAdditionalContentWrapper>
                  <div className="select-wrapper">
                    <SearchableSelect
                      value={selectedShop}
                      loadOptions={query => {
                        return this.fetchShops(query);
                      }}
                      placeholder={__('Zvoľte predajňu')}
                      handleOnChange={e => {
                        if (e && e.value) {
                          this.setState({
                            selectedShop: {
                              value: e.value,
                              label: e.label,
                              _id: e._id,
                            },
                          });
                        }
                      }}
                    />
                  </div>
                  {requestPickupErrorData.invoice_not_found && (
                    <div className="input-wrapper">
                      <Input
                        value={invoiceNumber}
                        onChange={e =>
                          this.setState({
                            invoiceNumber: e.target.value,
                            invoiceNumberError: false,
                          })
                        }
                        error={invoiceNumberError}
                        placeholder={__('Číslo faktúry')}
                      />
                    </div>
                  )}

                  {requestPickupErrorData.delivery_note_not_found && (
                    <div className="input-wrapper">
                      <Input
                        value={deliveryNoteNumber}
                        onChange={e =>
                          this.setState({
                            deliveryNoteNumber: e.target.value,
                            deliveryNoteNumberError: false,
                          })
                        }
                        error={deliveryNoteNumberError}
                        placeholder={__('Číslo dodacieho listu')}
                      />
                    </div>
                  )}
                  {requestPickupErrorData.package_weight_is_not_valid && (
                    <div className="input-wrapper">
                      <Input
                        value={packagesWeight}
                        type="number"
                        min={0}
                        onChange={e =>
                          this.setState({
                            packagesWeight: e.target.value,
                            packagesWeightError: false,
                          })
                        }
                        error={packagesWeightError}
                        placeholder={__('Váha balíka v kg')}
                      />
                    </div>
                  )}
                  {requestPickupErrorData.phone_number_is_not_valid && (
                    <div className="input-wrapper">
                      <Label>
                        {__('Tel. číslo v správnom tvare (+42...)')}
                      </Label>
                      <Input
                        value={requestPickupErrorData.invalid_number}
                        onChange={e => this.editCustomNumber(e)}
                        error={phoneNumberError}
                        placeholder={__('Tel číslo v správnom tvare')}
                      />
                      {phoneNumberError && (
                        <InputError className="error">{`${__(
                          'Tel. číslo nie je v správnom formáte pre krajinu',
                        )}: ${this.props.firm}`}</InputError>
                      )}
                    </div>
                  )}
                </PickupAdditionalContentWrapper>
              }
            />
          )}
        </PopUpWrapper>
        <PopUpWrapper
          display={showCreateDialog}
          small
          onClose={() => this.setState({ showCreateDialog: null })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ showCreateDialog: null })}
          message={__('Vytvorenie balíka')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showCreateDialog: null })}
            onDismiss={() => this.createCurierOrder(true)}
            dismissLoading={createCurierOrderLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Vytvoriť')}
            message={`${__('Naozaj chcete vytvoriť balík v systéme Packeta')}?`}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={packageToCancel}
          small
          onClose={() => this.setState({ packageToCancel: null })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ packageToCancel: null })}
          message={__('Storno balíka')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ packageToCancel: null })}
            onDismiss={() => this.cancelOrder()}
            dismissLoading={packgeCancelLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Stornovať')}
            message={`${__(
              'Naozaj chcete stornovať balík č.',
            )} ${packageToCancel && packageToCancel.package_id}?`}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={packageToPickup}
          small
          onClose={() => this.setState({ packageToPickup: null })}
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ packageToPickup: null })}
          message={__('Zvoz balíka')}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ packageToPickup: null })}
            onDismiss={() => this.requestOrderPickup()}
            dismissLoading={packagePickupLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Objednať')}
            message={`${__(
              'Naozaj chcete objednať zvoz pre balík č.',
            )} ${packageToPickup && packageToPickup.package_id}?`}
          />
        </PopUpWrapper>
        <PopUpWrapper
          display={courierEmailConflictData}
          onClose={() => this.setState({ courierEmailConflictData: null })}
          small
          statusName="showDeleteActivity"
          onEscClose={() => this.setState({ courierEmailConflictData: null })}
          message={__('Odoslať email zákazníkovi?')}
        >
          {courierEmailConflictData && (
            <ConfirmDialog
              onConfirm={() =>
                this.setState({ courierEmailConflictData: null })
              }
              onDismiss={() => this.sendCourierEmail(true)}
              dismissLoading={sendCourierEmailLoading}
              confirmMessage={__('Zrušiť')}
              dismissMessage={__('Odoslať znovu')}
              message={`Email bol zákazníkovi úspešne odoslaný ${
                courierEmailConflictData.count
              } krát. Naposledy ${formatDate(
                moment(courierEmailConflictData.last.created_date),
                FORMAT_HUMANE_DATETIME,
              )} na adresu: ${courierEmailConflictData.last.addresses
                .map(a => a.to)
                .join(', ')}. Status: ${courierEmailConflictData.last.type}`}
            />
          )}
        </PopUpWrapper>

        <PopUpWrapper
          display={showDailyExportPopUp}
          onClose={() => this.setState({ showDailyExportPopUp: false })}
          small
          onEscClose={() => this.setState({ showDailyExportPopUp: false })}
          message={__('Exportovať denný sumár zásielok ?')}
          scroll={false}
        >
          <ConfirmDialog
            onConfirm={() => this.setState({ showDailyExportPopUp: false })}
            onDismiss={() => this.getDailyExportPDF()}
            dismissLoading={dailyExportLoading}
            confirmMessage={__('Zrušiť')}
            dismissMessage={__('Exportovať do PDF')}
            dismissDisable={!dailyExportDate}
            message={
              <div>
                <DateAndTimerPicker
                  showTimeSelect={false}
                  datePlaceHolder="Deň exportu"
                  value={dailyExportDate && moment(dailyExportDate)}
                  onChange={e => {
                    this.setState({
                      dailyExportDate: e,
                    });
                  }}
                />
              </div>
            }
          />
        </PopUpWrapper>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(PacketaPackages);
