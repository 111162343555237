import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { rem } from 'polished';
import { formatPrice, __ } from '../../Utils';
import CustomAPI from '../../CustomAPI';
import { LoaderWrapper } from '../ReusableComponents';
import { Loader } from 'oxyrion-ui/lib';
import SearchableSelect from '../SearchableSelect';
import { searchProducts } from '../../Utils/ApiSearchFunctions';
import { connect } from '../../Store';

const PopUpContent = styled.div`
  padding: ${rem(16)} ${rem(20)};
  padding-bottom: ${rem(60)};
`;

const PopUpInfo = styled.div`
  display: grid;
  grid-template-columns: max-content auto;
  gap: ${rem(10)};
  margin-bottom: ${rem(20)};
`;

const Label = styled.div`
  justify-self: start;
  text-align: left;
  padding-right: ${rem(60)};
  font-weight: 600;
  font-size: ${rem(12)};
`;

const Value = styled.div`
  justify-self: start;
  text-align: left;
  font-weight: 400;
  font-size: ${rem(13)};
`;

const ItemsContent = styled.div`
  margin-top: ${rem(6)};

  .item-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .item-info-price {
    display: flex;
    justify-content: space-between;
    width: 40%;
  }

  .item-label-value-wrapper {
    display: flex;
    flex-direction: column;
    gap: ${rem(12)};
    padding-bottom: ${rem(20)};
  }
`;

const Line = styled.div`
  height: ${rem(1)};
  width: 100%;
  background-color: #dedfe0;
`;

const InputWrapper = styled.div`
  width: ${rem(300)};
`;

class FinPortalDetail extends Component {
  state = {
    showDetailReceiptPopUp: false,
    finData: {
      resultFinPortalInfo: [],
      resultFinPortalAdditional: [],
    },
  };

  componentDidUpdate(newProps) {
    if (newProps.billId !== this.props.billId) {
      this.fetchFinRecipeDetail(this.props.billId);
    }
  }

  async fetchFinRecipeDetail(billId) {
    const { functionalityFinPortalBill, firm } = this.props;

    try {
      this.props.onLoading(true);

      const resultFinPortal = await CustomAPI.getFinPortalRecipeDetailAction(
        billId,
      );

      if (resultFinPortal.receipt) {
        let resultFinPortalInfo = [];
        let resultFinPortalAdditional = [];

        resultFinPortalInfo = [
          {
            label: __('Obchodné meno'),
            value: resultFinPortal.receipt.organization.name,
          },
          {
            label: __('Adresa sídla'),
            value: `${resultFinPortal.receipt.organization.streetName} ${resultFinPortal.receipt.organization.propertyRegistrationNumber}/${resultFinPortal.receipt.organization.buildingNumber}, ${resultFinPortal.receipt.organization.postalCode} ${resultFinPortal.receipt.organization.municipality} ${resultFinPortal.receipt.organization.country}`,
          },
          {
            label: __('Predajné miesto'),
            value: `${resultFinPortal.receipt.unit.streetName} ${resultFinPortal.receipt.unit.postalCode} ${resultFinPortal.receipt.unit.municipality}, ${resultFinPortal.receipt.unit.country}`,
          },
          {
            label: __('DIČ'),
            value: resultFinPortal.receipt.dic,
          },
          {
            label: __('IČO'),
            value: resultFinPortal.receipt.ico,
          },
          {
            label: __('Kód pokladnice'),
            value: resultFinPortal.receipt.cashRegisterCode,
          },
          {
            label: __('Dátum a čas vyhotovenia'),
            value: resultFinPortal.receipt.createDate,
          },
          {
            label: __('Poradové číslo dokladu'),
            value: resultFinPortal.receipt.receiptNumber,
          },
          {
            label: __('Celková suma dokladu'),
            value: formatPrice({
              amount: Number(resultFinPortal.receipt.totalPrice),
            }),
          },
          {
            label: __('ID Dokladu'),
            value: resultFinPortal.receipt.receiptId,
          },
          {
            label: __('OKP'),
            value: resultFinPortal.receipt.okp,
          },
        ];

        resultFinPortalAdditional = [
          {
            label: __('Sadzba'),
            value: `${resultFinPortal.receipt.vatRateBasic} %`,
          },
          {
            label: __('Bez DPH'),
            value: formatPrice({
              amount: Number(resultFinPortal.receipt.taxBaseBasic),
            }),
          },
          {
            label: __('DPH'),
            value: formatPrice({
              amount: Number(resultFinPortal.receipt.vatAmountBasic),
            }),
          },
        ];

        const rFinPortal = resultFinPortal;
        if (functionalityFinPortalBill) {
          const productsPromises = [];

          resultFinPortal.receipt.items.forEach((item, index) => {
            if (item.itemType === 'Z') {
              productsPromises.push({ additionalData: { index } });
            } else {
              productsPromises.push(
                searchProducts(item.name, firm, { limit: 1 }, { index }),
              );
            }
          });

          const fullProducts = await Promise.all(productsPromises);

          rFinPortal.receipt.items = rFinPortal.receipt.items.map(
            (i, index) => {
              const p = fullProducts.find(
                f => f.additionalData.index === index,
              );

              if (p && p.options && p.options.length === 1) {
                return {
                  ...i,
                  findProduct: p.options[0],
                };
              } else {
                return {
                  ...i,
                  findProduct: '',
                };
              }
            },
          );
        }
        this.setState({
          finData: Object.assign(rFinPortal, {
            resultFinPortalInfo,
            resultFinPortalAdditional,
          }),
        });
        this.props.onSuccess(rFinPortal);
      } else {
        this.props.onError();
        this.setState({
          error: __('Doklad sa nepodarilo nájsť'),
        });
      }
    } catch (e) {
      this.props.onError(e);
      this.setState({
        error: __('Doklad sa nepodarilo nájsť'),
      });
    } finally {
      this.props.onLoading(false);
    }
  }

  handleChange(field, value, index) {
    let { finData } = this.state;

    finData.receipt.items[index][field] = value;
    this.setState({ finData });
  }

  render() {
    const { finData } = this.state;
    const { functionalityFinPortalBill, firm, header } = this.props;

    return (
      <React.Fragment>
        {header}
        {finData && finData.receipt ? (
          <PopUpContent>
            <PopUpInfo>
              {finData.resultFinPortalInfo.map(item => (
                <>
                  <Label>{item.label}</Label>
                  <Value>{item.value}</Value>
                </>
              ))}
            </PopUpInfo>
            <Line />
            <ItemsContent>
              <div className="item-info">
                {finData.resultFinPortalAdditional.map(item => (
                  <div className="item-label-value-wrapper">
                    <Label>{item.label}</Label>
                    <Value>{item.value}</Value>
                  </div>
                ))}
              </div>
            </ItemsContent>
            <Line />
            <ItemsContent>
              {finData.receipt.items.map((item, index) => (
                <div className="item-label-value-wrapper">
                  <Value>{item.name}</Value>
                  {functionalityFinPortalBill && item.itemType !== 'Z' && (
                    <InputWrapper>
                      <SearchableSelect
                        error={!item.findProduct || !item.findProduct.value}
                        value={item.findProduct}
                        loadOptions={query => searchProducts(query, firm)}
                        handleOnChange={e =>
                          this.handleChange('findProduct', e, index)
                        }
                      />
                    </InputWrapper>
                  )}
                  <div className="item-info">
                    <Label>{item.quantity}x</Label>
                    <div className="item-info-price">
                      <Label>{item.vatRate}%</Label>
                      <Label>
                        {formatPrice({
                          amount: Number(item.price),
                        })}
                      </Label>
                    </div>
                  </div>
                </div>
              ))}
            </ItemsContent>
          </PopUpContent>
        ) : (
          <LoaderWrapper>
            <Loader size="xl" />
          </LoaderWrapper>
        )}
      </React.Fragment>
    );
  }
}

FinPortalDetail.propTypes = {
  billId: PropTypes.string,
  functionalityFinPortalBill: PropTypes.bool,
  onSuccess: PropTypes.func.isRequired,
  onLoading: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

FinPortalDetail.defaultProps = {
  billId: '',
  functionalityFinPortalBill: false,
  onSuccess: () => {},
  onLoading: () => {},
  onError: () => {},
};

const mapStateToProps = state => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(FinPortalDetail);
